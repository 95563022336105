export function loadedUserVsla(state = [], action) {
  switch (action.type) {
    case "USER_LOADED_VSLA":
      return action.userVsla
    default:
      return state
  }
}

export function UserVlsaLoadingStarted(state = false, action) {
  switch (action.type) {
    case "USER_VSLA_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function userVlsaLoadingError(state = false, action) {
  switch (action.type) {
    case "USER_VSLA_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}
