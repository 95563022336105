export function loadedGroupItem(state = [], action) {
  switch (action.type) {
    case "LOADED_GROUP_ITEM":
      return action.group
    default:
      return state
  }
}

export function groupItemLoadingStarted(state = false, action) {
  switch (action.type) {
    case "GROUP_ITEM_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function groupItemLoadingError(state = false, action) {
  switch (action.type) {
    case "GROUP_ITEM_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}
