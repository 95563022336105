import isEmpty from "lodash/isEmpty"

export default function validateInput(data) {
  const lang_code = localStorage.getItem("lang")

  const lang_required = {
    FRANÇAIS: {
      message_required: `Ce champ est requis`,
    },
    ENGLISH: {
      message_required: `This field is required`,
    },
    SWAHILI: {
      message_required: `Ni lazima kujaza nafasi hii`,
    },
    ZARMA: {
      message_required: `Tilas batamo hima ga bara`,
    },
    HAOUSSA: {
      message_required: `Doole hilin ga shina nan`,
    },
    SPANISH: {
      message_required: `Este campo es requerido`,
    },
    LEBANESE: {
      message_required: `This field is required`,
    },
    ARABIC: {
      message_required: `هذا الحقل اجباري`,
    },
    PORTUGUESE: {
      message_required: `Este campo é obrigatório`,
    },
  }

  const lang_format = {
    FRANÇAIS: {
      message_required: `Format de nombre incorrect`,
    },
    ENGLISH: {
      message_required: `Incorrect number format`,
    },
    SWAHILI: {
      message_required: `Umekosea jinsi ya kuandika namba`,
    },
    ZARMA: {
      message_required: `Kabuyaŋiizey hantumyaŋey faỳanka-faỳanka`,
    },
    HAOUSSA: {
      message_required: `Rubutun lambobi ya bambanta`,
    },
    SPANISH: {
      message_required: `Formato de número incorrecto`,
    },
    LEBANESE: {
      message_required: `Incorrect number format`,
    },
    ARABIC: {
      message_required: `خطأ في صيغة الرقم`,
    },
    PORTUGUESE: {
      message_required: `Formato numérico incorreto`,
    },
  }

  const lang_max_length = {
    FRANÇAIS: {
      message_required: `9 caractères maximum autorisés`,
    },
    ENGLISH: {
      message_required: `Maximum allowed characters are 9`,
    },
    SWAHILI: {
      message_required: `Mwisho ni tarakimu au herufi 9`,
    },
    ZARMA: {
      message_required: `Hantumiize yagga hinne no ga furo ne`,
    },
    HAOUSSA: {
      message_required: `Harahi tara  kaɗay ake iya rubutawa a nan`,
    },
    SPANISH: {
      message_required: `Los caracteres máximos permitidos son 9`,
    },
    LEBANESE: {
      message_required: `Maximum allowed characters are 9`,
    },
    ARABIC: {
      message_required: `الحد الاقصى للادخال ٩`,
    },
    PORTUGUESE: {
      message_required: `O máximo de caracteres permitidos é 9`,
    },
  }

  const lang_text_only = {
    FRANÇAIS: {
      message_required: `Entrer seulement du texte`,
    },
    ENGLISH: {
      message_required: `Enter text only`,
    },
    SWAHILI: {
      message_required: `Ingiza herufi pekee`,
    },
    ZARMA: {
      message_required: `Zarma for Enter text only`,
    },
    HAOUSSA: {
      message_required: `Rubuta matani kaɗay`,
    },
    SPANISH: {
      message_required: `Inserta solo texto`,
    },
    LEBANESE: {
      message_required: `Enter text only`,
    },
    ARABIC: {
      message_required: `ادخال احرف/ كلمات فقط`,
    },
    PORTUGUESE: {
      message_required: `Insira apenas texto`,
    },
  }

  let errors = {}
  const letters = /^[a-zA-ZÀ-ÿ0-9-' \u0600-\u06FF]*$/

  if (data.name === "") {
    errors.name = lang_required[lang_code].message_required
  }

  if (!data.name.match(letters)) {
    errors.name = lang_text_only[lang_code].message_required
  }

  // if (data.location === "") {
  //   errors.location = lang_required[lang_code].message_required;
  // }

  // if (!data.location.match(letters)) {
  //   errors.name = lang_text_only[lang_code].message_required;
  // }

  if (data.share_value === "") {
    errors.share_value = lang_required[lang_code].message_required
  }

  if (isNaN(data.share_value)) {
    errors.share_value = lang_format[lang_code].message_required
  }

  if (data.share_value.length > 9) {
    errors.share_value = lang_max_length[lang_code].message_required
  }

  if (data.savings_cycle === "") {
    errors.savings_cycle = lang_required[lang_code].message_required
  }

  if (data.country === "") {
    errors.country = lang_required[lang_code].message_required
  }

  if (data.currency === "" && (lang_code === "ARABIC" || lang_code === "LEBANESE")) {
    errors.currency = lang_required[lang_code].message_required
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}
