import { createSelector } from "reselect"

export function loadedMeetingTransaction(state = [], action) {
  switch (action.type) {
    case "LOADED_MEETING_TRANSACTION":
      return action.transactions
    case "UPDATE_ONGOING_MEETING_TRANSACTIONS":
      const filtered = state.filter(
        ({ memberId }) => memberId !== action.payload[0].memberId,
      )
      const sorted = [...action.payload, ...filtered].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      )
      return sorted
    case "REMOVE_REVERSED_TRANSACTIONS":
      return state.filter(({ id }) => !action.payload.includes(id))
    default:
      return state
  }
}

export function meetingTransactionsLoadingStarted(state = false, action) {
  switch (action.type) {
    case "MEETING_TRANSACTION_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function meetingTransactionLoadingError(state = false, action) {
  switch (action.type) {
    case "MEETING_TRANSACTION_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}

export const currentMeetingTransactions = (state) => state.loadedMeetingTransaction
export const currentMemberName = (state) => state.loadedMember.full_name
export const transactionType = (state) => state.currentTransactionContext

export const selectMemberTrasactions = createSelector(
  currentMeetingTransactions,
  currentMemberName,
  transactionType,
  (transactions, name, type) => {
    return transactions
      .filter((transaction) => transaction.full_name === name)
      .filter((transaction) => transaction.transaction_type === type)
      .filter((transaction) => transaction.reversal === false)
  },
)

export const selectMemberReversedWriteOffTrasactions = createSelector(
  currentMeetingTransactions,
  currentMemberName,
  transactionType,
  (transactions, name) => {
    return transactions
      .filter((transaction) => transaction.full_name === name)
      .filter((transaction) => transaction.transaction_type === "Loan write-off")
      .filter((transaction) => transaction.reversal === false)
  },
)

export const selectMemberReversedLoanPaidTrasactions = createSelector(
  currentMeetingTransactions,
  currentMemberName,
  transactionType,
  (transactions, name) => {
    return transactions
      .filter((transaction) => transaction.full_name === name)
      .filter((transaction) => transaction.transaction_type === "Loan repaid")
      .filter((transaction) => transaction.reversal === false)
  },
)
