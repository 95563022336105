import { createSelector } from "reselect"

export function groupTransactionsAggregLoadingStarted(state = false, action) {
  switch (action.type) {
    case "GROUP_TRANSACTION_AGGREGATE_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function loadedGroupTransactionAggreg(state = [], action) {
  switch (action.type) {
    case "GROUP_LOADED_TRANSACTION_AGGREG":
      return action.aggreg
    default:
      return state
  }
}

export function groupTransactionAggregLoadingError(state = [], action) {
  switch (action.type) {
    case "GROUP_TRANSACTION_AGGREG_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}

export const selectGroupBalanceAggreg = (state) => state.loadedGroupTransactionAggreg

export const selectGroupIncome = createSelector(
  selectGroupBalanceAggreg,
  (aggregs) => {
    return aggregs.filter((aggreg) =>
      ["Group income", "Other income"].includes(aggreg.transaction_type_name),
    )
  },
)

export const selectGroupCashOut = createSelector(
  selectGroupBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Savings cash out",
    )
  },
)

export const selectActualGroupShares = createSelector(
  selectGroupBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Share balance",
    )
  },
)

export const selectGroupsFineAggregate = createSelector(
  selectGroupBalanceAggreg,
  (aggregs) => {
    return aggregs.filter((aggreg) => aggreg.transaction_type_name === "Fine paid")
  },
)

export const selectGroupsWelfareAggregate = createSelector(
  selectGroupBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Welfare contribution",
    )
  },
)

export const selectGroupsSharesSoldInCashOut = createSelector(
  selectGroupBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Shares sold - cash out",
    )
  },
)
