import React, { Component } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Input,
  Col,
  Row,
} from "reactstrap"
import uuid from "uuid"
import { connect } from "react-redux"
import { postCreateMemberTwo } from "../../store/actions/members/createMember"
import validateInput from "../../view/shared/validations/addSecretary"
import { Redirect } from "react-router-dom"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"

import { toast } from "react-toastify"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import StellarLoader from "../layout/StellarLoader"
import { AuthAPI } from "../../store/api"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.member_create_success"
      component="div"
      style={successStyle}
    />
  )
}

const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.member_create_error"
      component="div"
      style={failStyle}
    />
  )
}
export class AddSecretary extends Component {
  notify = (msg) => toast(msg)

  state = {
    modal: true,
    setModal: false,
    redirect: false,
    status: "dormant",
    disabled: false,
    paysInterest: "",
    errors: {},
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })

  isValid() {
    const { errors, isValid } = validateInput(this.state)

    if (!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  async getUser() {
    const headers = {
      headers: {
        Authorization:
          "Bearer " + this.props.user.token || localStorage.getItem("token"),
      },
    }
    const { group } = this.props.vslaSummary[0]

    let currentUser = this.props.user
    const currentUserDetails = await AuthAPI.get(
      `/api/v1/users/${currentUser.id}`,
      headers,
    )
    const memberUuid = uuid.v4()

    const holMember = {
      full_name: currentUser.full_name,
      phone: currentUserDetails.data.phone_number.includes("+")
        ? currentUserDetails.data.phone_number
        : `+${currentUserDetails.data.phone_number}`,
      pubkey: memberUuid,
      gender: currentUser.gender,
      date_of_birth: currentUserDetails.data.date_of_birth.split("T")[0],
      member_role: counterpart.translate("dropdowns.secretary"),
      status: "Active",
      loan_service_charge: this.state.paysInterest === "Yes" ? true : false,
    }

    const urlApi = `${window.REACT_APP_PWA_APP_DOMAIN}/api/v1/groups/${group.id}/members`
    this.addSecretaryToHOL(urlApi, holMember)
  }

  addSecretaryToHOL = (url, holMember) => {
    this.props
      .createMemberTwo(url, holMember, {
        Authorization:
          "Bearer " + this.props.user.token || localStorage.getItem("token"),
      })
      .then(() => {
        this.notify(<SuccessMessage />)
        this.setState({ status: "dormant", redirect: true })
      })
      .catch(() => {
        this.notify(<ErrorMessage />)
      })
  }

  toggle = () => {
    if (this.state.disabled) {
      return
    }
    if (["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang"))) {
      this.setState({ disabled: true })
      this.getUser()
    } else if (this.isValid()) {
      this.setState({ disabled: true })
      this.getUser()
    }
  }

  render() {
    const { modal, redirect, status, errors } = this.state
    const { vsla } = this.props
    const paysInterest = counterpart.translate("placeholder_copy.pays_interest")

    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "1.8em",
      textAlign: "center",
      paddingBottom: "0.5em",
    }

    const radioTextStyle = {
      fontFamily: "Fira Sans",
      fontSize: "18px",
    }

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: `/Groups/${vsla.id}/membersmenu`,
            state: { vsla_id: vsla.id },
          }}
        />
      )
    }

    if (status === "processing") {
      return <StellarLoader />
    }

    return (
      <div>
        <Modal isOpen={modal}>
          <ModalHeader>
            <Translate
              content="placeholder_copy.add_secretary_header"
              style={headerStyle}
            />
          </ModalHeader>
          <ModalBody>
            <Translate
              content="placeholder_copy.add_secretary_msg"
              style={{
                fontFamily: "Fira Sans",
                fontSize: "20px",
                color: "#696969",
              }}
            />
            <br />
            <br />
            {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
              <>
                <span style={loadingStyle}>{paysInterest}</span>

                <div>
                  <Container>
                    <span style={{ color: "red" }}>{errors.paysInterest}</span>
                    <Col>
                      <Row>
                        <Input
                          id="payInterest"
                          type="radio"
                          name="paysInterest"
                          value="Yes"
                          onChange={this.onChange}
                        />

                        <Translate
                          content="dropdowns.yes"
                          component="span"
                          style={radioTextStyle}
                        />
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Input
                          id="noInterestpaid"
                          type="radio"
                          name="paysInterest"
                          value="No"
                          onChange={this.onChange}
                        />
                        <Translate
                          content="dropdowns.no"
                          component="span"
                          style={radioTextStyle}
                        />
                      </Row>
                    </Col>
                  </Container>
                  <br />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={this.toggle}
              disabled={this.state.disabled}
              className="btn btn-primary"
            >
              {" "}
              {this.state.disabled ? (
                <Translate
                  content="placeholder_copy.add_secretary_status"
                  style={loadingStyle}
                />
              ) : (
                <Translate content="placeholder_copy.add_secretary" />
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const successStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "green",
  fontWeight: "bold",
}

const failStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "red",
  fontWeight: "bold",
}

const mapStateToProps = (state) => {
  return {
    vslaSummary: selectCreatedVslaInfo(state),
    isCreating: state.isCreatingVslaMember,
    vsla: state.vsla,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createMemberTwo: (url, member, headers) =>
      dispatch(postCreateMemberTwo(url, member, headers)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSecretary)
