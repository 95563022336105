export function isCreatingVsla(state = false, action) {
  switch (action.type) {
    case "IS_CREATING_VSLA":
      return action.isCreating
    default:
      return state
  }
}

export function vslaCreated(state = [], action) {
  switch (action.type) {
    case "CREATE_VSLA":
      return action.vsla
    default:
      return state
  }
}

export function group(state = [], action) {
  switch (action.type) {
    case "CREATE_GROUP":
      return action.group
    default:
      return state
  }
}

export function groupError(state = false, action) {
  switch (action.type) {
    case "CREATE_GROUP_ERROR":
      return action.groupError
    default:
      return state
  }
}

export function createVslaError(state = "", action) {
  switch (action.type) {
    case "CREATE_VSLA_ERROR":
      return action.error
    default:
      return state
  }
}

export const projects = (state = [], action) => {
  switch (action.type) {
    case "SET_PROJECTS":
      return action.payload
    default:
      return state
  }
}
