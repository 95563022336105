const reducer = (
  state = { transactions: {}, balances: {}, members: {} },
  action,
) => {
  const clone = { ...state }
  switch (action.type) {
    case "SET_BALANCES":
      const balances = {}
      action.payload.forEach((item) => {
        balances[item.accountType] = item
      })
      return {
        ...clone,
        balances,
      }
    case "SET_TYPE_BALANCES":
      return {
        ...clone,
        balances: {
          ...clone.balances,
          [action.payload.transactionType]: action.payload.data,
        },
      }
    case "SET_MEMBER_BALANCES":
      return {
        ...clone,
        members: {
          ...clone.members,
          [action.payload.memberId]: {
            ...(clone.members[action.payload.memberId] || {}),
            [action.payload.transactionType]: action.payload.data,
          },
        },
      }
    case "SET_MEMBER_ACCOUNTS":
      return {
        ...clone,
        members: action.payload,
      }
    default:
      return clone
  }
}

export default reducer
