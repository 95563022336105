import { setCurrentPage } from "../../reducers/navigation"
import { addToCache, updateCacheItem } from "../../../view/shared/utilities/cache"

export const deleteMemberAction =
  (member, data, user_id) => async (dispatch, getState) => {
    const { vsla } = getState()
    const { memberId } = data
    const cacheKey = await addToCache("members", {
      data: member,
      refId: member.pubkey,
      vslaId: vsla.id,
    })
    try {
      const requestObject = {
        pushEvent: "requests",
        receiveEvent: "response",
        emit: true,
        metadata: {
          userId: vsla.id,
          cacheKey,
          callBackParams: [],
          type: "members",
        },
        payload: {
          url: `${window.REACT_APP_PWA_APP_DOMAIN}/api/v1/members`,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            id: user_id,
            walletId: memberId,
          },
          userId: `${vsla.id}_members`,
          method: "DELETE",
        },
      }
      dispatch(requestObject)
      await updateCacheItem(
        "members",
        { requestObject: JSON.stringify(requestObject) },
        cacheKey,
      )
      dispatch(setCurrentPage("group-memberList"))
    } catch (error) {
      console.log(error)
    }
  }
