import moment from "moment"

const accountTypes = [
  "loan",
  "fine",
  "fund",
  "shares",
  "welfare",
  "membership",
  "loan_interest",
  "welfare_requests",
]
const filterMemberBalance = (trans) => {
  return trans
    .filter(
      ({ balance, accountType }) =>
        parseFloat(balance) !== 0.0 &&
        accountTypes.includes(accountType.toLowerCase()),
    )
    .map((aggreg) => ({
      transactionType: aggreg.accountType,
      aggregAmount: aggreg.balance,
    }))
}

const sumWelfareRequests = (trans) =>
  trans.reduce((acc, item) => {
    acc += item.balance
    return acc
  }, 0)

const defaultDate = () => {
  const minAge =
    countryAgeRequirements[localStorage.getItem("_country") || "GH"] || 18

  // Calculate the date (per country requirement) years ago from today using moment.js
  const years = moment().subtract(minAge, "years")
  // Format the date in YYYY-MM-DD format
  const formattedDate = years.format("YYYY-MM-DD")
  return formattedDate
}

const isDateOfBirthValid = (dateOfBirth, minAge) => {
  // Calculate the minimum date (minAge) years ago from today
  const today = new Date()
  const minDate = new Date(
    today.getFullYear() - minAge,
    today.getMonth(),
    today.getDate(),
  )
  // Convert the input dateOfBirth string to a Date object
  const inputDate = new Date(dateOfBirth)
  // Set the time portion of both dates to midnight
  minDate.setHours(0, 0, 0, 0)
  inputDate.setHours(0, 0, 0, 0)
  // Compare the input date with the minimum date
  return inputDate <= minDate
}

const countryAgeRequirements = {
  GH: 18,
  // Add other countries and their requirements here
}

// Country-specific requirements
const countryPhoneRequirements = {
  MZ: 12, // For Mozambique, phone number length should be 12 (including country code)
  // Add other countries and their requirements here
}

export {
  sumWelfareRequests,
  filterMemberBalance,
  defaultDate,
  isDateOfBirthValid,
  countryAgeRequirements,
  countryPhoneRequirements,
}
