import { blockchainAPI } from "../../store/api"
import memberToIssuer from "./memberToIssuer"

export default function reverseFromCustomFund(
  txn,
  id,
  amount,
  vsla_id,
  member_id,
  source_fund_type,
  dest_fund_type,
  reversalTransactionType,
  meeting_id,
  pubkey,
) {
  const url = `vsla/${vsla_id}/member/${member_id}/transaction`
  const data = {
    amount: amount,
    asset: "SC",
    source: source_fund_type,
    dest: "member",
  }
  const headersBC = {
    headers: { Authorization: "Bearer " + localStorage.getItem("tokenBC") },
  }

  blockchainAPI
    .post(url, data, headersBC)
    .then((res) => {
      memberToIssuer(
        id,
        txn,
        amount,
        vsla_id,
        member_id,
        meeting_id,
        pubkey,
        reversalTransactionType,
        dest_fund_type,
      )
    })
    .catch((error) => {
      console.log(error)
    })
}
