import Airtable from "airtable"
import Dexie from "dexie"

const ALLOWED_LANGUAGES = [
  { name: "ENGLISH", id: 1 },
  { name: "SWAHILI", id: 2 },
  { name: "PORTUGUESE", id: 3 },
  { name: "KINYARWANDA", id: 4 },
]

export const getLearningLanguage = () => {
  /*
   vsla has more languages than what the contant supports,
   for that we are defaulting to English when the selected language is not supported
  */
  const defaultLanguage = "ENGLISH"
  const appLanguage = localStorage.getItem("lang") || defaultLanguage // incase "lang" is null
  const allowedLanguage = ALLOWED_LANGUAGES.find(
    ({ name }) => name.toLocaleLowerCase() === appLanguage.toLocaleLowerCase(),
  )
  return allowedLanguage ? allowedLanguage.name : defaultLanguage
}
//translations object contains all available translations (English, Portuguese and Swahili) from airtable
//this function filters the array of translations objects based on content (categories/courses/lessons) Ids
//and language selected. Returns the translated contents for display.
const getTranslationsById = (content, translations, lang, contentType) =>
  translations
    ? translations.filter(
        ({ translation_id, Language }) =>
          lang.includes(Language) &&
          !!content &&
          content
            .map(({ title_tx_id, translation_id }) =>
              contentType === "lessons" ? title_tx_id[0] : translation_id[0],
            )
            .includes(translation_id),
      )
    : []

const getCoursesById = (id, courses, lang) =>
  courses
    ? courses.filter(
        ({ category, Language }) =>
          lang.some((langName) => Language.includes(langName)) && category[0] === id,
      )
    : []

const getLessonsById = (id, lessons, contentType, lang) => {
  return lessons
    ? lessons.filter(
        ({ status, category, course_id, Language }) =>
          status === "Final" &&
          (contentType === "course"
            ? course_id[0]
            : lang.some((langName) => Language.includes(langName)) &&
              category[0]) === id,
      )
    : []
}

const getLessonContentById = (id, content, lang) =>
  content.filter(
    ({ lesson_id, language }) => lang.includes(language) && lesson_id[0] === id,
  )

const getLessonsByCourseIds = (lessons, courses = [], translations, lang) => {
  const courseIds = courses.map(({ course_id }) => course_id[0])
  const filteredLessons = courseIds
    ? lessons.filter((lesson) => courseIds.includes(lesson.course_id[0]))
    : lessons
  return getTranslationsById(filteredLessons, translations, lang, "lessons")
}

//this is the index for hiveonline made content from airtable base
const AIRTABLE_HIVEONLINE_CONTENT_ID = ["recPL0nRMg0dbLtny", "recakJqF8xQ2qNl28"]

const AIRTABLE_BASE_ENV = ["staging", "uat"].includes(window.REACT_APP_PWA_APP_ENV)
  ? window.REACT_APP_PWA_AIRTABLE_STAGING_BASE
  : window.REACT_APP_PWA_AIRTABLE_PROD_BASE

const airtableBase = new Airtable({
  apiKey: window.REACT_APP_PWA_AIRTABLE_API_KEY,
}).base(AIRTABLE_BASE_ENV)

const downloadedVideosDB = new Dexie("downloadedVideosDB")
downloadedVideosDB.version(1).stores({
  videos: "++id, vslaId, data, lessonId, lessonName",
})

export {
  getTranslationsById,
  getCoursesById,
  getLessonsById,
  getLessonContentById,
  getLessonsByCourseIds,
  AIRTABLE_HIVEONLINE_CONTENT_ID,
  AIRTABLE_BASE_ENV,
  downloadedVideosDB,
  airtableBase,
}
