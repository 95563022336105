import isEmpty from "lodash/isEmpty"
import { arabicToInt } from "../utilities/arabicNumParser"
import {
  countryAgeRequirements,
  countryPhoneRequirements,
  isDateOfBirthValid,
} from "../utilities"

export default function validateInput(data) {
  const lang_code = localStorage.getItem("lang")
  const _country = localStorage.getItem("_country") || "GH"

  // Country-specific requirements for age
  const requiredAge = countryAgeRequirements[_country] || 18
  // Country-specific requirements for phone
  const requiredPhoneLength = countryPhoneRequirements[_country] || 12

  const lang_required = {
    FRANÇAIS: {
      message_required: `Ce champ est requis`,
    },
    ENGLISH: {
      message_required: `This field is required`,
    },
    SWAHILI: {
      message_required: `Ni lazima kujaza nafasi hii`,
    },
    ZARMA: {
      message_required: `Tilas batamo hima ga bara`,
    },
    HAOUSSA: {
      message_required: `Doole hilin ga shina nan`,
    },
    SPANISH: {
      message_required: `Este campo es requerido`,
    },
    LEBANESE: {
      message_required: `This field is required`,
    },
    ARABIC: {
      message_required: `هذا الحقل اجباري`,
    },
    PORTUGUESE: {
      message_required: `Este campo é obrigatório`,
    },
  }

  const lang_digits_only = {
    FRANÇAIS: {
      message_required: `Entrez uniquement des chiffres`,
    },
    ENGLISH: {
      message_required: `Enter digits only`,
    },
    SWAHILI: {
      message_required: `Ingiza tarakimu pekee`,
    },
    ZARMA: {
      message_required: `Hantum  lambayze hinne`,
    },
    HAOUSSA: {
      message_required: `Rubuta ỳan ƙilgo kaɗay`,
    },
    SPANISH: {
      message_required: `Inserta solo dígitos`,
    },
    LEBANESE: {
      message_required: `Enter digits only`,
    },
    ARABIC: {
      message_required: `ادخال أرقام فقط`,
    },
    PORTUGUESE: {
      message_required: `Insira apenas dígitos`,
    },
  }

  const lang_text_only = {
    FRANÇAIS: {
      message_required: `Entrer seulement du texte`,
    },
    ENGLISH: {
      message_required: `Enter text only`,
    },
    SWAHILI: {
      message_required: `Ingiza herufi pekee`,
    },
    ZARMA: {
      message_required: `Zarma for Enter text only`,
    },
    HAOUSSA: {
      message_required: `Rubuta matani kaɗay`,
    },
    SPANISH: {
      message_required: `Inserta solo texto`,
    },
    LEBANESE: {
      message_required: `Enter text only`,
    },
    ARABIC: {
      message_required: `ادخال احرف/ كلمات فقط`,
    },
    PORTUGUESE: {
      message_required: `Insira apenas texto`,
    },
  }

  const lang_pin_length = {
    FRANÇAIS: {
      message_required: `Le code PIN doit comporter 4 chiffres`,
    },
    ENGLISH: {
      message_required: `Pin should be 4 digits`,
    },
    SWAHILI: {
      message_required: `PIN mwisho tarakimu 4`,
    },
    ZARMA: {
      message_required: `Zarma for PIN mwisho tarakimu 4`,
    },
    HAOUSSA: {
      message_required: `ỳan ƙilgo hudu kaday ke cikin lamban kanki`,
    },
    SPANISH: {
      message_required: `El pin debe tener 4 dígitos`,
    },
    LEBANESE: {
      message_required: `Pin should be 4 digits`,
    },
    ARABIC: {
      message_required: `الرمز من ٤ أرقام فقط`,
    },
    PORTUGUESE: {
      message_required: `O PIN deve ter 4 dígitos`,
    },
  }

  const lang_pin_mismatch = {
    FRANÇAIS: {
      message_required: `Erreur de code PIN`,
    },
    ENGLISH: {
      message_required: `Pin does not match`,
    },
    SWAHILI: {
      message_required: `PIN haifanani`,
    },
    ZARMA: {
      message_required: `Zarma for Pin does not match`,
    },
    HAOUSSA: {
      message_required: `Lamban ki na kanki ta bambanta`,
    },
    SPANISH: {
      message_required: `El pin no coincide`,
    },
    LEBANESE: {
      message_required: `Pin does not match`,
    },
    ARABIC: {
      message_required: `الرمز غير مطابق`,
    },
    PORTUGUESE: {
      message_required: `O PIN não corresponde`,
    },
  }

  const lang_phone_max_length = {
    FRANÇAIS: {
      message_required: `Le numéro de téléphone doit comporter ${requiredPhoneLength} chiffres`,
    },
    ENGLISH: {
      message_required: `Phone number should be ${requiredPhoneLength} digits`,
    },
    SWAHILI: {
      message_required: `Namba ya simu mwisho ni tarakimu ${requiredPhoneLength}`,
    },
    ZARMA: {
      message_required: `Zarma for Phone number should be ${requiredPhoneLength} digits`,
    },
    HAOUSSA: {
      message_required: `ỳan ƙilgo ${requiredPhoneLength} ke cikin lamba waya`,
    },
    SPANISH: {
      message_required: `El número de teléfono debe tener ${requiredPhoneLength} dígitos`,
    },
    LEBANESE: {
      message_required: `Phone number should be ${requiredPhoneLength} digits`,
    },
    PORTUGUESE: {
      message_required: `O número de telefone deve ter ${requiredPhoneLength} dígitos`,
    },
    ARABIC: {
      message_required: `رقم الهاتف يجب ان يكون من ١٠ أرقام`,
    },
  }

  const min_date_msg = {
    FRANÇAIS: {
      message_required: `Format de date invalide ou ne répond pas aux exigences d'âge minimum de ${requiredAge} ans`,
    },
    ENGLISH: {
      message_required: `Invalid date format or does not meet minimum age requirements of ${requiredAge} years`,
    },
    SWAHILI: {
      message_required: `Muundo wa tarehe si sahihi au haukidhi mahitaji ya chini ya umri wa miaka ${requiredAge}`,
    },
    ZARMA: {
      message_required: `Invalid date format or does not meet minimum age requirements of ${requiredAge} years`,
    },
    HAOUSSA: {
      message_required: `Tsarin kwanan wata mara inganci ko bai cika mafi ƙarancin shekarun buƙatun shekaru ${requiredAge} ba`,
    },
    SPANISH: {
      message_required: `Formato de fecha no válido o no cumple con los requisitos de edad mínima de ${requiredAge} años.`,
    },
    LEBANESE: {
      message_required: `Invalid date format or does not meet minimum age requirements of ${requiredAge} years`,
    },
    PORTUGUESE: {
      message_required: `Formato de data inválido ou não atende aos requisitos de idade mínima de ${requiredAge} anos`,
    },
    ARABIC: {
      message_required: `تنسيق التاريخ غير صالح أو لا يستوفي متطلبات الحد الأدنى للعمر وهو ${requiredAge} عامًا`,
    },
  }

  let errors = {}
  const letters = /^[A-zÀ-ú\-\\' \u0600-\u06FF]+$/

  if (data.gender === "") {
    errors.gender = lang_required[lang_code].message_required
  }
  if (data.full_name === "") {
    errors.full_name = lang_required[lang_code].message_required
  }

  if (!data.full_name.match(letters)) {
    errors.full_name = lang_text_only[lang_code].message_required
  }

  if (data.pin === "") {
    errors.pin = lang_required[lang_code].message_required
  }

  if (data.confirm_pin === "") {
    errors.confirm_pin = lang_required[lang_code].message_required
  }

  if (data.confirm_pin !== data.pin) {
    errors.pin = lang_pin_mismatch[lang_code].message_required
    errors.confirm_pin = lang_pin_mismatch[lang_code].message_required
  }

  if (lang_code === "ARABIC") {
    if (isNaN(arabicToInt(data.pin))) {
      errors.pin = lang_digits_only[lang_code].message_required
    }
  } else if (isNaN(data.pin)) {
    errors.pin = lang_digits_only[lang_code].message_required
  }

  if (lang_code === "ARABIC") {
    if (isNaN(arabicToInt(data.confirm_pin))) {
      errors.confirm_pin = lang_digits_only[lang_code].message_required
    }
  } else if (isNaN(data.confirm_pin)) {
    errors.confirm_pin = lang_digits_only[lang_code].message_required
  }

  if (data.pin.length !== 4) {
    errors.pin = lang_pin_length[lang_code].message_required
  }

  if (data.confirm_pin.length !== 4) {
    errors.confirm_pin = lang_pin_length[lang_code].message_required
  }

  if (data.phone_number.length !== requiredPhoneLength) {
    errors.phone_number = lang_phone_max_length[lang_code].message_required
  }

  if (!isDateOfBirthValid(data.date_of_birth, requiredAge)) {
    errors.date_of_birth = min_date_msg[lang_code].message_required
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}
