import { backendAPI } from "../../store/api"

export default function logTransaction(amount, uuid, meeting_id, transaction_type) {
  const url = `/api/v1/transactions/${meeting_id}/${uuid}/`

  const headers = {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  }

  const data = {
    meeting_id: meeting_id,
    pubkey: uuid,
    transaction_type_name: transaction_type,
    amount: amount,
    reversal: false,
  }

  backendAPI
    .post(url, data, headers)
    .then((res) => {
      console.log(res.data)
    })
    .catch((error) => {
      console.log(error)
    })
}
