import {
  SET_USER_SELECTED_LOCATION_DATA,
  SET_LOCATIONS,
  SET_COUNTRIES,
} from "../../constants"

// Initial state with location, children, and parent maps
const initialState = {
  childrenMap: new Map(),
}

const locations = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return action.payload
    default:
      return state
  }
}

const initialCountriesState = {
  defaultCountry: {},
  allCountries: {},
}
const countries = (state = initialCountriesState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return action.payload
    default:
      return state
  }
}

const locationTransport = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_SELECTED_LOCATION_DATA:
      return action.payload
    default:
      return state
  }
}

export { locations, locationTransport, countries }
