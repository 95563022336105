import { backendAPI } from "../../store/api"

export default function updateTransaction(txn) {
  const url = `/api/v1/transactions/${txn.id}`
  let copy_txn = {
    pubkey: txn.pubkey,
    full_name: txn.full_name,
    transaction_type: txn.transaction_type,
    amount: txn.amount,
    reversal: true,
  }

  const headers = {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  }

  backendAPI
    .patch(url, copy_txn, headers)
    .then((res) => {
      console.log("updated", res)
    })
    .catch((error) => {
      console.log(error)
    })
}
