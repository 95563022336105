export default {
  learningContent: {
    buttonTitle: "تعليمي",
    title: "Learning",
    description:
      "Here you can find learning materials that can help farmers develop their skills and increase their farming knowledge.",
    button: "Go To Learning",
    courses: "Courses",
    content: "Content",
    downloads: "Downloads",
    list: "List",
    filter_list_text: "Filter List By",
    lessons: "Lesson list",
    lessonView: "Lessons",
    language: "LANGUAGE",
    course: "Course",
    select_language: "Select Language",
    search_course: "Search course...",
    download_msg: "Your downloaded lessons will appear here",
    selected_filters: "Selected Filters",
    views: "Views",
    download: "Download",
    downloading: "Downloading...",
    delete: "Delete",
    deleting: "Deleting...",
    NoSpaceAvailable: "Not enough storage space",
    lesson: "Lesson",
  },
  error_msg: {
    transactions_error:
      "Recording TRANSACTION_NAME of AMOUNT CURRENCY for MEMBER_NAME has failed. What do you want to do with the transaction?",
  },
  countries: {
    Niger: "النيجر",
    Tanzania: "تنزانيا",
    Rwanda: "رواندا",
    Zambia: "زامبيا",
    Honduras: "هندوراس",
    Nigeria: "نيجيريا",
    Lebanon: "لبنان",
    Mozambique: "موزامبيق",
    Ghana: "غانا",
    Syria: "سوري",
    Palestine: "فلسطيني",
  },
  title_copy: {
    status: "status",
    version_msg: "You're using the test version of vsla.online",
    cycles_title: "Saving cycles",
    header_login: "تسجيل الدخول",
    header_signup: "معلومات عن المسؤول",
    create_group: "إنشاء مجموعة",
    group_finance: ":صندوق الرعاية",
    group_header: "مجموعة",
    loading: "...جاري التحميل",
    updating: "...جاري التحديث والتفعيل",
    check_status: "التحقق من حالة المجموعة",
    no_vsla: "لا توجد أي مجموعة",
    create_meeting: "إنشاء جلسة",
    meeting_members: "مجموعة",
    meeting: "المعاملات",
    prev_meeting: "جلسات المعاملات السابقة",
    add_member: "معلومات عن المسؤول",
    buy_shares: "إضافة المدخرات",
    sell_shares: "سحب المدخرات",
    fine: "دفع غرامة",
    welfare: "المساهمة في الرعاية",
    welfare_request: "طلب الرعاية",
    membership_fee: "رسوم العضوية",
    loan: "القرض",
    funds: "الأرصدة",
    more_details: "تفاصيل إضافية",
    vsla_actions: "أعمال المجموعة",
    mem_actions: "اعمال الفرد",
    memberlist_overview: "اللائحة",
    mem_menu: "اضافة افراد",
    loan_repayment: "سداد القروض",
    loan_writeoff: "شطب القرض",
    outstanding_loan: ":قرض غير مسدد",
    outstanding_loan_interest: ":فائدة قرض غير مسددة",
    outstanding_service_charge: ":فائدة قرض غير مسددة",
    attendees: "جلسة الأسر",
    share_value: "قيمة الأسهم",
    cashOutType: "طريقة القبض",
    settings: "الإعدادات",
    current_savings: "المدخرات",
    cycle_duration: "مدة الدورة",
    addFundAccount: "إضافة حسابات",
    supported_accounts: "الحسابات المدعومة",
    lastTransaction: "المعاملة الأخيرة",
    otherIncome: "مداخيل أخرى",
    otherExpenses: "نفقات أخرى",
    cashOut: "القبض",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    offline_meeting: "جلسة غير متصلة بالإنترنت",
    reminder: "Reminder",
  },
  menu: {
    learning_content: "tutorials",
    vlsa: "القائمة",
    group: "المجموعة",
    meeting: "المعاملات",
    create_vsla: "إنشاء مجموعة",
    vsla_overview: "نظرة عامة عن المجموعة",
    new_meeting: "جلسة المعاملات الجديدة",
    prev_meeting: "جلسات المعاملات السابقة",
    vsla_saving: ":حساب الادّخار",
    vlsa_loan: ":إجمالي القروض",
    vlsa_balance: ":الرصيد الفعلي",
    funds: "الحسابات",
    members: "الأفراد",
    members_overview: "نظرة عامة عن الأسر",
    add_member: "إضافة أفراد.",
    end_meeting: "نهاية جلسة المعاملات",
    current_meeting: "جلسة المعاملات الحالية",
    settings: "الإعدادات",
    options: "الخيارات",
    language: "اللغة",
    close: "إغلاق",
    savingsFund: "حساب الادخار",
    welfareFund: "حساب الرعاية",
    loanInterestFund: "حساب فائدة القرض",
    fineFund: "حساب الغرامات",
    membershipFund: "حساب العضوية",
    end_of_cycle_cash_out: "End of cycle cash out",
    offline_meeting: "جلسة غير متصلة بالإنترنت",
  },
  menu_mem: {
    overview: "نظرة عامة عن الفرد",
    welfare: "المساهمة في الرعاية",
    welfare_request: "طلب الرعاية",
    buy_shares: "إضافة المدخرات",
    create_loan: "إنشاء قرض",
    repay_loan: "سداد القرض",
    writeoff_loan: "شطب القرض",
    pay_fine: "دفع الغرامات",
    membership_fee: "رسوم العضوية",
    sell_shares: "سحب المدخرات",
    transaction_amount: "قيمة المعاملات",
    other_income: "مداخيل أخرى",
    other_expenses: "نفقات أخرى",
    cashout: "القبض",
    welfare_contribution: "المساهمة في الرعاية",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    buy_shares_reversal: "إضافة المدخرات (عكس)",
    sell_shares_reversal: "سحب المدخرات (عكس)",
    group_income_fund: "Group income - Savings",
    group_income_fine: "Group income - Fine",
    group_income_welfare: "Group income - Welfare",
    group_expense_fund: "Group expense - Savings",
    group_expense_fine: "Group expense - Fine",
    group_expense_welfare: "Group expense - Welfare",
  },
  mem_overview: {
    welfare: "المساهمة في الرعاية",
    shares_in_francs: "أسهم نقدًا",
    loan: "قيمة القرض",
    shares_in_numbers: "عدد الأسهم:",
    welfare_total: ":الرعاية",
    shares_total: "مدخرات الأسر",
  },

  header: {
    home: "الصفحة الرئيسية",
    vsla: "المجموعة",
    logout: "تسجيل الخروج",
    whats_new: "ما الجديد",
    tutorials: "ارشادات ",
    legal: "قانوني",
  },

  placeholder_copy: {
    search_no_result_message: "Not found",
    search_placeholder: "Search name",
    add_account_loader_msg: "Creating accounts, final step",
    group_loader_msg: "Creating group, wait a moment",
    gender: "الجنس",
    female: "أنثى",
    male: "ذكر",
    sms_code: "رمز التحقق",
    submit: "إرسال",
    income_amount: "قيمة...",
    login_email: "رقم الهاتف",
    login_password: "رقم التعريف الشخصي",
    login_submit: "تسجيل الدخول",
    login_submitting: "جاري تسجيل الدخول",
    signUp_submit: "إنشاء",
    signUp_submitting: "جاري الإنشاء",
    create_group_name: "اسم المجموعة",
    create_group_location: "مكان المجموعة",
    create_group_submit: "إنشاء المجموعة",
    create_fund_submit: "إنشاء حسابات",
    meeting_date: "اختيار التاريخ",
    meeting_submit: "إنشاء الجلسة",
    meeting_submitting: "جاري إنشاء الجلسة",
    member_submit: "اضافة فرد",
    member_name: "الإسم الثلاثي",
    member_phone: "رقم الهاتف",
    number_of_shares: "قيمة المبلغ",
    shares_submit: "إضافة المدخرات",
    share: "المدخرات",
    loan_amount: "قيمة القرض",
    loan_submit: "إنشاء القرض",
    welfare_amount: "قيمة الرعاية",
    welfare_submit: "دفع الرعاية",
    payment_amount: "قيمة الدفع",
    repay_submit: "دفع القرض",
    loan_writeoff: "شطب القرض",
    share_value_amount: "مبلغ قيمة الأسهم",
    share_value_submit: "تحديث الإعدادات",
    create_group_share_value: " قيمة حصة دورة المجموعة",
    role_in_group: "الدور في الأسرة",
    login_pin: " رقم التعريف الشخصي",
    login_pin_confirm: "تأكيد رقم التعريف الشخصي",
    create_group_federation: "رابطة...",
    savings_cycle: "اختيار دورة المدخرات",
    pays_interest: "هل تسدد الأسرة فائدة على القروض؟",
    country: "اختيار البلد",
    date_of_birth: "تاريخ الولادة",
    add_secretary_header: "جاري إضافة المسؤول",
    add_secretary_msg: " سيتم إضافة المسؤول أولا",
    add_secretary_status: "جاري الإضافة",
    add_secretary: "إضافة المسؤول",
    total_amount: " المبلغ الإجمالي",
    fine_amount: "قيمة الغرامة",
    fine_submit: "دفع الغرامة",
    membership_amount: "رسوم العضوية",
    membership_submit: "سداد رسوم العضوية",
    sell_shares: "سحب المدخرات",
    membership_reverse: "إلغاء",
    service_charge_amount: " مبلغ رسوم الخدمة",
    forgot_pin: " نسيت رقم التعريف الشخصي؟",
    account_associated: " أدخل رقم الهاتف المرتبط بحسابك",
    token_sent: " أدخل الرمز المكون من 4 أرقام الذي تلقيته عبر رسالة نصية قصيرة",
    resend_token: "إعادة إرسال الرمز",
    submit_smscode: " أدخل رمز رقم التعريف الشخصي الجديد المكون من 4 أرقام",
    income_submit: "القبض",
    nationality: "الجنسية",
    select_nationality: "اختر الجنسية",
    next: "Next",
    cashingOut: "Cashing out",
    finish: "Finish",
    currency: "إختار العملة",
    savings_amount: "مبلغ الادخار",
    omt_recipt_number: "OMT رقم ايصال",
    enter_recipt_number: "أدخل رقم الإيصال",
    savings_withdrawal: "سحب المدخرات",
    project_name: "اسم المشروع",
    retry: "أعد المحاولة",
    remove: "يزيل",
    secretary_creation_msg: "جاري إنشاء السكرتير...",
    secretary_creation_failed_msg: "فشل إنشاء السكرتير. الرجاء اعادة المحاولة.",
  },

  delete: "حذف المجموعة",
  delete_member: "حذف الفرد",
  edit: "تعديل المجموعة",
  save: "حفظ",
  edit_member: "تعديل الفرد",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "تم تحميل المعاملات التي جرت دون اتصال بالإنترنت وحلها. تحقق من جلسات المعاملات السابقة لرؤيتها",
    no_members: "لا يوجد افراد في هذه المجموعة",
    no_savings_cash_out_personal: "حساب الادخار المسحوب مغلق، وقرض الأسرة غير محقق",
    no_savings_cash_out_group:
      "حساب الادخار المسحوب مغلق، والأسرة غير محققة في المجموعة",
    no_membership_cash_out_personal:
      "حساب العضوية المسحوب مغلق، وقرض الأسرة غير محقق",

    no_membership_cash_out_group:
      "حساب العضوية المسحوب مغلق، والقرض غير محقق في المجموعة",

    no_fine_cash_out_personal: "حساب الغرامة المسحوب مغلق، والقرض الخاص غير محقق",
    no_fine_cash_out_group: "حساب الغرامة المسحوب مغلق، والقرض غير محقق في المجموعة",
    no_welfare_cash_out_personal: "حساب الرعاية المسحوب مغلق، والقرض الخاص غير محقق",

    no_welfare_cash_out_group:
      "حساب الرعاية المسحوب مغلق، والقرض غير محقق في المجموعة",

    cash_out_success: " تم استيفاء جميع الشروط لتمكين القبض",
    success: "النجاح",
    error: "خطأ",
    cash_out_type_not_define:
      "لم يتم تحديد نوع القبض النقدي لهذا الحساب، قم بتحديثه من قائمة الإعدادات",
    cannot_end_meeting:
      "لا يمكن إنهاء جلسة المعاملة بينما لا تزال بعض المعاملات جارية",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "لا يوجد شبكة انترنت",
    uploading_transactions: "...تحميل المعاملات",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "Can't delete member while cashout in progress",
    cannot_delete_member_secretary_msg: "Can't the App Secretary delete",
  },

  dropdowns: {
    secretary: "المسؤول",
    chairperson: "رب الأسرة",
    box_keeper: "أمين صندوق الأسرة",
    keyHolder: "Key Holder",
    money_counter: "عداد مال الأسرة",
    member: "عضو في الاسرة",
    yes: "نعم",
    no: "كلا",
    nine_months: "9 أشهر",
    six_months: "6 أشهر",
    year: "سنة واحدة",
    establishment_date: "",
    USD: "دولار أمريكي",
    LBP: ".ل.ل",
    business_development: "Business development",
    personal_emergency: "Personal Emergency",
    other: "Other",
  },

  cards: {
    total_member: "مجموع الأفراد",
    cycle_duration: "مدّة الدورة",
    country: "البلد",
    locality: "البلدة",
    federation: "رابطة",
    share_value: "قيمة الأسهم",
    months: "الأشهر",
    created_at: "تمّ الإنشاء في",
    established_on: "",
  },

  notifications: {
    group_exist_error: "اسم المجموعة مأخوذ بالفعل. الرجاء اختيار واحد مختلف",
    edit_success: "تم التحديث بنجاح",
    edit_error: "جاري تحديث الخطأ",
    signup_success: "تم إنشاء الحساب بنجاح",
    success: "تمت العملية بنجاح",
    error: "لم تتم العملية بنجاح",
    group_create_success: "تم إنشاء المجموعة بنجاح",
    group_create_error: "خطأ أثناء إنشاء المجموعة",
    activation_success: "تم تفعيل المجموعة بنجاح",
    activation_error: "خطأ أثناء تفعيل المجموعة",
    meeting_end_success: "نهاية الجلسة",
    meeting_end_error: "خطأ أثناء نهاية الجلسة",
    meeting_create_success: "بداية الجلسة",
    meeting_create_error: "لم تبدأ الجلسة",
    member_create_success: "تم إنشاء الأسرة بنجاح",
    member_create_error: "خطأ أثناء إنشاء الأسرة",
    meeting_no_member_warning: "لا يمكن بدء الجلسة قبل إضافة الأسر إلى المجموعة",

    no_previous_meeting: " لا توجد جلسات سابقة لهذه المجموعة",
    no_transactions: "عدم وجود معاملات لهذه الجلسة",
    loan_amount_error: "قيمة القرض تتجاوز القيمة المسموح بها",
    share_value_loading: "الحصول على قيمة الأسهم الحالية",
    cant_update_shares: "الجلسة قائمة، لا يمكن تحديث قيمة الأسهم",
    member_phone_error: "الرقم المستخدم",
    savings_fund_mandatory: "حساب الإدخار الزامي",
    fund_create_success: "تم إنشاء الحساب بنجاح",
    gdpr_warning:
      " من خلال متابعة إنشاء الحساب، فإنك تؤكد أنك تبلغ من العمر 16 عامًا وما فوق",

    can_not_request_welfare: "إن رصيد حساب الرعاية الحالي هو صفر، الطلب غير ممكن",

    no_reversible_transactions: "عدم وجود معاملات قابلة للعكس",
    activate_reversible_transactions: "ضع علامة في المربع لتفعيل عكس المعاملات",
    welfare_amount_warning: " القيمة تتجاوز القيمة المسموح بها",
    pin_success: "تم تغيير رقم التعريف الشخصي بنجاح",
    pin_error: "أدخل الرمز الصحيح!يرجى معاودة المحاولة",
    cancel_txn: "تم إلغاء المعاملة",
    processing: "جاري معالجة المعاملات",
    pay_out_standing_charge_first: "يجب دفع رسوم الخدمة قبل سداد القرض",
    cant_delete_member_has_funds:
      "لا يمكن حذف الأسرة إذا كان لديها أموال متبقية أو أثناء انعقاد الجلسة",
    cant_delete_group_has_funds:
      "لا يمكن حذف المجموعة إذا تم تمويل محفظتها أو أثناء انعقاد الجلسة",
    share_amount_error: "مبلغ الادخار يتجاوز المبلغ المسموح به",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_information:
      "You are starting a new saving cycle. Do you want to change the share value?",
    share_value_updated_msg: "Share value updated!",
    incorrect_username_password_msg: "اسم المستخدم أو كلمة المرور غير صحيحة",
    contactHiveForSupportMsg:
      "حدث خطأ أثناء إرسال الرمز. اتصل بـ hiveonline للحصول على الدعم",
  },

  badge: {
    meeting_on: "نهاية الجلسة",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "حالة المعاملة / الحوالة",
    selectFund: "إختيار القيمة",
    CashOutTypeSelect: "إختيار طريقة القبض",
    login_span: "ليس لديك حساب؟",
    signup: "إنشاء حساب",
    signup_span: "هل لديك حساب؟",
    login: " تسجيل الدخول",
    welfare: "رعاية",
    savings: "مدخرات",
    fine: "غرامة",
    endOfCycle: "نهاية الدورة",
    midCycle: "منتصف الدورة",
    membership: "عضوية",
    sharedBased: "أساس الأسهم",
    equal: "متساوية",
    refund: "إسترداد",
    selectCashOutType: " إختيار طريقة قبض المجموعة",
    savingsFund: "حساب الإدخار",
    membershipFund: "حساب العضوية",
    welfareFund: "حساب الرعاية     ",
    fineFund: "حساب الغرامة",
    loanInterestFund: "حساب فائدة القرض",
    equally: "متساوية",
    fineCashOutType: "طريقة قبض الغرامة",
    loanServiceCharge: "رسم خدمة القرض",
    welfareCashOutType: " طريقة قبض الرعاية",
    member_role: "الدور في الأسرة",
    processing: "قيد المعالجة",
    back: "Back",
    continue: "Continue",
    no_records_found: "لم تتم إضافة سجلات المعاملات",
    cancel: "Cancel",
    save: "Save",
    bc_wallet: "محفظة بي سي",
    link: "وصلة",
  },

  banner: {
    install: "تحميل",
  },
  locale: {
    date: "en-EN",
    // ar_LB
  },
  memberActions: {
    confirm_message: `حذف الأسرة؟`,
  },
  cashOut: {
    settings_summary_title: "These are the cash-out types set for each fund:",
    savings: "Savings Fund",
    membership: "Membership Fund",
    Welfare: "Welfare Fund",
    fine: "Fine Fund",
    call_to_action: "You can change these settings",
  },
  settings: {
    disclaimer:
      "you can only change cash-out types settings if no transaction has been added to current meeting",
  },
  status: {
    SUCCESSFUL: "تم",
    PENDING: "في الانتظار",
    FAILED: "باءت بالفشل",
  },
  locations: {
    country: "دولة",
    county: "مقاطعة",
    province: "مقاطعة",
    region: "منطقة",
    district: "يصرف",
    gh_district: "العاصمة / البلدية / المنطقة",
    municipality: "البلدية",
    sub_county: "المقاطعة الفرعية",
    administrative_post: "وظيفة إدارية",
    locality: "محلية",
    select_country: "حدد الدولة",
    select_county: "اختر مقاطعة",
    select_sub_county: "حدد المقاطعة الفرعية",
    select_province: "اختر المقاطعة",
    select_region: "اختر المنطقة",
    select_district: "حدد المنطقة",
    select_municipality: "اختر البلدية",
    select_administrative_post: "اختر الوظيفة الادارية",
    select_locality: "حدد العنوان",
    neigbourhood_name: "اسم الحي/الشارع",
  },
}
