import React, { Component } from "react"
import { FormTitle } from "../members/formComponents/FormTitle"
import { FormButton } from "../members/formComponents/FormButton"
import { Container, Form, Alert } from "reactstrap"
import Translate from "react-translate-component"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { selectVslaDebtBalance } from "../../store/reducers/getVslaBalance"
import { connect } from "react-redux"
import { selectMemberSharesFund } from "../../store/reducers/getMemberBalance"
import { toast } from "react-toastify"
import { cashout, confirmCashout } from "../../store/reducers/cashout/actions"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { getTranslation } from "../../view/shared/helpers"
import { NavLink } from "react-router-dom"
import { setCurrentPage } from "../../store/reducers/navigation"
import StellarLoader from "../layout/StellarLoader"

counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const successStyle = {
  fontFamily: "quicksand",
  fontSize: "20px",
  color: "green",
  fontWeight: "bolder",
}

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.cash_out_success_msg"
      component="div"
      style={successStyle}
    />
  )
}

export class MidCycleCashOut extends Component {
  notify = (msg) => toast(msg)

  state = {
    cashOutType: "midCycle",
    redirect: false,
    loading: false,
    loadingCashoutData: false,
    cashoutBreakDown: [],
    noCashBreakdownDetails: false,
    toggleAlert: true,
    hasLoan: false,
    hideCashoutTypes: "",
  }

  async componentDidMount() {
    this.handleCashout()
  }

  handleCashout = async () => {
    let memLoan = this.props.memberDebt(
      this.props.member.blockchain_wallet_id,
    ).balance
    if (memLoan > 0 && this.props.groupDebt.amount > 0)
      this.setState({ hasLoan: true })
    else {
      await this.getCashoutDetails()
    }
  }

  getCashoutDetails = async () => {
    this.setState({
      ...this.state,
      loadingCashoutData: true,
      cashoutBreakDown: [],
    })
    const res = await this.props.initiateCashOutProcess(
      this.props.member.blockchain_wallet_id,
      "midCycle",
    )
    if (!this.cashedOut(res)) {
      this.setState({
        ...this.state,
        loadingCashoutData: false,
        cashoutBreakDown: res,
      })
    } else {
      this.setState({
        noCashBreakdownDetails: true,
        loadingCashoutData: false,
        toggleAlert: false,
      })
    }
  }

  cashedOut = (params) => {
    return params.every((element) => element.amount === 0)
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.processCashOut()
  }

  processCashOut() {
    const lang_code = localStorage.getItem("lang")
    let savingsCashOutValue = this.state.cashoutBreakDown.filter(
      ({ fundName }) => fundName === "Savings fund",
    )
    savingsCashOutValue = savingsCashOutValue.length
      ? savingsCashOutValue[0].amount
      : 0

    const lang = {
      FRANÇAIS: {
        message: `Confirmer le retrait de ${savingsCashOutValue} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm cash out of ${savingsCashOutValue} for member`,
      },
      SWAHILI: {
        message: `Thibitisha mwanachama atapewa mchango wa shilingi ${savingsCashOutValue}`,
      },
      SPANISH: {
        message: `Confirmar efectivo de ${savingsCashOutValue} lempiras para miembro`,
      },
      PORTUGUESE: {
        message: `Confirme o saque de ${savingsCashOutValue} para o membro`,
      },
      ARABIC: {
        message: `أكد سحب ${savingsCashOutValue} للأسرة`,
      },
    }

    if (window.confirm(lang[lang_code].message)) {
      this.setState({ ...this.state, loading: true, hideCashoutTypes: "none" })

      const { member, meeting } = this.props

      this.props.confirmCashout(
        member.blockchain_wallet_id,
        meeting[0].id,
        this.state.cashOutType,
      )

      this.notify(<SuccessMessage />)

      this.setState({ ...this.state, loading: false })

      this.props.setPage("group-memberList")
    }
  }

  showCashOutMessage() {
    let savingsCashOutValue = ""
    let membershipCashOutValue = ""
    let fineCashOutValue = ""
    let welfareCashOutValue = ""

    if (
      this.state.cashOutType !== "" &&
      document.getElementById("savingsCashOutAmount") !== null
    ) {
      savingsCashOutValue = document.getElementById("savingsCashOutAmount").innerText
    }

    if (
      this.state.cashOutType !== "" &&
      document.getElementById("membershipCashOutAmount") !== null
    ) {
      membershipCashOutValue = document.getElementById(
        "membershipCashOutAmount",
      ).innerText
    }

    if (
      this.state.cashOutType !== "" &&
      document.getElementById("fineCashOutValue") !== null
    ) {
      fineCashOutValue = document.getElementById("fineCashOutAmount").innerText
    }

    if (
      this.state.cashOutType !== "" &&
      document.getElementById("welfareCashOutAmount") !== null
    ) {
      welfareCashOutValue = document.getElementById("welfareCashOutAmount").innerText
    }

    return (
      savingsCashOutValue &&
      membershipCashOutValue &&
      fineCashOutValue &&
      welfareCashOutValue
    )
  }

  deactivateSubmit() {
    if (!this.eligibleForCashout()) {
      return true
    } else if (this.state.loadingCashoutData) {
      return true
    }
    return false
  }

  eligibleForCashout() {
    if (this.state.cashoutBreakDown.length) {
      return this.state.cashOutType
        ? this.state.cashOutType === "midCycle"
          ? this.props.memberDebt(this.props.member.blockchain_wallet_id).balance < 1
          : this.props.groupDebt.amount < 1
        : false
    }
    return false
  }

  render() {
    const { vsla } = this.props
    const { loading } = this.state

    counterpart.setLocale(localStorage.getItem("lang"))
    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "1.2rem",
      textAlign: "center",
      paddingBottom: "0.5em",
    }

    if (loading) {
      return <StellarLoader />
    }

    return (
      <Container>
        <div>
          <h4 style={headerStyle}>{this.props.member.full_name}</h4>
        </div>
        <FormTitle style={headerStyle} title="menu_mem.midCycle_Cashout" />

        <Form onSubmit={this.onSubmit}>
          {this.state.noCashBreakdownDetails && (
            <Alert color="danger">
              <Translate content="alert.error" component="h4" />
              <span>
                <Translate
                  content="alert.member_cashed_out"
                  style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
                />
              </span>
            </Alert>
          )}
          {this.state.hasLoan && (
            <Alert color="danger">
              <Translate content="alert.failed" component="h4" />
              <span>
                <Translate
                  content="alert.member_pending_loan"
                  style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
                />
              </span>
            </Alert>
          )}
          {this.state.loadingCashoutData && (
            <span style={{ display: "flex" }}>
              <span
                className="dots-loader"
                style={{
                  color: "#E36F1E",
                  textAlign: "center",
                  fontSize: 12,
                  margin: "auto",
                }}
              >
                processing<span style={{ position: "absolute" }}></span>
              </span>
            </span>
          )}
          <ul style={{ listStyle: "none" }}>
            {this.state.cashoutBreakDown.map((item) => (
              <li style={{ padding: "4px 0" }}>
                {`${item.fundName}: ${item.amount}`}{" "}
              </li>
            ))}
          </ul>

          <br />
          <FormButton
            type={"submit"}
            value="placeholder_copy.income_submit"
            name="submit"
            className="btn btn-lg form-control"
            disabled={this.deactivateSubmit()}
          />
        </Form>
        <Alert color="info" style={{ display: this.state.hideCashoutTypes }}>
          <p>{getTranslation("cashOut", "settings_summary_title")}</p>
          <p>
            <strong>{getTranslation("cashOut", "savings")}</strong>:{" "}
            {this.props.vsla.metadata.savingsCashOutType}
          </p>
          <p>
            <strong>{getTranslation("cashOut", "loan_interest")}</strong>:{" "}
            {this.props.vsla.metadata.loanInterestCashOutType}
          </p>
          <p>
            <strong>{getTranslation("cashOut", "membership")}</strong>:{" "}
            {this.props.vsla.metadata.membershipCashOutType}
          </p>
          <p>
            <strong>{getTranslation("cashOut", "welfare")}</strong>:{" "}
            {this.props.vsla.metadata.welfareCashOutType}
          </p>
          <p>
            <strong>{getTranslation("cashOut", "fine")}</strong>:{" "}
            {this.props.vsla.metadata.fineCashOutType}
          </p>
          <br />
          <p>
            {getTranslation("cashOut", "call_to_action")}{" "}
            <NavLink
              onClick={(e) => {
                const hasTransaction =
                  this.props.transactions.filter(
                    ({ meetingId }) =>
                      parseInt(meetingId) === parseInt(this.props.meeting[0].id),
                  ).length > 0
                if (hasTransaction) {
                  e.preventDefault()
                  alert(getTranslation("settings", "disclaimer"))
                }
              }}
              to={{
                pathname: `/Groups/${this.props.group[0].id}/settings`,
                state: {
                  description: vsla.description,
                  vsla_id: this.props.group[0].id,
                  group_id: vsla.id,
                },
              }}
            >
              here
            </NavLink>
          </p>
        </Alert>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    memberDebt: (id) => state.account.members[id].loan,
    groupDebt: selectVslaDebtBalance(state),
    membersSaving: selectMemberSharesFund(state),
    meeting: state.currentOngoingMeeting,
    member: state.loadedMember,
    vsla: state.vsla,
    group: state.loadedvslas,
    transactions: state.loadedMeetingTransaction,
  }
}

const mapDispatchToProps = (dispatch) => ({
  initiateCashOutProcess: (id, cycle) => dispatch(cashout(id, cycle)),
  confirmCashout: (id, meetingId, cycle) =>
    dispatch(confirmCashout(id, meetingId, cycle)),
  setPage: (page) => dispatch(setCurrentPage(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MidCycleCashOut)
