import React from "react"
import { getTranslation } from "../../view/shared/helpers"
import stellarProcessing from "../../view/assets/stellar-animation.gif"
import pending from "../../view/assets/pending.svg"
import done from "../../view/assets/done.svg"

const spinnerStyle = {
  height: "70%",
  width: "70%",
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const itemStyle = {
  padding: 5,
}

const containerStyle = {
  margin: "auto",
  display: "flex",
  flexDirection: "column",
}

const StellarLoader = (props) => {
  return (
    <div id="stellar-loader" style={{ textAlign: "center" }}>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
        <br />
        <span style={loadingStyle}>{getTranslation("title_copy", "loading")}</span>
        <p>{props.msg ? props.msg : ""}</p>
      </center>
    </div>
  )
}

const IconState = ({ iconStyle, off }) => (
  <img style={{ ...iconStyle, marginLeft: 3 }} src={off ? done : pending} alt="" />
)

const EPOCHES = [10000, 10000, 30000, 10000]
export const GroupCreateLoader = (props) => {
  const [timer1, setTimer1] = React.useState(false)
  const [timer2, setTimer2] = React.useState(false)
  const [timer3, setTimer3] = React.useState(false)
  const [timer4, setTimer4] = React.useState(false)

  React.useEffect(() => {
    initTimers()
  }, [])

  const [consumedIndces, setConsumedIndices] = React.useState([])

  const getUniqueIndex = () => {
    let randomIndex = Math.floor(Math.random() * EPOCHES.length)
    if (randomIndex in consumedIndces) {
      getUniqueIndex()
      return
    }
    setConsumedIndices([...consumedIndces, randomIndex])
    return randomIndex
  }

  const initTimers = () => {
    Array.from({ length: EPOCHES.length }).forEach((key, idx) => {
      const sec = EPOCHES[getUniqueIndex()]
      const multiplier = (idx + 1) * sec
      timerFun(idx + 1, multiplier)
    })
  }

  const timerFun = (val, period) => {
    let func = setTimer1
    switch (val) {
      case 1: {
        func = setTimer1
        break
      }
      case 2: {
        func = setTimer2
        break
      }
      case 3: {
        func = setTimer3
        break
      }
      case 4: {
        func = setTimer4
        break
      }
      default: {
        return
      }
    }
    setTimeout(() => {
      func(true)
    }, period)
  }

  return (
    <div id="stellar-loader" style={{ display: "flex", flexDirection: "column" }}>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
      </center>
      <div style={containerStyle}>
        <span style={itemStyle}>
          <IconState iconStyle={props.iconStyle} off={timer1} />
          {getTranslation("placeholder_copy", "loader_msg1")}
        </span>
        <span style={itemStyle}>
          <IconState iconStyle={props.iconStyle} off={timer2} />
          {getTranslation("placeholder_copy", "loader_msg2")}
        </span>
        <span style={itemStyle}>
          <IconState iconStyle={props.iconStyle} off={timer3} />
          {getTranslation("placeholder_copy", "loader_msg3")}
        </span>
        <span style={itemStyle}>
          <IconState iconStyle={props.iconStyle} off={timer4} />
          {getTranslation("placeholder_copy", "loader_msg4")}
        </span>
      </div>
    </div>
  )
}

export default StellarLoader
