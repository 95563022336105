export function pendingTransactions(state = {}, action) {
  const cloneState = { ...state }
  switch (action.type) {
    case "SET_PENDING_TRANSACTIONS":
      const itemKey = Object.keys(action.payload)[0]
      const storedItem = cloneState[itemKey] || {}
      const updatedItem = {
        ...storedItem,
        ...Object.values(action.payload)[0],
      }
      return { ...cloneState, [itemKey]: updatedItem }
    case "CLEAR_PENDING_TRANSACTIONS":
      return {}
    case "REMOVE_PENDING_TRANSACTION":
      delete cloneState[action.id]
      return cloneState
    default:
      return state
  }
}
