import axios from "axios"
import {
  SET_LEARNING_CATEGORIES,
  SET_LEARNING_COURSES,
  SET_LEARNING_LESSONS,
  SET_LEARNING_TRANSLATIONS,
  SET_LEARNING_CONTENT,
  triggerAction,
  SET_CATEGORY_ITEMS,
} from "../../constants"
import { AIRTABLE_BASE_ENV } from "../../../components/educationalContent/helpers"

export const setCategoryItems = (item) => ({
  type: SET_CATEGORY_ITEMS,
  item,
})

export const setLessons = (item) => ({
  type: "SET_LESSONS",
  item,
})

export const setLessonContent = (item) => ({
  type: "SET_LESSON_CONTENT",
  item,
})
const getActionType = (index) => {
  switch (index) {
    case 0:
      return SET_LEARNING_CATEGORIES
    case 1:
      return SET_LEARNING_COURSES
    case 2:
      return SET_LEARNING_LESSONS
    case 3:
      return SET_LEARNING_TRANSLATIONS
    case 4:
      return SET_LEARNING_CONTENT
    default:
      return null
  }
}

export const setSelectedLanguage = (language) => ({
  type: "SET_SELECTED_LANGUAGE",
  language,
})

export const deleteLanguage = (language) => ({
  type: "DELETE_LANGUAGE",
  language,
})

export const getLearningData = () => async (dispatch) => {
  try {
    const tableNames = ["category", "course", "lesson", "translation", "content"]
    const fetchPromises = tableNames.map((tableName, index) => {
      return dispatch(fecthAirtableData(getActionType(index), tableName)) // getActionType is a helper function to get the corresponding action type based on index
    })
    await Promise.all(fetchPromises)
  } catch (error) {
    console.log("Error: ", error)
  }
}

export const fecthAirtableData = (actionType, tableName) => {
  return async function (dispatch) {
    try {
      const pageSize = 100
      let offset = ""
      let allDataFields = []

      do {
        const response = await axios.get(
          `${window.REACT_APP_PWA_AIRTABLE_URL}/${AIRTABLE_BASE_ENV}/${tableName}?view=Grid%20view&pageSize=${pageSize}&offset=${offset}`,
          {
            headers: {
              Authorization: `Bearer ${window.REACT_APP_PWA_AIRTABLE_API_KEY}`,
            },
          },
        )
        const { records, offset: newOffset } = response.data
        allDataFields = allDataFields.concat(records.map(({ fields }) => fields))
        offset = newOffset
      } while (offset)

      dispatch(triggerAction(actionType, allDataFields))
    } catch (error) {
      console.log("Error: ", error)
    }
  }
}
