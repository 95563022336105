import { meetingLoaded } from "./getMeeting"
import { backendAPI } from "../../api"

export const meetingsLoadStarted = (bool) => ({
  type: "MEETINGS_LOAD_START",
  isloading: bool,
})

export const meetingsLoaded = (meetings) => ({
  type: "MEETING_ITEMS",
  meetings,
})

export const meetingsLoadError = (error) => ({
  type: "MEETINGS_LOAD_ERROR",
  error: error,
})

export function fetchGroupMeetings(headers) {
  return async function (dispatch, getState) {
    dispatch(meetingsLoadStarted(true))
    try {
      const group_id = getState().loadedvslas[0].id
      const url = `/api/v1/groups/${group_id}/meetings`
      const res = await backendAPI.get(url, headers)
      dispatch(
        meetingLoaded(res.data.filter((meeting) => meeting.status === "ongoing")),
      )
      dispatch(meetingsLoaded(res.data))
      dispatch(meetingsLoadStarted(false))
    } catch (error) {
      dispatch(meetingsLoadError(error))
      dispatch(meetingsLoadStarted(false))
    }
  }
}
