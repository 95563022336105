import { createSelector } from "reselect"

export function loadedvslas(state = [], action) {
  switch (action.type) {
    case "LOADED_VSLA":
      return action.vslas
    default:
      return state
  }
}

export function vlsasLoadingStarted(state = false, action) {
  switch (action.type) {
    case "VSLA_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function vlsasLoadingError(state = "", action) {
  switch (action.type) {
    case "VSLA_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}

export const existingVsla = (state) => state.vsla
export const existingVslaFunds = (state) => state.vsla.accounts
export const existingGroup = (state) => state.loadedvslas[0]
export const vslaMetadata = (state) => state.vsla.metadata
export const vslaAccounts = (state) => state.vsla.accounts
export const members = (state) => state.loadedvslaMembers
export const numberOfMeetings = (state) => state.groupMeetings

export const selectCreatedVslaInfo = createSelector(
  existingVsla,
  existingGroup,
  vslaMetadata,
  members,
  vslaAccounts,
  numberOfMeetings,
  (v, g, vMeta, m, acc, meets) => {
    return [
      {
        vsla: v,
        group: g,
        metadata: vMeta,
        members: m,
        accounts: acc,
        meetings: meets,
      },
    ]
  },
)

export const selectVlsaFundAccounts = createSelector(
  existingVslaFunds,
  (vslaFunds) => {
    return vslaFunds
      .filter((vslaFund) => vslaFund.type !== "fund")
      .filter((vslaFund) => vslaFund.type !== "issuer")
      .map((vslaFund) => {
        return [vslaFund.type].flat(1)
      })
  },
)
