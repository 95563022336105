import React from "react"
import { Card } from "reactstrap"
import { connect } from "react-redux"
import { setCurrentPage } from "../../store/reducers/navigation"
import { setCategoryItems, setLessonContent } from "../../store/actions/learning"
import { getLessonContentById, getLearningLanguage } from "./helpers"

const headerStyle = {
  fontWeight: "500",
  textTransform: "capitalize",
  padding: "5px 10px",
}
const contentStyle = {
  padding: "0 10px",
}
const cardStyle = { margin: "8px 0" }

const CardView = (props) => {
  const viewContent = () => {
    if (props.isLesson) {
      const content = getLessonContentById(
        props.lesson_id[0],
        props.learningData.contents,
        [getLearningLanguage()],
      )
      props.setLessonContent({
        content,
        lessonName: props.name,
        lesson_fk_id: props.lesson_fk_id,
        num_of_views: props.num_of_views,
      })
      props.setPage("group-lessonPage")
      return
    }
    props.setCategoryItems({ lessons: props.lessons, courses: [props.course] })
    props.setPage("group-lessonsPage")
  }
  return (
    <Card style={cardStyle} onClick={viewContent}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <span
          onClick={viewContent}
          style={{ fontSize: 12, margin: 3, cursor: "pointer" }}
        >
          View
        </span>
      </div>
      <h6 style={headerStyle}>{props.name}</h6>
      <p
        style={{
          ...contentStyle,
          display:
            props.description && props.description.length ? "initial" : "none",
        }}
      >
        {props.description}
      </p>
    </Card>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    setCategoryItems: (data) => dispatch(setCategoryItems(data)),
    setLessonContent: (data) => dispatch(setLessonContent(data)),
  }
}
const mapStateToProps = (state) => {
  return {
    learningData: state.learningData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardView)
