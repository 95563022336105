import { vslaLoaded, fetchVslas, loadVslaSuccess } from "./getVslas"
import { defaultGroupSettings } from "../../view/shared/helpers"
import { setCurrentPage } from "../reducers/navigation"
import { backendAPI, blockchainAPI } from "../api"
import { vslaMemberLoaded } from "./members/getVslaMembers"
import { triggerAction } from "../constants"

export const isCreatingVsla = (bool) => ({
  type: "IS_CREATING_VSLA",
  isCreating: bool,
})

export const createVsla = (vsla) => ({
  type: "CREATE_VSLA",
  vsla,
})

export const createGroup = (group) => ({
  type: "CREATE_GROUP",
  group,
})

export const createVslaError = (error) => ({
  type: "CREATE_VSLA_ERROR",
  error: error,
})

export const createGroupError = (bool) => ({
  type: "CREATE_GROUP_ERROR",
  groupError: bool,
})

export const checkGroupName = (name) => async (dispatch) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }
  return backendAPI.get(`/api/v1/groups/names/${name}`, headers)
}

export function postCreateVsla(url, vsla, headers) {
  return async function (dispatch) {
    try {
      dispatch(isCreatingVsla(true))
      const isExist = await checkGroupName(vsla.description)
      if (isExist.data && isExist.data.status === "Active") {
        dispatch(isCreatingVsla(false))
        throw new Error("duplicateGroup")
      }
      const res = await blockchainAPI.post(
        url,
        {
          ...vsla,
          metadata: {
            ...vsla.metadata,
            ...defaultGroupSettings,
          },
        },
        headers,
      )
      dispatch(createVsla(res.data))
      dispatch(isCreatingVsla(false))
      dispatch(setCurrentPage("group-addFunds"))
      return Promise.resolve(res.data)
    } catch (error) {
      dispatch(createVslaError(error))
      dispatch(isCreatingVsla(false))
      return Promise.reject(error)
    }
  }
}

export function postCreateGroup(url, data, headers, ownProps) {
  return async function (dispatch, getState) {
    try {
      dispatch(isCreatingVsla(true))
      localStorage.setItem("acts", data.accounts.length)
      const res = await backendAPI.post(url, data, headers)
      const { group, group_wallet, member } = res.data
      dispatch(loadVslaSuccess(group_wallet))
      dispatch(vslaLoaded([group]))
      dispatch(createGroup(group))
      dispatch(vslaMemberLoaded([member]))
      dispatch(setCurrentPage("group-main"))
      dispatch(isCreatingVsla(false))
    } catch (error) {
      console.log("Error ", error)
      dispatch(isCreatingVsla(false))
      dispatch(createGroupError(true))
      return Promise.reject(error.response.detail)
    }
    dispatch(
      fetchVslas(localStorage.getItem("token"), localStorage.getItem("tokenBC")),
    )
  }
}

export const updateGroupSettings = (data) => async (dispatch, getState) => {
  const { vsla } = getState()
  try {
    const res = await blockchainAPI.patch(`/vsla/${vsla.id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenBC"),
      },
    })
    return Promise.resolve(res)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getProjects = () => async (dispatch) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    const { data } = await backendAPI.get("/api/v1/projects/", headers)
    dispatch(triggerAction("SET_PROJECTS", data))
  } catch (error) {
    console.log("Error: ", error)
  }
}
