import Dexie from "dexie"
export const db = new Dexie("vslaDB")

const checkStorageSize = async (sizeInBytes) => {
  if (!navigator.storage || !navigator.storage.estimate) {
    // Fallback to checking the total space available in the origin's storage
    const { usage, quota } = await navigator.storageQuota.queryInfo("temporary")
    return quota - usage >= sizeInBytes
  }
  const { usage, quota } = await navigator.storage.estimate()
  return usage + sizeInBytes <= quota
}

const checkPersistStorage = async () => {
  if (!navigator.storage || !navigator.storage.persist) {
    return false
  }
  //check whether the storage is currently persisted or not
  const persisted = await navigator.storage.persisted()
  if (persisted) {
    return true
  }
  //requests the browser to persist storage
  const granted = await navigator.storage.persist()
  return granted
}

export const isIndexedDBSupported = () => {
  return "indexedDB" in window
}

db.version(2).stores({
  accounts: "++cacheId, data, error, vslaId",
  offlineMeeting: "++cacheId, data, error, vslaId",
  members: "++cacheId, data, requestObject, refId, error, vslaId",
  pendingTransactions: "++cacheId, data, meetingId, requestObject, error, vslaId",
})

export const addToCache = async (table, data) => {
  // ensure data persists, avoiding browser eviction mechanisms

  const persisted = await checkPersistStorage()
  const buffer = new ArrayBuffer(data)
  const hasStorage = await checkStorageSize(buffer.byteLength)
  if (!persisted) {
    console.error("Storage permission not granted, data may be evicted")
  }
  if (!hasStorage) {
    alert("your device is running out of space!")
    return
  }
  if (table === "offlineMeeting" && data.data.id) {
    localStorage.setItem("_m_id", data.data.id)
  }

  try {
    const cacheId = await db.table(table).add(data) // add data to IndexedDB
    console.log(`successfully added cache to table: ${table}`)
    return cacheId
  } catch (error) {
    console.log(`failed to add cache for table: ${table}, ${error}`)
  }
}

export const deleteCacheByKey = async (table, key, id) => {
  if (!id || !key) {
    console.log(`Error: invalid values key ${key} id ${id}`)
    return
  }
  if (table === "offlineMeeting") {
    localStorage.removeItem("_m_id")
  }

  try {
    const res = await db
      .table(table)
      .where({ [key]: id })
      .delete()
    console.log(`successfully deleted row id: ${id} of table: ${table}`)
    return res
  } catch (error) {
    console.log(`failed to delete cache id: ${id} for table: ${table}`)
  }
}

export const updateCacheItem = async (table, data, cacheId) => {
  try {
    if (!cacheId) {
      return
    }
    let oldData = await db.table(table).where({ cacheId }).toArray()
    if (!oldData.length) {
      return
    }
    oldData = oldData[0]
    await db.table(table).put({ cacheId, ...oldData, ...data })
    console.log(`successfully updated cache id: ${cacheId} of table: ${table}`)
  } catch (error) {
    console.log(
      `failed to update cache id: ${cacheId} of table: ${table} error: ${error}`,
    )
  }
}

export const getCacheItemByRefID = async (table, refId) => {
  if (!refId) {
    console.log(`Invalid refId: ${refId}`)
    return
  }
  try {
    const result = await db.table(table).where({ refId }).toArray()
    return result
  } catch (error) {
    console.error(`Error getting cache item by refId: ${refId} error: ${error}`)
  }
}

export const getCacheById = async (table, cacheId) => {
  try {
    if (!cacheId) {
      console.log(`Invalid cacheId: ${cacheId}`)
      return
    }
    const result = await db.table(table).where({ cacheId }).toArray()
    return result
  } catch (error) {
    console.error(`Error getting cache item by cacheId: ${cacheId} error: ${error}`)
  }
}

export const clearAllTables = async () => {
  try {
    const tableNames = ["accounts", "members"]
    for (const tableName of tableNames) {
      await db.table(tableName).clear()
      console.log(`Successfully cleared all data from table: ${tableName}`)
    }
    //clear meeting table if there are no transactions and user is logging out
    await clearOfflineMeetingTable()
  } catch (error) {
    console.error(`Failed to clear data from all tables: ${error}`)
  }
}

async function clearOfflineMeetingTable() {
  try {
    const meetingsToDelete = await db
      .table("offlineMeeting")
      .filter((meeting) => {
        return (
          meeting.data.status === "ongoing" && meeting.data.transactions.length === 0
        )
      })
      .toArray()

    for (const meeting of meetingsToDelete) {
      await db.table("offlineMeeting").delete(meeting.cacheId)
      console.log(`Deleted meeting with ID ${meeting.cacheId}`)
    }
  } catch (error) {
    console.error("Error while clearing offlineMeeting table:", error)
  }
}
