import React from "react"
import { Container, Form, FormGroup, Input, Alert, Button } from "reactstrap"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { postCreateMeeting } from "../../store/actions/meetings/createMeeting"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import uuid from "uuid"
import { triggerAction } from "../../store/constants"
import { setCurrentPage } from "../../store/reducers/navigation"
import { getUserGeoData } from "../../view/shared/helpers"
import { postGeoLocationLat, postGeoLocationLong } from "../../store/actions/geoData"
import { addToCache } from "../../view/shared/utilities/cache"
import StellarLoader from "../layout/StellarLoader"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

export const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.meeting_create_success"
      component="div"
      style={loadingStyle}
    />
  )
}

export const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.meeting_create_error"
      component="div"
      style={loadingStyle}
    />
  )
}

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
}

const subheaderStyle = {
  padding: ".3rem",
  textAlign: "center",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#696969",
  fontStyle: "italic",
  fontWeight: "bold",
}

const listStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  padding: "3px",
}

const notify = (msg) => toast.info(msg)
const defaultObj = {
  date: "",
  vsla_id: "",
  attendees: [],
  isLoading: false,
  lang: localStorage.getItem("lang"),
  longi: "",
  lati: "",
  county: "",
  village: "",
  country: "",
  errors: "",
  buttonDisable: false,
}

const CreateMeeting = (props) => {
  const [state, setState] = React.useState({ ...defaultObj })

  const onChange = (e) => {
    setState({
      ...state,
      buttonDisable: false,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setState({ ...state, isLoading: true, buttonDisable: true })
    createMeeting(
      state.date,
      state.attendees,
      props.geoLong,
      props.geoLat,
      state.county,
      state.country,
      state.village,
    )
    // setState({ ...state, date: "" });
  }

  React.useEffect(() => {
    const { vsla, group } = props.vslaSummary[0]
    const vsla_id = vsla.id
    const description = vsla.description
    const group_id = group.id
    const activeMembers = props.loadedvslaMembers.filter(
      (member) => member.blockchain_wallet_id !== null && member.status === "Active",
    )
    setState({
      ...state,
      group_id,
      vsla_id,
      description,
      attendees: activeMembers,
      country: group.country,
      county: group.county,
      village: group.village,
    })
    const success = (pos) => {
      const cord = pos.coords
      props.sendGeoDataLat(cord.latitude)
      props.sendGeoDataLong(cord.longitude)
    }
    const error = (err) => {
      console.warn(`Error ${err.code}: ${err.message}`)
    }
    getUserGeoData(success, error)
  }, [])

  const createMeeting = async (
    date,
    attendees,
    longi,
    lati,
    county,
    country,
    village,
  ) => {
    const finalDate = !date ? new Date() : new Date(date)
    const lang = localStorage.getItem("lang")

    const group_name = state.description
    const group_id = state.group_id

    const notif = {
      ENGLISH: {
        message: `The secretary has started a meeting for ${group_name} group on VSLA Online`,
      },
      FRANÇAIS: {
        message: `FRA - The secretary has started a meeting for ${group_name} group on VSLA Online`,
      },
      SWAHILI: {
        message: `SWA - The secretary has started a meeting for ${group_name} group on VSLA Online`,
      },
      SPANISH: {
        message: `La secretaria ha iniciado una reunión para el grupo ${group_name} en VSLA Online`,
      },
      LEBANESE: {
        message: `The officer has started a session for ${group_name} community on VSLA.online`,
      },
      PORTUGUESE: {
        message: `O/A secretário/a iniciou uma reunião para o grupo ${group_name} no VSLA Online`,
      },
      ARABIC: {
        message: group_name,
      },
    }
    const data = {
      requestId: uuid.v4(),
      date: finalDate,
      status: "ongoing",
      longi: longi,
      lati: lati,
      county: county,
      village: village,
      country: country,
      attendees: attendees.map(({ pubkey }) => pubkey),
      msg: Object.values(notif[lang])[0],
    }

    if (!props.isConnected) {
      await addToCache("offlineMeeting", {
        data,
        vslaId: state.vsla_id,
      })
      notify(<SuccessMessage />)
      props.setPage("group-meetingsMenu")
      setState({ ...state, isLoading: false, buttonDisable: false })
    } else {
      const url = `/api/v1/groups/${group_id}/meetings`

      const headers = {
        headers: {
          Authorization:
            "Bearer " + props.user.token || localStorage.getItem("token"),
        },
      }
      props
        .sendMeetingData(url, data, headers)
        .then((res) => {
          notify(<SuccessMessage />)
          setState({ ...defaultObj })
          props.setPage("group-meetingsMenu")
        })
        .catch((error) => {
          notify(<ErrorMessage />)
        })
        .finally(() => {
          setState({ ...state, isLoading: false, buttonDisable: false })
        })
    }
  }

  const todaysDate = () => {
    let todays = new Date().toISOString()
    let ISOArray = todays.slice(0, 10).split("-")
    const defaultDate = ISOArray[0] + "-" + ISOArray[1] + "-" + ISOArray[2]
    return defaultDate
  }

  counterpart.setLocale(state.lang)
  const meeting_submit = counterpart.translate("placeholder_copy.meeting_submit")
  const meeting_submitting = counterpart.translate(
    "placeholder_copy.meeting_submitting",
  )
  const meeting_date = counterpart.translate("placeholder_copy.meeting_date")

  return (
    <div>
      {state.isLoading ? (
        <StellarLoader />
      ) : (
        <>
          <div
            style={{
              backgroundColor: "white",
              padding: ".5rem",
              textAlign: "center",
              margin: "34px 0 0 0",
            }}
          >
            <Translate
              content="title_copy.create_meeting"
              component="h4"
              style={headerStyle}
            />
          </div>
          <Form onSubmit={onSubmit}>
            <Container>
              <FormGroup>
                <Input
                  type="date"
                  name="date"
                  placeholder={meeting_date}
                  selected={state.date}
                  style={loadingStyle}
                  onChange={onChange}
                  defaultValue={todaysDate()}
                  max={todaysDate()}
                />
              </FormGroup>

              <div style={subheaderStyle}>
                {state.description}{" "}
                <Translate content="title_copy.meeting_members" />
              </div>
              <hr style={{ borderColor: "#E36F1E" }} />
              <FormGroup>
                <Container>
                  {state.errors !== "" && (
                    <div className="alert alert-danger">{state.errors}</div>
                  )}
                  <ul>
                    {state.attendees.map((activeMember) => (
                      <ul
                        key={activeMember.id}
                        style={listStyle}
                        className="textStyle"
                      >
                        {activeMember.full_name}
                      </ul>
                    ))}
                  </ul>
                </Container>
              </FormGroup>
            </Container>
            <Container>
              {state.attendees.length === 0 && (
                <Alert color="warning">
                  <span>
                    <Translate
                      content="notifications.meeting_no_member_warning"
                      style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
                    />
                  </span>
                </Alert>
              )}
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  size="sm"
                  block
                  name="submit"
                  // className="btn btn-primary"
                  disabled={state.attendees.length === 0 || state.buttonDisable}
                >
                  {state.buttonDisable ? meeting_submitting : meeting_submit}
                </Button>
              </FormGroup>
            </Container>
          </Form>
        </>
      )}
    </div>
  )
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loadedvslaMembers: state.loadedvslaMembers,
    isConnected: state.isConnected,
    geoLat: state.geoDataReturnedLat,
    geoLong: state.geoDataReturnedLong,
    vslaSummary: selectCreatedVslaInfo(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendGeoDataLat: (lat) => dispatch(postGeoLocationLat(lat)),
    sendGeoDataLong: (long) => dispatch(postGeoLocationLong(long)),
    setPage: (page) => dispatch(setCurrentPage(page)),
    setOfflineMeeting: (data) =>
      dispatch(triggerAction("SET_OFFLINE_MEETING", data)),
    sendMeetingData: (url, data, headers) =>
      dispatch(postCreateMeeting(url, data, headers, ownProps)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMeeting)
