export function isCreatingVslaMember(state = false, action) {
  switch (action.type) {
    case "IS_CREATING_MEMBER":
      return action.isCreating
    default:
      return state
  }
}

export function vslaMember(state = [], action) {
  switch (action.type) {
    case "CREATE_MEMBER":
      return action.member
    default:
      return state
  }
}

export function groupMember(state = [], action) {
  switch (action.type) {
    case "CREATE_MEMBER_TWO":
      return action.memberTwo
    default:
      return state
  }
}

export function createMemberError(state = false, action) {
  switch (action.type) {
    case "CREATE_MEMBER_ERROR":
      return action.error
    default:
      return state
  }
}
