import React from "react"
import { Container } from "reactstrap"
import Translate from "react-translate-component"
import Courses from "./courses"
import { getTranslationsById, getLearningLanguage } from "./helpers"
import { connect } from "react-redux"
import { getLearningData, setCategoryItems } from "../../store/actions/learning"

import { setSelectedLanguage } from "../../store/actions/learning"

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  margin: "4px 2.4px",
}

const MainView = (props) => {
  const [categories, setCategories] = React.useState([])
  setSelectedLanguage([localStorage.getItem("lang")])

  React.useEffect(() => {
    if (!props.learningData) {
      return
    }
    // Filter out none financial literacy courses
    const filteredCategories = (props.learningData.categories || []).filter(
      (item) => item.category_id === 2,
    )
    const categoriesTrans = getTranslationsById(
      filteredCategories,
      props.learningData.translations,
      [getLearningLanguage()],
    )
    setCategories(categoriesTrans)
  }, [props.learningData])

  return (
    <Container>
      <div style={containerStyle}>
        <Translate content="learningContent.title" component="h5" />
        <Translate content="learningContent.description" component="h6" />
        <Courses categories={categories} />
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    learningData: state.learningData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLearningData: () => dispatch(getLearningData()),
    setCategoryItems: (data) => dispatch(setCategoryItems(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView)
