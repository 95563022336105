import { triggerAction } from "../constants"
import ReactGA from "react-ga4"

export default (state = null, action) => {
  switch (action.type) {
    case "SET_PAGE":
      if (action.payload) {
        const customUrl = action.payload.toLowerCase().split("-").join("/")
        ReactGA.send({
          hitType: "pageview",
          page: customUrl,
        })
      }
      return action.payload
    default:
      return state
  }
}

export function previousPage(state = null, action) {
  switch (action.type) {
    case "SET_PREVIOUS_PAGE":
      return action.payload
    default:
      return state
  }
}

export const setCurrentPage = (payload) => (dispatch) => {
  dispatch(triggerAction("SET_PAGE", payload))
}

export const setPreviousPage = (payload) => (dispatch) => {
  dispatch(triggerAction("SET_PREVIOUS_PAGE", payload))
}
