export const requestGeoDataLat = (bool) => ({
  type: "IS_REQUESTING_GEODATA_LAT",
  isRequestingLat: bool,
})

export const requestGeoDataLong = (bool) => ({
  type: "IS_REQUESTING_GEODATA_LONG",
  isRequestingLong: bool,
})

export const getGeoDataLat = (lat) => ({
  type: "GET_GE0DATA_LAT",
  lat,
})

export const getGeoDataLong = (long) => ({
  type: "GET_GE0DATA_LONG",
  long,
})

export const geoDataError = (error) => ({
  type: "GEO_DATA_ERROR",
  error,
})

export function postGeoLocationLat(latitude) {
  return async function (dispatch) {
    try {
      dispatch(getGeoDataLat(latitude))
      dispatch(requestGeoDataLat(false))
    } catch (error) {
      return dispatch(geoDataError(error))
    }
  }
}

export function postGeoLocationLong(longitude) {
  return async function (dispatch) {
    try {
      dispatch(getGeoDataLong(longitude))
      dispatch(requestGeoDataLong(false))
    } catch (error) {
      return dispatch(geoDataError(error))
    }
  }
}
