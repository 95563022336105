export function gettingGroupMeetings(state = false, action) {
  switch (action.type) {
    case "MEETINGS_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function groupMeetings(state = [], action) {
  switch (action.type) {
    case "MEETING_ITEMS":
      return action.meetings
    default:
      return state
  }
}

export function groupmeetingsLoadingError(state = [], action) {
  switch (action.type) {
    case "MEETINGS_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}
