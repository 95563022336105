import React, { useState } from "react"
import { Input, Container, Form, FormGroup, Alert } from "reactstrap"
import validateInput from "../../view/shared/validations/phoneVerify"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { createBrowserHistory } from "history"
import Translate from "react-translate-component"
import "react-phone-number-input/style.css"
import "react-responsive-ui/style.css"
import "react-phone-input-2/lib/style.css"
import ar from "react-phone-input-2/lang/ar.json"
import PhoneInput from "react-phone-input-2"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { AuthAPI } from "../../store/api"
import { getTranslation } from "../../view/shared/helpers"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const history = createBrowserHistory({ forceRefresh: true })

const PhoneVerify = () => {
  const [state, setState] = useState({
    phone_number: "",
    isLoaded: false,
    errors: {},
    bounce: "",
    lang: localStorage.getItem("lang"),
    buttonDisable: false,
  })
  const [_country] = React.useState(localStorage.getItem("_country") || "GH")

  const isValid = () => {
    const { errors, isValid } = validateInput(state)
    if (!isValid) {
      setState({ ...state, errors })
    }
    return isValid
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isValid()) {
      setState({ ...state, buttonDisable: true })
      sendVerifcation(state.phone_number).then(
        (res) =>
          history.push({
            pathname: "/VerifyCode",
          }),
        (error) => {
          const { response } = error
          if (!response.data) {
            return
          }
          setState({
            ...state,
            bounce:
              response.data.message ===
              "Error sending code. Contact hiveonline for support"
                ? getTranslation("notifications", "contactHiveForSupportMsg")
                : response.data.message,
            buttonDisable: false,
          })
        },
      )
    } else {
      setState({ ...state, phone_number: "" })
    }
  }

  const phoneValidate = (phone) => {
    if (phone === "+") {
      return ""
    } else {
      return phone
    }
  }

  const sendVerifcation = async (phone_number) => {
    const phone = phoneValidate("+" + phone_number)
    await AuthAPI.post(
      `/api/v1/forgot-pin?lan=${localStorage.getItem("lang")}&app=vsla`,
      {
        phoneNumber: phone,
      },
    )
  }

  const login_email = counterpart.translate("placeholder_copy.login_email")
  const submit = counterpart.translate("placeholder_copy.submit")
  const { errors, bounce } = state

  const inputStyle = {
    flex: "10",
    fontFamily: "Fira Sans",
    height: "50px",
    width: "100%",
    fontSize: "20px",
  }

  return (
    <div>
      <Container>
        <Alert color="secondary" style={loadingStyle}>
          <Translate content="placeholder_copy.account_associated" />
        </Alert>

        <Form onSubmit={onSubmit}>
          {bounce && <div className="alert alert-danger">{bounce}</div>}
          <span style={{ color: "red" }}>{errors.phone_number}</span>
          <FormGroup
            style={{
              direction: "ltr",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PhoneInput
              country={_country.toLocaleLowerCase()}
              enableSearch
              disableSearchIcon
              placeholder={login_email}
              inputStyle={inputStyle}
              style={loadingStyle}
              value={state.phone_number}
              onChange={(phone_number) => setState({ ...state, phone_number })}
              limitMaxLength={true}
              localization={localStorage.getItem("lang") === "ARABIC" ? ar : false}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="submit"
              value={submit}
              name="submit"
              className="btn btn-primary"
              size="lg"
            />
          </FormGroup>
        </Form>
      </Container>
    </div>
  )
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "18px",
}

export default PhoneVerify
