import React from "react"
import PropTypes from "prop-types"
import Translate from "react-translate-component"

export const DisplayOutput = ({ title, style }) => {
  return <Translate content={title} component="p" style={style} />
}

DisplayOutput.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
}
