import React, { Component } from "react"
import { Input, Container, Form, FormGroup, Alert } from "reactstrap"
import validateInput from "../../view/shared/validations/validateToken"
import Translate from "react-translate-component"
import counterpart from "counterpart"
import { toast } from "react-toastify"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { createBrowserHistory } from "history"
import "react-phone-number-input/style.css"
import "react-responsive-ui/style.css"
import PhoneInput from "react-phone-input-2"
import { NavLink } from "react-router-dom"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { backendAPI } from "../../store/api"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const history = createBrowserHistory({ forceRefresh: true })
const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.success"
      component="div"
      style={loadingStyle}
    />
  )
}

const ErrorMessage = () => {
  return (
    <Translate content="notifications.error" component="div" style={loadingStyle} />
  )
}

export class VerifyCode extends Component {
  notify = (msg) => toast.info(msg)
  state = {
    phone_number: "",
    token: "",
    errors: {},
    bounce: "",
    isLoaded: false,
    lang: localStorage.getItem("lang"),
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })

  isValid() {
    const { errors, isValid } = validateInput(this.state)

    if (!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  onSubmit = (e) => {
    e.preventDefault()
    if (this.isValid()) {
      this.verifyToken(this.state.token, this.state.phone_number)
        .then((res) => {
          this.notify(<SuccessMessage />)
          history.push({
            pathname: `/`,
          })
        })
        .catch((error) => {
          const { response } = error
          if (!response.data) {
            return
          }
          this.setState({
            bounce: error.response.data.detail,
          })
          this.notify(<ErrorMessage />)
        })
    } else {
      this.setState({ phone_number: "", token: "" })
    }
  }

  phoneValidate(phone) {
    if (phone === "+") {
      return ""
    } else {
      return phone
    }
  }

  async verifyToken(token, phone_number) {
    const phone = this.phoneValidate("+" + phone_number)
    await backendAPI.post(`/api/v1/verify-code/${token}/${phone}`)
  }

  render() {
    const smscode = counterpart.translate("placeholder_copy.sms_code")
    const submit = counterpart.translate("placeholder_copy.submit")
    const login_email = counterpart.translate("placeholder_copy.login_email")

    const { errors, bounce } = this.state

    const inputStyle = {
      flex: "10",
      fontFamily: "Fira Sans",
      height: "50px",
      width: "100%",
      fontSize: "20px",
    }

    const formStyle = {
      flex: "10",
      padding: "5px",
      fontFamily: "Fira Sans",
      fontSize: "20px",
      height: "50px",
      width: "100%",
    }

    return (
      <div>
        <Container>
          <Alert color="secondary" style={loadingStyle}>
            <Translate content="placeholder_copy.token_sent" />{" "}
            <i>
              <NavLink to="/PhoneVerify">
                <Translate content="placeholder_copy.resend_token" />
              </NavLink>
            </i>
          </Alert>

          <Form onSubmit={this.onSubmit}>
            {bounce && <div className="alert alert-danger">{bounce}</div>}
            <FormGroup
              style={{
                direction: "ltr",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ color: "red" }}>{errors.phone_number}</span>
              <PhoneInput
                country="ne"
                placeholder={login_email}
                inputStyle={inputStyle}
                style={loadingStyle}
                value={this.state.phone_number}
                onChange={(phone_number) => this.setState({ phone_number })}
                onlyCountries={["rw", "ke", "ne", "dk", "se", "tz", "zm", "hn"]}
                limitMaxLength={true}
              />
            </FormGroup>
            <FormGroup>
              <span style={{ color: "red" }}>{errors.token}</span>
              <input
                type="number"
                name="token"
                placeholder={smscode}
                style={formStyle}
                value={this.state.token}
                onChange={this.onChange}
                error={errors.token}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="submit"
                value={submit}
                name="submit"
                className="btn btn-primary"
                size="lg"
              />
            </FormGroup>
          </Form>
        </Container>
      </div>
    )
  }
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "18px",
}
export default VerifyCode
