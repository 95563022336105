import React, { useEffect, useState } from "react"
import { FormTitle } from "../members/formComponents/FormTitle"
import { FormButton } from "../members/formComponents/FormButton"
import { Container, Form, Alert } from "reactstrap"
import Translate from "react-translate-component"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import {
  cashoutStarted,
  confirmCashout,
  deleteCashoutvslaMembers,
  groupCashout,
  getCashoutDetails,
} from "../../store/reducers/cashout/actions"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { getTranslation } from "../../view/shared/helpers"
import { setCurrentPage, setPreviousPage } from "../../store/reducers/navigation"
import { fetchActiveMeetingTransactions } from "../../store/actions/meetings"
import StellarLoader from "../layout/StellarLoader"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.cash_out_success_msg"
      component="div"
      style={successStyle}
    />
  )
}

const notify = (msg) => toast(msg)
const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "700",
  fontSize: "28px",
  lineHeight: "33.6px",
  textAlign: "center",
  paddingBottom: "0.5em",
}

const formHeaderStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "24px",
  textAlign: "center",
  paddingBottom: "0.5em",
}

const EndCycleCashout = (props) => {
  counterpart.setLocale(localStorage.getItem("lang"))

  const [cashingOut, setCashingOut] = useState(false)
  const [cashoutBtnEnable, setCashoutBtnEnable] = useState(false)
  const [hideCashoutTypes, setHideCashoutTypes] = useState("")
  const [cashoutBreakDown, setCashoutBreakDown] = useState(props.cashoutBreakDown)
  const [memberDetails, setMemberDetails] = useState()

  const fetchData = async (memberDetails) => {
    const res = await props.getCashoutDetails(memberDetails)
    setCashoutBreakDown(res)
  }

  useEffect(() => {
    if (props.cashoutMembers[0]) fetchData(props.cashoutMembers[0])
    else setCashoutBreakDown([])
    setMemberDetails(props.cashoutMembers[0])
  }, [props.cashoutMembers])

  useEffect(() => {
    props.fetchActiveMeetingTransactions()
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()
    setCashoutBtnEnable(true)
    setCashingOut(true)
    setHideCashoutTypes("none")

    if (props.cashoutMembers.length) processCashOut()
  }

  const processCashOut = async () => {
    const { meeting, cashoutMembers, user } = props
    await props.confirmCashout(
      memberDetails.blockchain_wallet_id,
      meeting[0].id,
      vsla.id,
      user.id,
    )
    setCashoutBtnEnable(false)
    setCashingOut(false)
    setHideCashoutTypes("")
    setCashoutBreakDown()
    setMemberDetails()
    props.delCashoutMembers(memberDetails.blockchain_wallet_id)

    notify(<SuccessMessage />)
    if (cashoutMembers.length === 1) {
      const { id, group_id } = props.meeting[0]
      props.groupCashout(group_id, id)
      props.cashoutStarted(false)
      props.setPreviousPage("group-endOfCycleCashout")
      props.setPage("group-memberList")
      return
    }
  }

  const hasTransaction = (e) => {
    if (props.transactions.length) {
      e.preventDefault()
      alert(getTranslation("settings", "disclaimer"))
      return
    }
    props.setPage("group-settings")
  }

  if (typeof cashoutBreakDown === "undefined") {
    return <StellarLoader />
  }
  const { vsla, cashoutMembers } = props

  return (
    <Container>
      <div>
        {memberDetails && <h4 style={headerStyle}>{memberDetails.full_name}</h4>}
      </div>
      <FormTitle style={formHeaderStyle} title="title_copy.end_of_cycle_cash_out" />

      <Form onSubmit={onSubmit}>
        <br />

        {cashoutMembers.length === 0 && (
          <Alert color="danger">
            <Translate content="alert.error" component="h4" />
            <span>
              <Translate
                content="alert.all_members_cashed_out"
                style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
              />
            </span>
          </Alert>
        )}

        {memberDetails && (
          <ul style={{ listStyle: "none" }}>
            {cashoutBreakDown.balances.map((item) => (
              <li key={item.fundName} style={{ padding: "4px 0" }}>
                {`${item.fundName}: ${item.amount}`}{" "}
              </li>
            ))}
          </ul>
        )}

        <br />
        {cashoutMembers.length === 1 && cashoutMembers.length > 0 && (
          <FormButton
            type={"submit"}
            value={
              cashingOut ? "placeholder_copy.cashingOut" : "placeholder_copy.finish"
            }
            name="submit"
            className="btn btn-lg form-control"
            disabled={cashoutBtnEnable}
          />
        )}
        {cashoutMembers.length > 1 && (
          <FormButton
            id="active-primary-btn"
            type={"submit"}
            value={
              cashingOut ? "placeholder_copy.cashingOut" : "placeholder_copy.next"
            }
            name="submit"
            className="btn btn-lg form-control"
            disabled={cashoutBtnEnable}
          />
        )}
      </Form>

      <Alert color="info" style={{ display: hideCashoutTypes }}>
        <p>{getTranslation("cashOut", "settings_summary_title")}</p>
        <p>
          <strong>{getTranslation("cashOut", "savings")}</strong>:{" "}
          {props.vsla.metadata.savingsCashOutType}
        </p>
        <p>
          <strong>{getTranslation("cashOut", "loan_interest")}</strong>:{" "}
          {props.vsla.metadata.loanInterestCashOutType}
        </p>
        <p>
          <strong>{getTranslation("cashOut", "membership")}</strong>:{" "}
          {props.vsla.metadata.membershipCashOutType}
        </p>
        <p>
          <strong>{getTranslation("cashOut", "welfare")}</strong>:{" "}
          {props.vsla.metadata.welfareCashOutType}
        </p>
        <p>
          <strong>{getTranslation("cashOut", "fine")}</strong>:{" "}
          {props.vsla.metadata.fineCashOutType}
        </p>
        <br />
        <p>
          {getTranslation("cashOut", "call_to_action")}{" "}
          <span
            className="sp-action"
            onClick={(e) => {
              hasTransaction(e)
            }}
          >
            here
          </span>
        </p>
      </Alert>
    </Container>
  )
}

const successStyle = {
  fontFamily: "quicksand",
  fontSize: "20px",
  color: "green",
  fontWeight: "bolder",
}

const mapStateToProps = (state) => {
  return {
    meeting: state.currentOngoingMeeting,
    vsla: state.vsla,
    group: state.loadedvslas,
    transactions: state.meetingTransactions,
    cashoutMembers: state.membersTocashout,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmCashout: (id, meetingId, group_id, userId) =>
    dispatch(confirmCashout(id, meetingId, group_id, userId)),
  delCashoutMembers: (state) => dispatch(deleteCashoutvslaMembers(state)),
  cashoutStarted: (state) => dispatch(cashoutStarted(state)),
  groupCashout: (id, group_id) => dispatch(groupCashout(id, group_id)),
  setPage: (page) => dispatch(setCurrentPage(page)),
  setPreviousPage: (page) => dispatch(setPreviousPage(page)),
  getCashoutDetails: (memberDetails) => dispatch(getCashoutDetails(memberDetails)),
  fetchActiveMeetingTransactions: () => dispatch(fetchActiveMeetingTransactions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EndCycleCashout)
