import React from "react"
import redArrow from "../../../view/assets/arrowRed.png"
import greenArrow from "../../../view/assets/arrowGreen.png"
import Status from "./Status"
import { useDispatch } from "react-redux"
import uuid from "uuid"
import { initiateTransaction } from "../../../store/reducers/transactions/actions"
import { getTranslation } from "../../../view/shared/helpers"
import { intToArabic } from "../../../view/shared/utilities/arabicNumParser"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../../view/shared/utilities/cache"
import { retryAction } from "../../../store/actions/members/createMember"

const itemStyle = { display: "flex", alignItems: "baseline" }
const textStyle = { display: "flex", justifyContent: "space-between" }
const buttonStyle = {
  width: "100%",
  color: "#fff",
  background: "#e36f1e",
  border: "solid 1px #e36f1e",
  borderRadius: "3px",
  marginTop: "14px",
}
const View = (props) => {
  const dispatch = useDispatch()

  const getCustomType = (type, fundType) => {
    const transactionType = type.split("_")
    if (transactionType[0] === "reverse") {
      return `Reverse ${getTranslation(
        "menu_mem",
        `${transactionType[1]}_${transactionType[2]}`,
      )}`
    }
    return getTranslation(
      "menu_mem",
      fundType && type !== "create_loan" ? `${type}_${fundType}` : type,
    )
  }

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vslaId)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  const submit = () => {
    if (props.customAction) {
      props.customAction(props.data.requestId)
      return
    }
    if (props.data.cacheId) {
      dispatch(retryAction("pendingTransactions", props.data.cacheId))
      return
    }
    dispatch(
      initiateTransaction(
        {
          ...props.data.request,
          uid: uuid.v4(),
        },
        cachedMeeting[0],
      ),
    )
  }
  return (
    <div>
      <div style={itemStyle}>
        <img
          src={
            props.data.status &&
            props.data.status.toLocaleLowerCase() === "successful" &&
            !props.data.transactionType.includes("REVERSAL")
              ? greenArrow
              : props.data.status &&
                  props.data.status.toLocaleLowerCase() === "pending"
                ? "/images/icons/error.svg"
                : redArrow
          }
          alt="fine"
          style={{ alignSelf: "center", padding: "0 12px" }}
        />
        <div style={{ width: "100%" }}>
          <div style={textStyle}>
            <span>{props.data.transactionPartyName}</span>{" "}
            <Status status={props.data.status} />
          </div>
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 4,
            }}
          >
            <span>
              {`${
                props.data.transactionType &&
                getCustomType(
                  props.data.transactionType.toLocaleLowerCase(),
                  props.data.fund,
                )
              }:  ${
                localStorage.getItem("lang") === "ARABIC"
                  ? intToArabic(props.data.amount)
                  : props.data.amount
              }`}
            </span>
            <span>
              {`${new Date(props.data.createdAt).getHours()}:${new Date(
                props.data.createdAt,
              ).getMinutes()}:${new Date(props.data.createdAt).getSeconds()}
              `}
            </span>
          </span>
        </div>
      </div>
      {props.data.status && props.data.status.toLocaleLowerCase() === "failed" ? (
        <>
          {props.data.description}
          <button onClick={submit} style={buttonStyle}>
            {props.customBtnLabel || "Retry"}
          </button>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default View
