import React from "react"
import "./app.css"
import LoginPage from "../components/login/LoginPage"
import PhoneVerify from "../components/login/PhoneVerify"
import VerifyCode from "../components/login/VerifyCode"
import PinReset from "../components/login/PinReset"
import ValidatePhone from "../components/login/ValidatePhone"
import Header from "../components/layout/Header"
import MainMenu from "../components/layout/MainMenu"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Groups from "../components/groups/Groups"
import SignUpPage from "../components/login/SignUpPage"
import ProtectedRoute from "../components/login/protected.route"
import ReactGA from "react-ga4"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { Alert } from "reactstrap"

import {
  faPeopleCarry,
  faToolbox,
  faHandHoldingUsd,
  faCoins,
  faUser,
  faUsers,
  faSignOutAlt,
  faAngleRight,
  faTimes,
  faExclamationCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { getBalances } from "../store/reducers/transactions/actions"
import {
  getTranslation,
  manifestURLs,
  navigationMap,
  detectNetworkStatus,
  getUserIPData,
} from "./shared/helpers"
import { setCurrentPage } from "../store/reducers/navigation"
import { triggerAction } from "../store/constants"
import { uploadOfflineMeetings } from "../store/actions/meetings"
import { fetchMeeting, meetingLoaded } from "../store/actions/meetings/getMeeting"
import { useLiveQuery } from "dexie-react-hooks"
import { addToCache, db } from "./shared/utilities/cache"

library.add(faPeopleCarry)
library.add(faToolbox)
library.add(faHandHoldingUsd)
library.add(faCoins)
library.add(faUser)
library.add(faSignOutAlt)
library.add(faUsers)
library.add(faAngleRight)
library.add(faTimes)
library.add(faExclamationCircle)
library.add(faArrowLeft)

ReactGA.initialize([
  {
    trackingId: window.REACT_APP_PWA_APP_TRACKING_ID,
    legacyDimensionMetric: false,
    gaOptions: {
      send_page_view: true,
    },
  },
])

const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

let mounted = false

const App = () => {
  const dispatch = useDispatch()
  const {
    currentOngoingMeeting,
    loadedvslas,
    currentPage,
    uploadStatus,
    user,
    vlsasLoadingStarted,
    vsla,
  } = useSelector(
    ({
      vsla,
      loadedvslas,
      loadedvslaMembers,
      pendingTransactions,
      currentPage,
      uploadStatus,
      currentOngoingMeeting,
      user,
      vlsasLoadingStarted,
    }) => ({
      vsla,
      loadedvslas,
      loadedvslaMembers,
      pendingTransactions,
      currentPage,
      uploadStatus,
      currentOngoingMeeting,
      user,
      vlsasLoadingStarted,
    }),
  )

  const [offlineMeeting, setOfflineMeeting] = React.useState(null)
  const [hasConnection, setHasConnection] = React.useState(true)
  const updateStoreNetworkStatus = (param) => setHasConnection(param)

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [vlsasLoadingStarted, currentOngoingMeeting])

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
      error: cachedMeeting[0].error,
    })
  }, [cachedMeeting, hasConnection])

  React.useEffect(() => {
    if (!hasConnection) {
      const el = document.getElementsByTagName("body")[0].style
      el.overscrollBehavior = "contain"
      return
    }
  }, [hasConnection])

  React.useEffect(() => {
    if (mounted && uploadStatus.complete) {
      setSuccessVisible(true)
    } else if (uploadStatus.failedToUpload) {
      setErrorVisible(true)
    }
  }, [uploadStatus])

  const uploadCachedMeeting = () => {
    const isCompleted = offlineMeeting && offlineMeeting.status === "completed"
    const hasError = offlineMeeting && offlineMeeting.error
    if (
      hasConnection &&
      offlineMeeting &&
      isCompleted &&
      !hasError &&
      localStorage.getItem("token")
    ) {
      dispatch(uploadOfflineMeetings(offlineMeeting))
    }
  }

  React.useEffect(() => {
    if (vlsasLoadingStarted || uploadStatus.isUploading) {
      return
    }
    uploadCachedMeeting()
  }, [
    vlsasLoadingStarted,
    offlineMeeting,
    uploadStatus.isUploading,
    uploadCachedMeeting,
  ])

  React.useEffect(() => {
    uploadCachedMeeting()
    ReactGA.send({
      hitType: "pageview",
      page: "/group",
    })
    mounted = true
    detectNetworkStatus(updateStoreNetworkStatus)
    getUserIPData()
    document
      .querySelector("#manifest-placeholder")
      .setAttribute("href", manifestURLs[window.REACT_APP_PWA_APP_ENV])
    if (localStorage.getItem("lang") === "ARABIC") {
      document.querySelector("body").setAttribute("dir", "rtl")
    }
    if (!isAndroid) {
      return
    }
    setupWindowHistoryState()
    return () => {
      if (!isAndroid) {
        return
      }
      window.removeEventListener("popstate", () => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [errorIsVisible, setErrorVisible] = React.useState(false)
  const [successIsVisible, setSuccessVisible] = React.useState(false)
  const onDismiss = () => {
    setSuccessVisible(false)
    setErrorVisible(false)
  }

  const handleBackButton = () => {
    if (!currentPage) {
      return
    }
    window.history.pushState({}, "")
    handleNavigation()
  }
  const setupWindowHistoryState = () => {
    window.addEventListener("popstate", handleBackButton)
  }
  const handleNavigation = () => {
    const metadata = currentPage.split("-")
    const pages = navigationMap[metadata[0]]
    const currentPage = pages[metadata[1]]
    if (!currentPage.backPage) {
      window.history.push("/")
    }
    return dispatch(setCurrentPage(`${metadata[0]}-${currentPage.backPage}`))
  }

  const makeItOffline = async () => {
    if (offlineMeeting) {
      return
    }
    const transactions = currentOngoingMeeting[0].transactions.filter(
      ({ status }) => status.toLowerCase() !== "successful",
    )
    await addToCache("offlineMeeting", {
      data: {
        ...currentOngoingMeeting[0],
        transactions,
      },
      vslaId: vsla.id,
    })
  }
  React.useEffect(() => {
    dispatch(triggerAction("UPDATE_NETWORK_STATUS", hasConnection))
    if (!hasConnection && currentOngoingMeeting.length && !offlineMeeting) {
      makeItOffline()
      dispatch(meetingLoaded([]))
      // return
    }
    uploadCachedMeeting()
  }, [hasConnection, offlineMeeting])

  React.useEffect(() => {
    if (
      !hasConnection &&
      [
        "group-repayLoan",
        "group-writeOffLoan",
        "group-createLoan",
        "group-sellShares",
        "group-addWelfare",
        "group-requestWelfare",
        "group-meetingTransactions",
      ].includes(currentPage)
    ) {
      dispatch(setCurrentPage("group-memberList"))
    }
  }, [hasConnection])

  React.useEffect(() => {
    if (!loadedvslas.length) {
      return
    }
    dispatch(getBalances())
  }, [loadedvslas.length])

  React.useEffect(() => {
    if (!hasConnection || currentOngoingMeeting.length || offlineMeeting) {
      return
    }
    if (
      (localStorage.getItem("token") || (user && user.token)) &&
      loadedvslas.length
    ) {
      const headers = {
        headers: {
          Authorization: "Bearer " + user.token || localStorage.getItem("token"),
        },
      }
      const group_id = loadedvslas[0].id
      const url = `/api/v1/groups/${group_id}/meetings`
      dispatch(fetchMeeting(url, headers))
    }
  }, [hasConnection, vsla])

  return (
    <>
      <Router basename="">
        <Switch>
          <Header />
        </Switch>
        <ToastContainer
          position="top-left"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        {[
          "ARABIC",
          "PORTUGUESE",
          "ENGLISH",
          "LEBANESE",
          "FRANÇAIS",
          "SWAHILI",
          "ZARMA",
          "HAOUSSA",
          "SPANISH",
        ].includes(localStorage.getItem("lang")) ? (
          <ProtectedRoute path="/" exact strict component={LoginPage} />
        ) : (
          <ProtectedRoute path="/" exact strict component={MainMenu} />
        )}
        <Route path="/SignUpPage" exact strict component={SignUpPage} />

        <Route path="/ValidatePhone" exact component={ValidatePhone} />

        <Route path="/PhoneVerify" exact component={PhoneVerify} />

        <Route path="/VerifyCode" exact component={VerifyCode} />

        <Route path="/PinReset" exact component={PinReset} />
        <ProtectedRoute path="/Groups" exact strict component={Groups} />
        <Alert
          isOpen={errorIsVisible}
          toggle={onDismiss}
          style={{
            position: "absolute",
            top: 12,
            width: "90%",
            zIndex: 1,
            margin: "0 5%",
            background: "#e71919",
            color: "#fff",
          }}
          color="success"
        >
          <h4 className="alert-heading">vsla.online</h4>
          <hr />
          <p className="mb-0">{getTranslation("alert", "upload_error")}</p>
        </Alert>
        <Alert
          isOpen={successIsVisible}
          toggle={onDismiss}
          style={{
            position: "absolute",
            top: 12,
            width: "90%",
            zIndex: 1,
            margin: "0 5%",
            background: "#1bc47d",
            color: "#fff",
          }}
          color="success"
        >
          <h4 className="alert-heading">vsla.online</h4>
          <hr />
          <p className="mb-0">{getTranslation("alert", "upload_success")}</p>
        </Alert>
      </Router>
    </>
  )
}

export default App
