export function isUpdatingVsla(state = false, action) {
  switch (action.type) {
    case "IS_UPDATING_VSLA":
      return action.isUpdating
    default:
      return state
  }
}

export function vslaUpdated(state = [], action) {
  switch (action.type) {
    case "UPDATE_VSLA":
      return action.vsla
    default:
      return state
  }
}

export function groupUpdated(state = [], action) {
  switch (action.type) {
    case "UPDATE_GROUP":
      return action.group
    default:
      return state
  }
}

export function groupUpdateError(state = false, action) {
  switch (action.type) {
    case "UPDATE_GROUP_ERROR":
      return action.groupError
    default:
      return state
  }
}

export function updateVslaError(state = "", action) {
  switch (action.type) {
    case "UPDATE_VSLA_ERROR":
      return action.error
    default:
      return state
  }
}
