import { backendAPI, blockchainAPI } from "../api"

export const isUpdatingVsla = (bool) => ({
  type: "IS_UPDATING_VSLA",
  isUpdating: bool,
})

export const updateVsla = (vsla) => ({
  type: "UPDATE_VSLA",
  vsla,
})

export const updateGroup = (group) => ({
  type: "UPDATE_GROUP",
  group,
})

export const updateVslaError = (error) => ({
  type: "UPDATE_VSLA_ERROR",
  error: error,
})

export const updateGroupError = (bool) => ({
  type: "UPDATE_GROUP_ERROR",
  groupError: bool,
})

export function patchVslaItem(url, data) {
  return async function (dispatch) {
    dispatch(isUpdatingVsla(true))
    try {
      const res = await blockchainAPI.patch(url, data)
      dispatch(updateVsla(res.data))
      dispatch(isUpdatingVsla(false))
    } catch (error) {
      return dispatch(updateVslaError(error))
    }
  }
}

export function patchGroupItem(url, data, headers) {
  return async function (dispatch) {
    try {
      const res = await backendAPI.patch(url, data, headers)
      dispatch(updateGroup(res.data))
    } catch (error) {
      dispatch(updateGroupError(true))
      return Promise.reject(error)
    }
  }
}
