export const cycles = (state = { cycles: [], selectedCycle: null }, action) => {
  switch (action.type) {
    case "SET_CYCLES":
      state["cycles"] = action.payload
      return state
    case "SET_SELECTED_CYCLE":
      state["selectedCycle"] = action.payload
      return state
    default:
      return state
  }
}
