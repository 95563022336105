import React from "react"
import { connect } from "react-redux"
import ReactPlayer from "react-player"
import { useState } from "react"
import { getTranslation } from "../../view/shared/helpers"
import { airtableBase } from "./helpers"
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import StellarLoader from "../layout/StellarLoader"

const TABS_LABELS = ["lesson"]

const LessonView = ({ lessonContent, currentUserDetails }) => {
  const [currentViews, setCurrentViews] = useState(0)
  const [playerLoading, setPlayerLoading] = useState(true)
  const [activeTab, setValue] = React.useState("lesson")

  React.useEffect(() => {
    airtableBase("lesson").find(
      lessonContent.lesson_fk_id[0],
      function (err, record) {
        if (err) {
          console.error(err)
          return
        }
        setCurrentViews(record.fields.num_of_views)
      },
    )

    // Cleanup function to cancel the loading state if the component unmounts
    return () => {
      setPlayerLoading(false)
    }
  }, [])

  const updateAirtableData = () => {
    console.error("not yet implemented")
    // Increase views
    // const views = currentViews + 1;
    // airtableBase("viewers").create(
    //   [
    //     {
    //       fields: {
    //         // Add data to the "viewers" table
    //         viewer_id: uuid(),
    //         viewer_name: currentUserDetails.full_name,
    //         viewer_gender: currentUserDetails.gender,
    //         // viewer_coop_id: myCoop.id,
    //         // viewer_coop_name: myCoop.name,
    //         // viewer_coop_location: JSON.stringify(myCoop.location),
    //         viewer_phone_number: currentUserDetails.phone_number,
    //         viewer_hiveonline_id: currentUserDetails.hiveonline_id,
    //         viewer_country: currentUserDetails.country,
    //         viewer_user_id: String(currentUserDetails.id),
    //       },
    //     },
    //   ],
    //   function (err, records) {
    //     if (err) {
    //       console.error(err);
    //       return;
    //     }
    //     const viewerId = records[0].id; // Get the viewer ID
    //     // Add data to the "views" table
    //     airtableBase("views").create(
    //       [
    //         {
    //           fields: {
    //             views_id: uuid(),
    //             viewer_id: [viewerId],
    //             viewed_date: new Date(),
    //             lesson_id: [lessonContent.lesson_fk_id[0]],
    //           },
    //         },
    //       ],
    //       function (err, records) {
    //         if (err) {
    //           console.error(err);
    //           return;
    //         }
    //         // Update data in the "lessons" table
    //         airtableBase("lesson").update(
    //           [
    //             {
    //               id: lessonContent.lesson_fk_id[0],
    //               fields: {
    //                 lesson_id: lessonContent.content[0].lesson_id[0],
    //                 num_of_views: views,
    //                 updated_at: new Date(),
    //               },
    //             },
    //           ],
    //           function (err) {
    //             if (err) {
    //               console.error(err);
    //               return;
    //             }
    //             setCurrentViews(views);
    //             console.log("Updated successfully");
    //           }
    //         );
    //       }
    //     );
    //   }
    // );
  }

  const handleChange = (tab) => {
    if (!TABS_LABELS.includes(tab)) {
      return
    }
    setValue(tab)
  }

  const isVideoOrAudio =
    lessonContent.content[0].contentType === "video" ||
    lessonContent.content[0].contentType === "audio"

  if (isVideoOrAudio) {
    return (
      <div>
        <Nav style={{ display: "flex", justifyContent: "center" }} tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "lesson" })}
              style={{ color: activeTab === "lesson" ? "#E36F1E" : "initial" }}
              onClick={() => handleChange("lesson")}
            >
              {getTranslation("learningContent", "content").toLocaleUpperCase()}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          style={{
            padding: "4px 8px",
            ...(playerLoading ? { display: "none" } : {}),
          }}
        >
          <TabPane tabId="lesson">
            <div
              style={{
                height: "calc(100vh - 180px)",
                overflow: "auto",
              }}
            >
              <div>
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "150%",
                    letterSpacing: "0.15px",
                    color: "rgba(0,0,0,0.87)",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  {lessonContent.lessonName}
                </span>
              </div>
              <div>
                <div>
                  <ReactPlayer
                    allowFullScreen
                    width="100%"
                    height="200px"
                    controls={true}
                    url={
                      lessonContent.content[0].contentType === "video"
                        ? lessonContent.content[0].video
                        : lessonContent.content[0].audio
                    }
                    onStart={() => updateAirtableData()}
                    onReady={() => setPlayerLoading(false)}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        color: "#007678",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "143%",
                        letterSpacing: "0.15px",
                        marginTop: "5px",
                      }}
                    >
                      {`${getTranslation(
                        "learningContent",
                        "views",
                      )}: ${currentViews}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        <div style={playerLoading ? {} : { display: "none" }}>
          <StellarLoader />
        </div>
      </div>
    )
  }
  return <div>{lessonContent.content[0].text}</div>
}

const mapStateToProps = (state) => {
  return {
    lessonContent: state.selectedLearningContent.lessonContent,
    currentUserDetails: state.currentUserDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonView)
