import { createSelector } from "reselect"

export function memberTransactionsAggregLoadingStarted(state = false, action) {
  switch (action.type) {
    case "MEMBER_TRANSACTION_AGGREGATE_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function loadedMemberTransactionAggreg(state = [], action) {
  switch (action.type) {
    case "LOADED_TRANSACTION_AGGREG":
      return action.aggreg
    default:
      return state
  }
}

export function memberTransactionAggregLoadingError(state = [], action) {
  switch (action.type) {
    case "TRANSACTION_AGGREG_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}

export const selectMemberBalanceAggreg = (state) =>
  state.loadedMemberTransactionAggreg

export const selectMemberBalance = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs
      .filter(
        (aggreg) =>
          aggreg.total_amount !== 0.0 &&
          (aggreg.transaction_type_name === "Membership" ||
            aggreg.transaction_type_name === "Fines" ||
            aggreg.transaction_type_name === "Welfare balance" ||
            aggreg.transaction_type_name === "Shares balance" ||
            aggreg.transaction_type_name === "Welfare contributions" ||
            aggreg.transaction_type_name === "Welfare requests" ||
            aggreg.transaction_type_name === "Loan balance"),
      )
      .map((aggreg) => {
        let data = [
          {
            transactionType: aggreg.transaction_type_name,
            aggregAmount: aggreg.total_amount,
          },
        ]
        return data.flat()
      })
  },
)

export const selectMemberShares = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Shares bought",
    )
  },
)

export const selectMemberMembershipAggregate = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter((aggreg) => aggreg.transaction_type_name === "Membership")
  },
)

export const selectMemberFineAggregate = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter((aggreg) => aggreg.transaction_type_name === "Fine paid")
  },
)

export const selectMemberWelfareAggregate = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Welfare contribution",
    )
  },
)

export const selectMemberLoanAggregate = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Loan balance",
    )
  },
)

export const selectMemberServiceChargeAggregate = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Service charge accrued",
    )
  },
)

export const selectMemberWriteOffReverseAggregate = createSelector(
  selectMemberBalanceAggreg,
  (aggregs) => {
    return aggregs.filter(
      (aggreg) => aggreg.transaction_type_name === "Write-off reversed",
    )
  },
)

export const selectMemberTotalLoanAggregate = createSelector(
  selectMemberLoanAggregate,
  selectMemberServiceChargeAggregate,
  (loan, serviceCharge) => {
    if (serviceCharge.length > 0) {
      return {
        loan: loan[0].total_amount + Math.abs(serviceCharge[0].total_amount),
      }
    } else if (loan.length > 0) {
      return {
        loan: loan[0].total_amount,
      }
    } else {
      return {
        loan: 0,
      }
    }
  },
)
