import React, { Component } from "react"
import { FormTitle } from "../members/formComponents/FormTitle"
import OtherIncomeAndExpenses from "./OtherIncomeAndExpenses"
import { connect } from "react-redux"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
class otherExpenses extends Component {
  state = {
    vsla_id: "",
  }
  componentDidMount() {
    const { vsla } = this.props.vslaSummary[0]
    this.setState({ vsla_id: vsla.id })
  }
  render() {
    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "1.8em",
      textAlign: "center",
      paddingBottom: "0.5em",
    }

    return (
      <div>
        <div>
          <h4 style={headerStyle}>{this.props.currentMember.full_name}</h4>
        </div>
        <FormTitle style={headerStyle} title="title_copy.otherExpenses" />
        <OtherIncomeAndExpenses
          vsla_id={this.state.vsla_id}
          amount={this.props.amount}
          history={this.props.history}
          sourceFundType={this.props.destination}
          destination="issuer"
          otherType="Group expenses"
          inputName="sourceFundType"
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    vslaSummary: selectCreatedVslaInfo(state),
    currentMember: state.loadedMember,
  }
}
export default connect(mapStateToProps, null)(otherExpenses)
