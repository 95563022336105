import React from "react"
import { ListGroup, ListGroupItem, Container } from "reactstrap"
import meetingLogo from "../../view/assets/meeting.png"
import groupLogo from "../../view/assets/group.png"
import schoolLogo from "../../view/assets/school.png"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { connect } from "react-redux"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import { setCurrentPage } from "../../store/reducers/navigation"

counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const itemStyle = {
  display: "flex",
  alignItems: "center",
}
const iconStyle = {
  float: "left",
  width: 70,
}

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
}

const subheaderStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  textAlign: "left",
  marginLeft: "2.4em",
  lineHeight: "1.5em",
}

const VslaMenu = (props) => {
  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"))
  }, [])

  return (
    <div>
      <Translate content="menu.vlsa" component="h4" style={headerStyle} />
      <Container>
        <ListGroup
          flush
          style={{
            float: localStorage.getItem("lang") === "ARABIC" ? "right" : "none",
          }}
        >
          <ListGroupItem>
            <span
              name="group-details-button"
              onClick={() => props.setPage("group-details")}
              style={itemStyle}
            >
              <img style={iconStyle} src={groupLogo} alt="Stellar" />
              <Translate
                content="menu.group"
                className="textStyle"
                style={subheaderStyle}
              />
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span
              onClick={() => props.setPage("group-meetingsMenu")}
              style={itemStyle}
            >
              <img style={iconStyle} src={meetingLogo} alt="Stellar" />
              <Translate
                content="menu.meeting"
                className="textStyle"
                style={subheaderStyle}
              />
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span
              onClick={() => props.setPage("group-learningPage")}
              style={itemStyle}
            >
              <img style={iconStyle} src={schoolLogo} alt="Stellar" />
              <Translate
                content="learningContent.buttonTitle"
                className="textStyle"
                style={subheaderStyle}
              />
            </span>
          </ListGroupItem>
        </ListGroup>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    vslas: state.loadedvslas,
    vslaSummary: selectCreatedVslaInfo(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setPage: (page) => dispatch(setCurrentPage(page)) }
}

export default connect(mapStateToProps, mapDispatchToProps)(VslaMenu)
