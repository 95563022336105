import React from "react"
import { FormTitle } from "../members/formComponents/FormTitle"
import { Container, Alert } from "reactstrap"
import Translate from "react-translate-component"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { connect, useSelector } from "react-redux"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import CashOutErrorList from "./CashoutErrorList"
import StellarLoader from "../layout/StellarLoader"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.2rem",
  textAlign: "center",
  paddingBottom: "0.5em",
}

function CashOutError(props) {
  counterpart.setLocale(localStorage.getItem("lang"))
  const { isLoading } = useSelector((state) => ({
    isLoading: state.vlsaMembersLoadingStarted,
  }))

  if (isLoading) {
    return <StellarLoader />
  }

  return (
    <Container>
      <FormTitle style={headerStyle} title="title_copy.end_of_cycle_cash_out" />
      <Alert color="danger">
        <Translate content="alert.failed" component="h4" />
        <span>
          <Translate
            content="alert.repay_loans_message"
            style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
          />
        </span>
      </Alert>
      <CashOutErrorList />
    </Container>
  )
}

export default connect(null, null)(CashOutError)
