export function membersWithLoans(state = {}, action) {
  switch (action.type) {
    case "SET_MEMBERS_WITH_LOANS":
      return {
        ...state,
        members: action.membersWithLoans,
      }
    default:
      return state
  }
}

export function loadingCashoutData(state = {}, action) {
  switch (action.type) {
    case "lOADING_CASHOUT_DATA":
      return {
        ...state,
        loadingCashoutData: action.loadingCashoutData,
      }
    default:
      return state
  }
}

export function membersTocashout(state = [], action) {
  switch (action.type) {
    case "CASHOUT_VSLA_MEMBERS":
      return action.membersTocashout
    case "DELETE_CASHOUT_VSLA_MEMBERS":
      return state.filter((member) => member.blockchain_wallet_id !== action.id)
    default:
      return state
  }
}

export function cashoutStarted(state = false, action) {
  switch (action.type) {
    case "CASHOUT_STARTED":
      return action.state
    default:
      return state
  }
}
