import React from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}
const formStyle = {
  flex: "10",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "17px",
  height: "50px",
}

const reducer = (state, action) => {
  const clone = [...state]
  switch (action.type) {
    case "add":
      return [...clone, action.item]
    case "remove":
      return clone.filter((i) => i !== action.item)
    case "clear":
      return []
    default:
      return state
  }
}
const MultipleSelect = (props) => {
  const [state, dispatch] = React.useReducer(reducer, props.preSetItems || [])
  const [displayItems, setDisplayItems] = React.useState(
    props.preSetItems.join(", "),
  )

  React.useEffect(() => {
    setDisplayItems(state.join(", "))
    props.callback(state)
  }, [state])

  const onChange = ({ target }) => {
    if (state.includes(target.value)) {
      dispatch({ type: "remove", item: target.value })
      return
    }
    dispatch({ type: "add", item: target.value })
  }

  const clear = () => {
    dispatch({ type: "clear" })
  }
  return (
    <FormGroup>
      <Label
        style={{
          ...loadingStyle,
          textAlign: localStorage.getItem("lang") === "ARABIC" ? "right" : "initial",
          width: localStorage.getItem("lang") === "ARABIC" ? "100%" : "initial",
        }}
      >
        {props.title}
      </Label>
      <span style={{ display: "flex" }}>
        <Input
          id="nationality"
          type="text"
          placeholder={props.placeholderOption}
          name="nationality"
          style={{ ...formStyle, borderRight: "none" }}
          value={displayItems}
        ></Input>
        <FontAwesomeIcon
          onClick={clear}
          style={{
            cursor: "pointer",
            fontSize: 12,
            margin: "auto 10px",
            zIndex: 1,
          }}
          icon="times"
        />
      </span>

      <Input
        id="multiple-select"
        type="select"
        name="multiple-select"
        style={{
          ...formStyle,
          marginTop: -76,
          color: "transparent",
          background: "transparent",
        }}
        onChange={onChange}
        value={displayItems}
      >
        <option disabled value="">
          {props.placeholderOption}
        </option>
        {props.options.map((op, idx) => (
          <option
            style={{ color: "#000" }}
            key={`${idx}-${op.value}`}
            value={op.value}
          >
            {op.title}
          </option>
        ))}
      </Input>
    </FormGroup>
  )
}

export default MultipleSelect
