import React from "react"
import { Container, Alert } from "reactstrap"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"

import { connect } from "react-redux"
import Translate from "react-translate-component"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { setCurrentPage } from "../../store/reducers/navigation"
import Transaction from "./ActiveMeetingTransactions/Transaction"
import { fullArabicDateConverter } from "../../view/shared/utilities/arabicDateConverter"
import { useLiveQuery } from "dexie-react-hooks"
import { db, updateCacheItem } from "../../view/shared/utilities/cache"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const dateStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "25px",
  padding: ".5rem",
  textAlign: "center",
  margin: "34px 0 0 0",
}

const OfflineMeetingTransactions = (props) => {
  const [offlineMeeting, setOfflineMeeting] = React.useState(null)

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    })
  }, [cachedMeeting])

  const removeTransaction = (id) => {
    const transactions = offlineMeeting.transactions.filter(
      ({ requestId }) => requestId !== id,
    )
    updateCacheItem(
      "offlineMeeting",
      { data: { ...offlineMeeting, transactions } },
      offlineMeeting.cacheId,
    )
  }

  if (offlineMeeting && !(offlineMeeting.transactions || []).length) {
    return (
      <div>
        <div style={dateStyle}>
          <span>
            {!["ARABIC"].includes(localStorage.getItem("lang")) &&
              new Date(
                offlineMeeting.date,
                // update the translation to use helper's module function
              ).toLocaleString(counterpart.translate("locale.date"), {
                dateStyle: "full",
              })}
            {["ARABIC"].includes(localStorage.getItem("lang")) &&
              fullArabicDateConverter(offlineMeeting.date)}
          </span>
        </div>
        <br />
        <Container>
          <Alert color="warning">
            <span>
              <Translate
                content="notifications.no_transactions"
                style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
              />
            </span>
          </Alert>
        </Container>
      </div>
    )
  }

  return (
    <div>
      {offlineMeeting && (
        <>
          <div style={dateStyle}>
            <span>
              {!["ARABIC"].includes(localStorage.getItem("lang")) &&
                new Date(offlineMeeting.date).toLocaleString(
                  // update the translation to use helper's module function
                  counterpart.translate("locale.date"),
                  { dateStyle: "full" },
                )}
              {["ARABIC"].includes(localStorage.getItem("lang")) &&
                fullArabicDateConverter(offlineMeeting.date)}
            </span>
          </div>
          <br />
          {offlineMeeting.error && (
            <span
              style={{ color: "red", display: "flex", justifyContent: "center" }}
            >
              {offlineMeeting.error}
            </span>
          )}
          <Container style={{ paddingBottom: "30px" }}>
            {(offlineMeeting.transactions || []).map((item) => (
              <>
                <Transaction
                  data={item}
                  vslaId={props.vsla.id}
                  key={item.requestId}
                  customAction={removeTransaction}
                  customBtnLabel="Remove"
                />
                <hr />
              </>
            ))}
          </Container>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loadedVsla: state.loadedvslas,
    vsla: state.vsla,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OfflineMeetingTransactions)
