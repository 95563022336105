import { fetchPreviousMeetingsData } from "."
import { setCurrentPage } from "../../reducers/navigation"
import { meetingTransactionsLoaded } from "../getTransactions"
import { fetchMeeting } from "./getMeeting"
import { backendAPI } from "../../api"
import { fetchVslas } from "../../actions/getVslas"

export const isCreatingMeeting = (bool) => ({
  type: "IS_CREATING_MEETING",
  isCreating: bool,
})

export const createMeeting = (meeting) => ({
  type: "CREATE_MEETING",
  meeting,
})

export const updateMeeting = (updatedMeeting) => ({
  type: "UPDATE_MEETING",
  updatedMeeting,
})

export const createMeetingError = (error) => ({
  type: "CREATE_MEETING_ERROR",
  error: error,
})

export function postCreateMeeting(url, data, headers) {
  return async function (dispatch, getState) {
    dispatch(isCreatingMeeting(true))
    try {
      const { vsla } = getState()
      const numAccounts = localStorage.getItem("acts")
      if (numAccounts && numAccounts !== vsla.accounts.length) {
        dispatch(fetchVslas())
      }
      const res = await backendAPI.post(url, data, headers)
      dispatch(createMeeting(res.data))
      await dispatch(fetchMeeting(url, headers))
      dispatch(isCreatingMeeting(false))
      return Promise.resolve(res.data)
    } catch (error) {
      dispatch(isCreatingMeeting(false))
      dispatch(createMeetingError(error))
      return Promise.reject()
    }
  }
}

export function patchMeeting(url, data, headers) {
  return async function (dispatch, getState) {
    dispatch(isCreatingMeeting(true))
    try {
      if (data.status === "completed") {
        dispatch(meetingTransactionsLoaded([]))
      }
      const res = await backendAPI.patch(url, data, headers)
      dispatch(updateMeeting(res.data))
      dispatch(setCurrentPage("group-meetingsMenu"))
      dispatch(isCreatingMeeting(false))
      dispatch(fetchPreviousMeetingsData())
    } catch (error) {
      dispatch(isCreatingMeeting(false))
      return dispatch(createMeetingError(error))
    }
  }
}
