import React, { useState } from "react"
import { Container } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import MemberList from "./MemberList"
import { connect } from "react-redux"
import { setCurrentPage, setPreviousPage } from "../../store/reducers/navigation"
import { getTranslation } from "../../view/shared/helpers"
import {
  canCashout,
  cashoutStarted,
  getCashoutDetails,
  membersTocashout,
} from "../../store/reducers/cashout/actions"
import CustomModal from "../layout/Modal"
import { toast } from "react-toastify"
import { ErrorMessage } from "../meetings/MeetingsMenu"
import { patchMeeting } from "../../store/actions/meetings/createMeeting"
import { fetchMeeting, meetingLoaded } from "../../store/actions/meetings/getMeeting"
import { fetchVslas } from "../../store/actions/getVslas"
import { db, updateCacheItem } from "../../view/shared/utilities/cache"
import { useLiveQuery } from "dexie-react-hooks"

const iconStyle = {
  fontSize: "20px",
  float: "right",
  color: "#02294A",
  transform: localStorage.getItem("lang") === "ARABIC" ? "rotateY(180deg)" : "none",
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  userSelect: "none",
}

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontSize: "1.8em",
  marginBottom: "0rem",
  fontWeight: "bolder",
  padding: ".5rem",
  textAlign: "center",
  margin: "34px 0 0 0",
}

const BackbuttonStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 12px",
  width: "62px",
  height: "36px",
  color: "#fff",
  fontFamily: "Quicksand",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: "#6C757D",
  borderRadius: "4px",
  flex: "none",
  border: "0",
  alignSelf: "stretch",
  flexGrow: "0",
  textTransform: "capitalize",
  marginRight: "0.5em",
}

const ContbuttonStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 12px",
  width: "93px",
  height: "36px",
  color: "#fff",
  fontFamily: "Quicksand",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: "#E36F1E",
  borderRadius: "4px",
  flex: "none",
  border: "0",
  alignSelf: "stretch",
  flexGrow: "0",
  textTransform: "capitalize",
  marginRight: "0.5em",
}
export const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.meeting_end_success"
      component="div"
      style={loadingStyle}
    />
  )
}

const MemberMenu = (props) => {
  const notify = (msg) => toast.info(msg)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const performCashOut = async () => {
    const hasLoan = await props.canCashout()
    if (typeof hasLoan === "undefined") {
      if (props.membersTocashout.length) {
        props.setPage("group-endOfCycleCashout")
      } else {
        const members = props.members.filter(
          (member) =>
            member.status === "Active" && member.blockchain_wallet_id !== null,
        )
        props.setMembersTocashout(members)
        props.cashoutStarted(true)
        props.setPage("group-endOfCycleCashout")
      }
    } else {
      props.setPage("group-cashOutError")
    }
  }

  const [offlineMeeting, setOfflineMeeting] = React.useState(null)
  const [pendingMembers, setPendingMembers] = React.useState([])
  const [modalInputData, setInputData] = useState()

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  const cachedMembers = useLiveQuery(async () => {
    try {
      const items = await db.members.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  React.useEffect(() => {
    setPendingMembers([
      ...(cachedMembers || [])
        .filter((item) => item && item.data)
        .map(({ cacheId, data, error }) => ({ ...data, error, cacheId })),
    ])
  }, [cachedMembers])

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    })
  }, [cachedMeeting])

  const endMeeting = () => {
    const lang_code = localStorage.getItem("lang")

    const currentMeeting = offlineMeeting || props.currentOngoingMeeting[0]
    const temp = currentMeeting
    temp.status = "completed"
    const headers = {
      headers: { Authorization: "Bearer " + props.user.token },
    }

    const group_name = props.group[0].name

    const notif = {
      ENGLISH: {
        message: `The secretary has ended a meeting for ${group_name} group on VSLA Online`,
      },
      FRANÇAIS: {
        message: `FRA - The secretary has ended a meeting for ${group_name} group on VSLA Online`,
      },
      SWAHILI: {
        message: `SWA - The secretary has ended a meeting for ${group_name} group on VSLA Online`,
      },
      SPANISH: {
        message: `La secretaria ha terminado una reunión para el grupo ${group_name} en VSLA Online`,
      },
      LEBANESE: {
        message: `The officer has ended a session for ${group_name} community on VSLA.online`,
      },
      ARABIC: {
        message: group_name,
      },
      PORTUGUESE: {
        message: `O/A secretário/a iniciou uma reunião para o grupo ${group_name} no VSLA Online`,
      },
    }

    temp.msg = Object.values(notif[lang_code])[0]
    const url = `/api/v1/meetings/${temp.id}`
    if (offlineMeeting) {
      updateCacheItem(
        "offlineMeeting",
        {
          data: {
            ...offlineMeeting,
            status: "completed",
          },
        },
        offlineMeeting.cacheId,
      )
      setLoading(false)
      notify(<SuccessMessage />)
      props.clearMeetingLoaded()
      return
    }
    props
      .patchOngoingMeeting(url, temp, headers)
      .then((res) => {
        setLoading(false)
        notify(<SuccessMessage />)
        const group_id = props.group[0].id
        const url = `/api/v1/groups/${group_id}/meetings`
        props.fetchOngoingMeeting(url, headers)
      })
      .catch((error) => {
        notify(<ErrorMessage />)
      })
  }

  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"))
  }, [])

  React.useEffect(() => {
    if (
      props.currentOngoingMeeting.length &&
      props.previousPage === "group-endOfCycleCashout" &&
      !props.cashoutActive
    ) {
      props.fetchVslas()
      const cashoutCompleted = props.group[0].can_change_share_value

      if (cashoutCompleted) {
        props.setPreviousPage(null)
        setLoading(true)
        endMeeting()
        props.setPage("group-meetingsMenu")
        return
      }
    }
  }, [props.group[0].can_change_share_value])

  const addMembers = (e) => {
    // Check if any of the conditions for preventing the action are met
    const secretary = props.members.find(
      ({ member_role, blockchain_wallet_id }) =>
        ["Secretário/a", "Secretary"].includes(member_role) &&
        blockchain_wallet_id !== null,
    )
    if (
      // Condition 1: No members and pending members exist
      (!props.members.length && pendingMembers.length) ||
      /* Condition 2: Secretary created on backend  
        but creation not completed on blockchain*/
      (props.members.length && !secretary) ||
      // Condition 3: Not connected
      !props.isConnected
    ) {
      e.preventDefault()
      return
    }
    props.setPage("group-addMember")
  }

  return (
    <div>
      <div>
        <Translate
          content="title_copy.mem_menu"
          component="h4"
          style={headerStyle}
        />
      </div>
      {props.vsla.metadata &&
        props.vsla.metadata.status === "Active" &&
        props.group.length > 0 &&
        props.group[0].status === "Active" && (
          <Container>
            <span>
              <span onClick={addMembers}>
                <span
                  style={
                    localStorage.getItem("lang") === "ARABIC"
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                        }
                      : {}
                  }
                >
                  <Translate
                    content="menu.add_member"
                    className="textStyle"
                    style={{
                      ...loadingStyle,
                      color: props.isConnected ? "initial" : "grey",
                    }}
                  />
                  <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                </span>
              </span>
            </span>
            <hr style={{ borderTop: "1px solid #E36F1E" }} />
            <span>
              {props.cashoutData &&
                props.cashoutData.hasBalances &&
                props.cashoutData.hasMembers &&
                props.currentOngoingMeeting.length > 0 &&
                !["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
                  <>
                    <span
                      onClick={() => {
                        if (pendingMembers.length) {
                          alert(getTranslation("alert", "cannot_cash_out"))
                          return
                        } else setIsOpen(true)
                      }}
                    >
                      <span
                        style={
                          localStorage.getItem("lang") === "ARABIC"
                            ? {
                                display: "flex",
                                justifyContent: "space-between",
                              }
                            : {}
                        }
                      >
                        <Translate
                          content="menu.end_of_cycle_cash_out"
                          className="textStyle"
                          style={{
                            ...loadingStyle,
                            color: props.isConnected ? "initial" : "grey",
                          }}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </span>
                    </span>
                    <hr style={{ borderTop: "1px solid #E36F1E" }} />
                  </>
                )}
              <CustomModal
                modalHeader={getTranslation("title_copy", "warning")}
                headerClassName="d-flex justify-content-center"
                bodyContent={
                  <div>
                    {getTranslation(
                      "notifications",
                      "end_of_cycle_cash_out_warning",
                    )}
                    <br />
                    <br />
                    {getTranslation("notifications", "proceed_to_cashout_msg")}
                  </div>
                }
                isCentered={true}
                showInput={true}
                errors={{}}
                showSavingsCycle={false}
                isCashoutModal={true}
                setInputData={setInputData}
                isOpen={isOpen}
                footerContent={
                  <div className="btn-toolbar">
                    <button
                      className="btn"
                      onClick={() => {
                        setIsOpen(false)
                      }}
                      style={BackbuttonStyle}
                    >
                      {getTranslation("span", "back")}
                    </button>
                    <button
                      className="btn btn-info"
                      disabled={
                        !(
                          modalInputData ===
                          getTranslation("notifications", "cashout")
                        )
                      }
                      onClick={() => performCashOut()}
                      style={ContbuttonStyle}
                    >
                      {getTranslation("span", "continue")}
                    </button>
                  </div>
                }
              />
            </span>
            {loading && (
              <span
                className="dots-loader"
                style={{
                  color: "#E36F1E",
                  fontSize: 12,
                  margin: "auto",
                }}
              >
                {getTranslation("span", "processing")}
                <span style={{ position: "absolute" }}></span>
              </span>
            )}
          </Container>
        )}
      <MemberList id={props.group[0].id} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    vsla: state.vsla,
    group: state.loadedvslas,
    members: state.loadedvslaMembers,
    cashoutData: state.loadingCashoutData.loadingCashoutData,
    membersTocashout: state.membersTocashout,
    currentOngoingMeeting: state.currentOngoingMeeting,
    loadedvslaMembers: state.loadedvslaMembers,
    user: state.user,
    previousPage: state.previousPage,
    cashoutActive: state.cashoutStarted,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setCurrentPage(page)),
  cashoutStarted: (state) => dispatch(cashoutStarted(state)),
  getCashoutDetails: () => dispatch(getCashoutDetails()),
  setMembersTocashout: (members) => dispatch(membersTocashout(members)),
  canCashout: () => dispatch(canCashout()),
  clearMeetingLoaded: () => dispatch(meetingLoaded([])),
  patchOngoingMeeting: (url, data, headers) =>
    dispatch(patchMeeting(url, data, headers)),
  fetchOngoingMeeting: (url, headers) => dispatch(fetchMeeting(url, headers)),
  fetchVslas: () =>
    dispatch(
      fetchVslas(localStorage.getItem("token"), localStorage.getItem("tokenBC")),
    ),
  setPreviousPage: (page) => dispatch(setPreviousPage(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MemberMenu)
