import React from "react"
import { Form, Input, FormGroup } from "reactstrap"
import validateInput from "../../view/shared/validations/login"
import axios from "axios"
import oauth from "axios-oauth-client"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import "react-phone-number-input/style.css"
import "react-responsive-ui/style.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import ar from "react-phone-input-2/lang/ar.json"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { connect } from "react-redux"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import { logoutAction, trackActivity } from "../../store/actions/auth"
import { getTranslation } from "../../view/shared/helpers"
import { useCookies } from "react-cookie"
import { triggerAction } from "../../store/constants"
import { arabicToInt } from "../../view/shared/utilities/arabicNumParser"
import { fetchVslas } from "../../store/actions/getVslas"
import { setAuthorizationToken } from "../../store/api"
import { v4 as uuid } from "uuid"
import { setCurrentPage } from "../../store/reducers/navigation"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
}

const formStyle = {
  flex: "10",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  height: "50px",
  width: "100%",
}

const LoginForm = (props) => {
  // eslint-disable-next-line
  const [_, setCookie] = useCookies(["token"])
  const [state, setState] = React.useState({
    phone_number: "",
    password: "",
    errors: {},
    bounce: "",
    isLoaded: false,
    lang: localStorage.getItem("lang"),
    buttonDisable: false,
  })
  const [bounce, setBounce] = React.useState("")
  const [_country] = React.useState(localStorage.getItem("_country") || "GH")

  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"))
  }, [state.errors, state.bounce, bounce])

  const onChange = (e) => {
    const { name, value } = e.target
    if (name === "password" && isNaN(value)) {
      return
    }
    setState({ ...state, buttonDisable: false })
    setState({ ...state, [name]: value })
  }

  const isValid = () => {
    const { errors, isValid } = validateInput(state)

    if (!isValid) {
      setState({ ...state, errors })
      return isValid
    }

    return isValid
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (isValid()) {
      setState({ ...state, buttonDisable: true, bounce: "" })
      try {
        await loginAction(state.phone_number, arabicToInt(state.password))
      } catch (error) {
        console.log("Error ", error)
      }
      setState({ phone_number: "", password: "" })
    }
  }

  const loginAction = async (phone_number, password) => {
    const modifiedEmail = "+" + phone_number + "@hive.com"

    try {
      const getOwnerCredentials = oauth.client(axios.create(), {
        url: `${
          window.REACT_APP_PWA_AUTH ||
          "https://auth-manager.staging.hivenetwork.online"
        }/api/v1/login?app=vsla`,
        grant_type: "password",
        username: modifiedEmail,
        password: password,
      })

      const auth = await getOwnerCredentials()
      props.setTokens(auth.access_token, auth.bc_access_token)
      setAuthorizationToken(auth.access_token, auth.bc_access_token)
      localStorage.setItem("token", auth.access_token)
      localStorage.setItem("tokenBC", auth.bc_access_token)
      localStorage.setItem("email", phone_number)

      const user = auth.user
      if (!user) {
        return
      }
      const obj = {
        ...user,
        hashed_resetcode: null,
        token: auth.access_token,
        tokenBC: auth.bc_access_token,
      }
      props.setUser(obj)
      await props.getVslaData(auth.access_token, auth.bc_access_token)
      const getExpireDate = (days) => {
        const result = new Date()
        result.setDate(result.getDate() + days)
        return result
      }
      setCookie("token", auth.access_token, {
        path: "/",
        expires: getExpireDate(30),
      })
      setCookie("tokenBC", auth.bc_access_token, {
        path: "/",
        expires: getExpireDate(30),
      })
      const session_id = uuid()
      sessionStorage.setItem("ta_id", session_id)
      props.trackActivity({
        session_id,
        logged_in: true,
        user_id: user.id,
        person_name: user.full_name,
        login_time: new Date().toISOString(),
      })
      props.setPage("group-main")
    } catch (error) {
      console.log("Error ", error)
      let errorMsg =
        error && error.response && error.response.data
          ? error.response.data.message
          : "An error occurred, try again"
      errorMsg =
        errorMsg === "Incorrect username or password"
          ? getTranslation("notifications", "incorrect_username_password_msg")
          : errorMsg
      setState({
        ...state,
        bounce: errorMsg,
        buttonDisable: false,
      })
      setBounce(errorMsg)
      props.logout()
    }
  }

  return (
    <div>
      <div>
        {["uat", "staging"].includes(
          window.REACT_APP_PWA_APP_ENV.toLocaleLowerCase(),
        ) && (
          <p style={{ textAlign: "center", fontWeight: "700", padding: 12 }}>
            {getTranslation("title_copy", "version_msg")}
          </p>
        )}
        <Translate
          content="title_copy.header_login"
          component="h4"
          style={headerStyle}
        />
      </div>
      {bounce && <div className="alert alert-danger">{bounce}</div>}
      <Form onSubmit={onSubmit}>
        <FormGroup
          style={{
            direction: "ltr",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {state.errors && (
            <span name="phone_validation" style={{ color: "red" }}>
              {state.errors.phone_number}
            </span>
          )}
          <PhoneInput
            name="phone_number"
            country={_country.toLocaleLowerCase()}
            enableSearch
            disableSearchIcon
            placeholder={getTranslation("placeholder_copy", "login_email")}
            error={state.errors ? state.errors.phone_number : ""}
            inputStyle={{
              flex: "10",
              fontFamily: "Fira Sans",
              height: "50px",
              width: "100%",
              fontSize: "20px",
            }}
            style={{ ...loadingStyle, alignSelf: "end" }}
            value={state.phone_number}
            onChange={(phone_number) => setState({ ...state, phone_number })}
            limitMaxLength={true}
            localization={localStorage.getItem("lang") === "ARABIC" ? ar : false}
          />
        </FormGroup>

        <FormGroup>
          {state.errors && (
            <span name="pin_validation" style={{ color: "red" }}>
              {state.errors.password}
            </span>
          )}
          <Input
            type="password"
            name="password"
            style={formStyle}
            placeholder={getTranslation("placeholder_copy", "login_pin")}
            onChange={onChange}
            error={state.errors ? state.errors.password : ""}
            value={state.password}
          />
        </FormGroup>
        <FormGroup>
          <button
            value={
              state.buttonDisable
                ? getTranslation("placeholder_copy", "login_submitting")
                : getTranslation("placeholder_copy", "login_submit")
            }
            name="submit"
            className="btn btn-primary w-100"
            bsSize="lg"
            disabled={state.buttonDisable}
          >
            {state.buttonDisable
              ? getTranslation("placeholder_copy", "login_submitting")
              : getTranslation("placeholder_copy", "login_submit")}
          </button>
        </FormGroup>
      </Form>
    </div>
  )
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const mapDispatchToProps = (dispatch, state) => {
  return {
    trackActivity: (data) => dispatch(trackActivity(data)),
    logout: () => dispatch(logoutAction()),
    setPage: (page) => dispatch(setCurrentPage(page)),
    getVslaData: (token1, token2) => dispatch(fetchVslas(token1, token2)),
    setUser: (data) => dispatch(triggerAction("SET_USER_DATA", data)),
    setTokens: (token, tokenBC) =>
      dispatch(triggerAction("SET_USER_DATA", { ...state.user, token, tokenBC })),
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isloading: state.vlsasLoadingStarted,
    groupWithEstablishmentDate: state.group,
    vslas: state.loadedvslas,
    error: state.vlsasLoadingError,
    members: state.loadedvslaMembers.filter(
      (member) => member.status === "Active" && member.blockchain_wallet_id !== null,
    ),
    vslaSummary: selectCreatedVslaInfo(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
