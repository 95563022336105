import { transactionAPI } from "../api"

export const memberTransactionAggregLoadingStarted = (bool) => ({
  type: "MEMBER_TRANSACTION_AGGREGATE_LOAD_START",
  isloading: bool,
})

export const transactionsAggregLoaded = (aggreg) => ({
  type: "LOADED_TRANSACTION_AGGREG",
  aggreg,
})

export const transactionsAggregLoadError = (error) => ({
  type: "TRANSACTION_AGGREG_LOAD_ERROR",
  error: error,
})

export function fetchTransactionsAggreg(headers) {
  return async function (dispatch, getState) {
    dispatch(memberTransactionAggregLoadingStarted(true))
    const member = getState().loadedMember
    try {
      const pubkey = member.pubkey
      const id = getState().loadedvslas[0].blockchain_key
      const res = await transactionAPI.get(`/${id}/members/${pubkey}/balances`)
      dispatch(transactionsAggregLoaded(res.data))
      dispatch(memberTransactionAggregLoadingStarted(false))
      return Promise.resolve()
    } catch (error) {
      dispatch(transactionsAggregLoadError(error))
      dispatch(memberTransactionAggregLoadingStarted(false))
      return Promise.reject()
    }
  }
}
