import React from "react"
import { useSelector } from "react-redux"
import { Container } from "reactstrap"
import MemberItem from "../members/MemberItem"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { connect } from "react-redux"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import StellarLoader from "../layout/StellarLoader"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

function CashoutErrorList(props) {
  counterpart.setLocale(localStorage.getItem("lang"))
  const { isLoading } = useSelector((state) => ({
    isLoading: state.vlsaMembersLoadingStarted,
  }))

  if (isLoading) {
    return <StellarLoader />
  }

  return (
    <div>
      <Container style={{ marginBottom: "140px" }}>
        <ol
          key={props.membersWithLoans.members.length}
          style={{ lineHeight: "0.3" }}
        >
          {props.membersWithLoans.members.map((member) => {
            return (
              member && (
                <MemberItem
                  members={props.membersWithLoans.members}
                  key={member.name}
                  member_details={member}
                  vsla_id={props.id}
                />
              )
            )
          })}
        </ol>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  membersWithLoans: state.membersWithLoans,
  id: state.loadedvslas[0].id,
})
export default connect(mapStateToProps, null)(CashoutErrorList)
