import React, { Component } from "react"
import { Form, FormGroup, Input, Alert, Label } from "reactstrap"
import validateInput from "../../view/shared/validations/signUp"
import counterpart from "counterpart"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import Translate from "react-translate-component"
import { postCreateUser } from "../../store/actions/signUp"
import { loadingToggleState } from "../../store/actions/signUp"
import { connect } from "react-redux"
import { createBrowserHistory } from "history"
import { toast } from "react-toastify"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import moment from "moment"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import ar from "react-phone-input-2/lang/ar.json"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import MultipleSelect from "../layout/MulitpleSelect"

import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { defaultDate } from "../../view/shared/utilities"
import { getTranslation } from "../../view/shared/helpers"
import { arabicToInt } from "../../view/shared/utilities/arabicNumParser"
import StellarLoader from "../layout/StellarLoader"
import { AuthAPI } from "../../store/api"
import { v4 as uuid } from "uuid"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const history = createBrowserHistory({ forceRefresh: true })

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.signup_success"
      component="div"
      style={loadingStyle}
    />
  )
}

const ErrorMessage = () => {
  return (
    <Translate content="notifications.error" component="div" style={loadingStyle} />
  )
}

export class SignUpForm extends Component {
  notify = (msg) => toast.info(msg)
  state = {
    full_name: "",
    phone_number: "",
    date_of_birth: defaultDate(),
    pin: "",
    confirm_pin: "",
    errors: {},
    isLoaded: false,
    bounce: "",
    gender: "",
    nationalities: [],
    lang: localStorage.getItem("lang"),
    buttonDisable: false,
    loading: true,
    _country: localStorage.getItem("_country") || "GH",
  }

  onChange = (e) => {
    this.setState({ buttonDisable: false })
    this.setState({ [e.target.name]: e.target.value })
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if (!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  onSubmit = (e) => {
    e.preventDefault()
    if (this.isValid()) {
      this.props.showLoadingState(true)
      this.setState({ buttonDisable: true })
      this.setState({ errors: {} })
      this.signUpAction(
        this.state.phone_number,
        this.state.gender,
        this.state.nationalities,
        arabicToInt(this.state.pin),
        this.state.full_name,
        this.state.date_of_birth,
      )
        .then((res) => {
          this.props.showLoadingState(false)
          this.notify(<SuccessMessage />)
          if (window.REACT_APP_PWA_APP_ENV === "production") {
            setTimeout(() => {
              history.push({
                pathname: `/ValidatePhone`,
              })
            }, 500)
          } else {
            setTimeout(() => {
              history.push({
                pathname: `/`,
              })
            }, 500)
          }
        })
        .catch((error) => {
          this.props.showLoadingState(false)
          this.setState({
            buttonDisable: false,
          })
          const { response } = error
          if (!response || !response.data) {
            return
          }
          this.setState({
            bounce: error.response.data.detail,
          })
          this.notify(<ErrorMessage />)
        })
    }
  }

  async signUpAction(
    phone_number,
    gender,
    nationalities,
    pin,
    full_name,
    date_of_birth,
  ) {
    const email = "+" + phone_number + "@hive.com"
    const finalDate = date_of_birth === null ? new Date() : new Date(date_of_birth)
    const dob = moment(finalDate).format("YYYY-MM-DD")
    const url = `/api/v1/users?app=vsla&requestId=${uuid()}`

    const data = {
      full_name,
      gender,
      nationalities,
      email,
      password: pin,
      phone_number: `+${phone_number}`,
      date_of_birth: dob,
    }
    return await AuthAPI.post(url, data)
  }

  render() {
    counterpart.setLocale(localStorage.getItem("lang"))
    const { showLoading } = this.props

    const phone = counterpart.translate("placeholder_copy.member_phone")
    const signUp_fullname = counterpart.translate("placeholder_copy.member_name")
    const signUp_submit = counterpart.translate("placeholder_copy.signUp_submit")
    const signUp_submitting = counterpart.translate(
      "placeholder_copy.signUp_submitting",
    )
    const login_pin = counterpart.translate("placeholder_copy.login_pin")
    const female = counterpart.translate("placeholder_copy.female")
    const male = counterpart.translate("placeholder_copy.male")
    const gender = counterpart.translate("placeholder_copy.gender")
    const nationality = counterpart.translate("placeholder_copy.nationality")
    const login_pin_confirm = counterpart.translate(
      "placeholder_copy.login_pin_confirm",
    )
    const birthdate = counterpart.translate("placeholder_copy.date_of_birth")
    const select_nationality = counterpart.translate(
      "placeholder_copy.select_nationality",
    )

    const loadingStyle = {
      fontFamily: "Fira Sans",
      fontSize: "20px",
    }

    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
    }

    const formStyle = {
      flex: "10",
      padding: "5px",
      fontFamily: "Fira Sans",
      fontSize: "17px",
      height: "50px",
    }

    const errorStyle = {
      fontFamily: "Fira Sans",
      fontSize: "17px",
      color: "red",
    }

    const { errors, bounce, lang, _country } = this.state,
      en = false

    if (
      showLoading.loadingToggleState ||
      (this.props.isCreating && !this.props.serverError)
    ) {
      return <StellarLoader />
    }

    return (
      <div>
        <div
          style={{
            backgroundColor: "white",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <Translate
            content="title_copy.header_signup"
            component="h4"
            style={headerStyle}
          />
        </div>
        <Form onSubmit={this.onSubmit} noValidate>
          <FormGroup>
            <Label
              className={
                localStorage.getItem("lang") === "ARABIC" ? "float-right" : ""
              }
              style={loadingStyle}
            >
              {signUp_fullname}
            </Label>
            <Input
              type="text"
              name="full_name"
              style={formStyle}
              placeholder={signUp_fullname}
              onChange={this.onChange}
              error={errors.full_name}
              value={this.state.full_name}
            />
            <span name="name_validation" style={errorStyle}>
              {errors.full_name}
            </span>
          </FormGroup>
          <FormGroup
            style={{
              direction: "ltr",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Label
              style={{
                ...loadingStyle,
                textAlign:
                  localStorage.getItem("lang") === "ARABIC" ? "right" : "left",
                width: "100%",
              }}
            >
              {phone}
            </Label>
            <PhoneInput
              inputClass="phone_number"
              country={_country.toLocaleLowerCase()}
              enableSearch
              disableSearchIcon
              placeholder={phone}
              error={errors.phone_number}
              style={loadingStyle}
              inputStyle={{
                flex: "10",
                fontFamily: "Fira Sans",
                height: "50px",
                width: "100%",
                fontSize: "20px",
              }}
              value={this.state.phone_number}
              onChange={(phone_number) => this.setState({ phone_number })}
              limitMaxLength={true}
              localization={lang === "ARABIC" ? ar : en}
            />
            {bounce && <span style={errorStyle}>{bounce}</span>}
            <span name="phone_validation" style={errorStyle}>
              {errors.phone_number}
            </span>
          </FormGroup>
          <FormGroup>
            <Label
              className={
                localStorage.getItem("lang") === "ARABIC" ? "float-right" : ""
              }
              style={loadingStyle}
            >
              {birthdate}
            </Label>
            <Input
              type="date"
              name="date_of_birth"
              placeholder={birthdate}
              selected={this.state.date_of_birth}
              style={formStyle}
              onChange={this.onChange}
              defaultValue={defaultDate()}
              numberofmonths={1}
              max={defaultDate()}
            />
            <span style={errorStyle}>{errors.date_of_birth}</span>
          </FormGroup>
          {["ARABIC", "LEBANESE"].includes(this.state.lang) && (
            <MultipleSelect
              title={nationality}
              preSetItems={this.state.nationalities}
              callback={(items) => {
                this.setState({ ...this.state, nationalities: items })
              }}
              placeholderOption={select_nationality}
              options={[
                {
                  value: getTranslation("countries", "Lebanon"),
                  title: getTranslation("countries", "Lebanon"),
                },
                {
                  value: getTranslation("countries", "Syria"),
                  title: getTranslation("countries", "Syria"),
                },
                {
                  value: getTranslation("countries", "Palestine"),
                  title: getTranslation("countries", "Palestine"),
                },
              ]}
            />
          )}
          <FormGroup>
            <Label
              className={
                localStorage.getItem("lang") === "ARABIC" ? "float-right" : ""
              }
              style={loadingStyle}
            >
              {gender}
            </Label>
            <Input
              id="gender"
              type="select"
              name="gender"
              style={formStyle}
              onChange={this.onChange}
              value={this.state.gender}
            >
              <option disabled value="">
                {gender}
              </option>
              <option value="female">{female}</option>
              <option value="male">{male}</option>
            </Input>
            <span name="required_field_validation" style={errorStyle}>
              {errors.gender}
            </span>
          </FormGroup>
          <FormGroup>
            <Label
              className={
                localStorage.getItem("lang") === "ARABIC" ? "float-right" : ""
              }
              style={loadingStyle}
            >
              {login_pin}
            </Label>
            <Input
              type="password"
              name="pin"
              style={formStyle}
              placeholder={login_pin}
              onChange={this.onChange}
              error={errors.pin}
              value={this.state.pin}
            />
            <span name="pin_validation" style={errorStyle}>
              {errors.pin}
            </span>
          </FormGroup>
          <FormGroup>
            <Label
              className={
                localStorage.getItem("lang") === "ARABIC" ? "float-right" : ""
              }
              style={loadingStyle}
            >
              {login_pin_confirm}
            </Label>
            <Input
              type="password"
              name="confirm_pin"
              style={formStyle}
              placeholder={login_pin_confirm}
              onChange={this.onChange}
              error={errors.confirm_pin}
              value={this.state.confirm_pin}
            />
            <span name="confirm_pin_validation" style={errorStyle}>
              {errors.confirm_pin}
            </span>
          </FormGroup>

          <Alert color="warning">
            <span>
              <Translate
                content="notifications.gdpr_warning"
                style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
              />
            </span>
          </Alert>
          <FormGroup>
            <Input
              type="submit"
              value={this.state.buttonDisable ? signUp_submitting : signUp_submit}
              name="submit"
              className="btn btn-primary"
              disabled={this.state.buttonDisable}
            />
          </FormGroup>
        </Form>
      </div>
    )
  }
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const mapStateToProps = (state) => {
  return {
    serverError: state.createUserError,
    isCreating: state.isCreatingUser,
    showLoading: state.loadingToggleState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendUserData: (url, userData) => dispatch(postCreateUser(url, userData)),
    showLoadingState: (state) => dispatch(loadingToggleState(state)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
