export const isConnected = (state = true, action) => {
  switch (action.type) {
    case "UPDATE_NETWORK_STATUS":
      state = action.payload
      return state
    default:
      return state
  }
}

export const uploadStatus = (
  state = { isUploading: false, failedToUpload: false, complete: false },
  action,
) => {
  switch (action.type) {
    case "TOGGLE_IS_UPLOADING":
      state = action.payload
      return {
        ...state,
        isUpLoading: action.payload,
      }
    case "TOGGLE_FAILED_TO_UPLOAD":
      state = action.payload
      return {
        ...state,
        failedToUpload: action.payload,
        isUpLoading: false,
      }
    case "TOGGLE_UPLOAD_COMPLETE":
      state = action.payload
      return {
        ...state,
        complete: action.payload,
        isUpLoading: false,
      }
    default:
      return state
  }
}
