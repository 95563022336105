import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from "react-cookie"
import { triggerAction } from "../../store/constants"
import { handleSocketConnection } from "../../store/actions/auth"
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state) => ({
    user: state.user,
  }))
  const dispatch = useDispatch()
  const [cookies] = useCookies(["token"])
  let isValid = user && user.token

  if (!isValid && localStorage.getItem("token")) {
    dispatch(
      triggerAction("SET_USER_DATA", {
        ...user,
        token: cookies.token,
        tokenBC: cookies.tokenBC,
      }),
    )
    isValid = true
  }
  const uConnected = localStorage.getItem("uconnected")
  if (user && user.id && !uConnected) {
    dispatch(handleSocketConnection("connected", user.id))
    localStorage.setItem("uconnected", "true")
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isValid && "/" !== props.location.pathname) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        } else if (isValid && "/" === props.location.pathname) {
          return (
            <Redirect
              to={{
                pathname: "/Groups",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}

export default ProtectedRoute
