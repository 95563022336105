import { backendAPI } from "../api"

export const loadingToggleState = (status) => ({
  type: "LOADING_TOGGLE_ACTION",
  loadingToggleState: status,
})

export const isCreatingUser = (bool) => ({
  type: "IS_CREATING_USER",
  isCreatingUser: bool,
})

export const createUser = (user) => ({
  type: "CREATE_USER",
  user,
})

export const createUserDetails = (userDetails) => ({
  type: "CREATE_USER_DETAILS",
  userDetails,
})

export const createUserError = (error) => ({
  type: "CREATE_USER_ERROR",
  error: error,
})

export function postCreateUser(url, userData) {
  return async function (dispatch) {
    dispatch(isCreatingUser(true))
    try {
      const res = await backendAPI.post(url, userData)
      dispatch(createUser(res.data))
      dispatch(isCreatingUser(false))
    } catch (error) {
      return dispatch(createUserError(error))
    }
  }
}
