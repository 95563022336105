import React, { useState } from "react"
import MeetingBadge from "../layout/MeetingBadge"
import LangSelector from "../layout/LangSelector"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { connect } from "react-redux"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink as Nav2,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { setCurrentPage } from "../../store/reducers/navigation"
import { navigationMap } from "../../view/shared/helpers"
import { logoutAction } from "../../store/actions/auth"
import StatusBanner from "./NetworkStatusBanner"
import { useCookies } from "react-cookie"
import { selectCycle } from "../../store/actions/meetings"

counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const BackNav = (props) => {
  return (
    <div>
      <StatusBanner />
      <Navbar
        light
        expand="md"
        style={{
          backgroundColor: ["uat", "staging"].includes(
            window.REACT_APP_PWA_APP_ENV.toLocaleLowerCase(),
          )
            ? "#dc2f02"
            : "initial",
          flexDirection:
            localStorage.getItem("lang") === "ARABIC" ? "row-reverse" : "initial",
        }}
      >
        <NavbarBrand
          style={{
            backgroundColor: ["uat", "staging"].includes(
              window.REACT_APP_PWA_APP_ENV.toLocaleLowerCase(),
            )
              ? "#dc2f02"
              : "initial",
          }}
          className="navbar-brand arrow-icon"
        >
          <FontAwesomeIcon
            onClick={props.setPage}
            style={props.iconStyle}
            icon="arrow-left"
          />
          <span
            style={{
              backgroundColor: ["uat", "staging"].includes(
                window.REACT_APP_PWA_APP_ENV.toLocaleLowerCase(),
              )
                ? "#dc2f02"
                : "initial",
            }}
            className="navbar-brand-title"
          >
            vsla.online
          </span>
          <NavbarToggler onClick={props.toggle} />
        </NavbarBrand>
      </Navbar>
      {props.token && (
        <CollapsableMenu
          vsla={props.vsla}
          isOpen={props.isOpen}
          toggle={props.toggle}
          setPage={props.navigateToHome}
          logOut={props.logOut}
        />
      )}
    </div>
  )
}

const CollapsableMenu = (props) => {
  const [languageSelectorIsOpen, setLanguageSelectorOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [cookies, removeCookie] = useCookies(["token", "tokenBC"])
  const NavLinkStyle = {
    color: "#02294a",
    cursor: "pointer",
  }
  const logoutAndResetNavState = () => {
    removeCookie("token")
    removeCookie("tokenBC")
    props.toggle()
    props.logOut()
  }

  return (
    <Collapse
      isOpen={props.isOpen}
      navbar
      style={{
        padding: "0 10px",
        textAlign: localStorage.getItem("lang") === "ARABIC" ? "right" : "left",
      }}
    >
      <Nav style={props.collapseStyle} className="mr-auto" navbar>
        <NavItem>
          <Nav2
            style={NavLinkStyle}
            onClick={() => {
              props.setPage("group-main")
              props.toggle()
            }}
          >
            <Translate
              content="header.vsla"
              style={{
                fontFamily: "Fira Sans",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#000",
              }}
            />
          </Nav2>
        </NavItem>
        <NavItem onClick={props.toggle}>
          <MeetingBadge key={props.isOpen} />
        </NavItem>
        <UncontrolledDropdown nav inNavbar>
          <span
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setLanguageSelectorOpen(!languageSelectorIsOpen)
            }}
          >
            <Translate
              content="menu.language"
              component="span"
              style={{
                fontFamily: "Fira Sans",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            />
            <FontAwesomeIcon
              style={{
                padding: "0 4px",
                fontSize: "35px",
              }}
              icon="angle-right"
            />
          </span>
          {languageSelectorIsOpen && <LangSelector toggleBack={props.toggle} />}

          <DropdownMenu className="shadow p-3 mb-5 bg-white rounded">
            <DropdownItem divider />
            <DropdownItem onClick={props.toggle}>
              <Translate
                content="menu.close"
                component="span"
                style={{
                  fontFamily: "Fira Sans",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <a
          style={{
            ...NavLinkStyle,
            padding: "0.5rem 0",
            textDecoration: "none",
          }}
          href="https://hiveonlineplanning.atlassian.net/wiki/x/AYCzgQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate
            content="header.whats_new"
            style={{
              fontFamily: "Fira Sans",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#000",
            }}
          />
        </a>
        <a
          style={{
            ...NavLinkStyle,
            padding: "0.5rem 0",
            textDecoration: "none",
          }}
          href="https://hiveonlineplanning.atlassian.net/wiki/spaces/AT/overview#VSLA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate
            content="header.tutorials"
            style={{
              fontFamily: "Fira Sans",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#000",
            }}
          />
        </a>
        <a
          style={{
            ...NavLinkStyle,
            padding: "0.5rem 0",
            textDecoration: "none",
          }}
          href="https://hiveonlineplanning.atlassian.net/l/cp/skb5xbq7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate
            content="header.legal"
            style={{
              fontFamily: "Fira Sans",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#000",
            }}
          />
        </a>
        <Nav2
          style={NavLinkStyle}
          onClick={logoutAndResetNavState}
          className="navigable"
        >
          <Translate
            content="header.logout"
            style={{
              fontFamily: "Fira Sans",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#000",
            }}
          />
        </Nav2>
      </Nav>
    </Collapse>
  )
}

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const iconStyle = {
    fontSize: "25px",
    cursor: "pointer",
    transform:
      localStorage.getItem("lang") === "ARABIC" ? "rotateY(180deg)" : "none",
  }
  const logoStyle = {
    paddingTop: "7px",
    backgroundColor: "white",
    paddingBottom: "5px",
    paddingRight: "40px",
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const setPage = () => {
    const metadata = props.currentPage.split("-")
    const pages = navigationMap[metadata[0]]
    const currentPage = pages[metadata[1]]
    if (currentPage.backPage === "meetingsMenu" && props.selectedCycle) {
      props.setCycle(null)
      return
    }
    if (!props.currentPage || !currentPage.backPage) {
      window.history.push("/")
    }
    props.setActivePage(`${metadata[0]}-${currentPage.backPage}`)
  }

  if (props.currentPage && props.currentPage.split("-")[1] !== "main") {
    const token = props.user
      ? props.user.token || localStorage.getItem("token")
      : null
    return (
      <BackNav
        token={token}
        vsla={props.vsla}
        isOpen={isOpen}
        toggle={toggle}
        setUser={props.setUser}
        logOut={props.logMeOut}
        setPage={setPage}
        navigateToHome={props.setActivePage}
        iconStyle={iconStyle}
        restStore={props.restStore}
        logoStyle={{ ...logoStyle, paddingRight: 0 }}
      />
    )
  }
  return (
    <div>
      <StatusBanner />
      <Navbar
        light
        expand="md"
        style={{
          backgroundColor: ["uat", "staging"].includes(
            window.REACT_APP_PWA_APP_ENV.toLocaleLowerCase(),
          )
            ? "#dc2f02"
            : "initial",
          flexDirection:
            localStorage.getItem("lang") === "ARABIC" ? "row-reverse" : "initial",
        }}
      >
        <NavbarBrand
          style={{
            backgroundColor: ["uat", "staging"].includes(
              window.REACT_APP_PWA_APP_ENV.toLocaleLowerCase(),
            )
              ? "#dc2f02"
              : "initial",
          }}
          className="navbar-brand no-arrow-icon"
        >
          vsla.online
        </NavbarBrand>
        {props.user && props.user.token && <NavbarToggler onClick={toggle} />}
      </Navbar>
      {props.user && props.user.token && (
        <CollapsableMenu
          isOpen={isOpen}
          toggle={toggle}
          vsla={props.vsla}
          setPage={props.setActivePage}
          logOut={props.logMeOut}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedCycle: state.cycles.selectedCycle,
    vsla: state.loadedvslas,
    currentPage: state.currentPage,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCycle: (item) => dispatch(selectCycle(item)),
    setActivePage: (page) => {
      dispatch(setCurrentPage(page))
    },
    logMeOut: () => dispatch(logoutAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
