export function isCreatingMeeting(state = false, action) {
  switch (action.type) {
    case "IS_CREATING_MEETING":
      return action.isCreating
    default:
      return state
  }
}

export function createdMeeting(state = [], action) {
  switch (action.type) {
    case "CREATE_MEETING":
      return action.meeting
    default:
      return state
  }
}

export function updatedMeeting(state = [], action) {
  switch (action.type) {
    case "UPDATE_MEETING":
      return action.updatedMeeting
    default:
      return state
  }
}

export function createMeetingError(state = [], action) {
  switch (action.type) {
    case "CREATE_MEETING_ERROR":
      return action.error
    default:
      return state
  }
}
