import React from "react"
import { Container, Row, Col, Label } from "reactstrap"
import { Form, Input, FormGroup } from "reactstrap"
import stellarProcessing from "../../view/assets/stellar-animation.gif"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"

import { toast } from "react-toastify"
import { WarningMessage } from "../../view/shared/notifications/Notifications"
import { connect } from "react-redux"
import validateInput from "../../view/shared/validations/createLoan"
import { getCurrency } from "../../view/shared/utilities/currency"
import { initiateTransaction } from "../../store/reducers/transactions/actions"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import { setCurrentPage } from "../../store/reducers/navigation"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../view/shared/utilities/cache"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const spinnerStyle = {
  height: "90%",
  width: "90%",
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
}

const infoStyle = {
  padding: ".3rem",
  textAlign: "center",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#696969",
}

const notify = (msg) => toast(msg)

const CreateLoan = (props) => {
  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  const [offlineMeeting, setOfflineMeeting] = React.useState(null)

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting(cachedMeeting[0])
  }, [])

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting])

  const [state, setState] = React.useState({
    amount: "",
    service_charge_amount: 0,
    asset: "DEBT",
    source: "member",
    dest: "fund",
    loading: false,
    errors: {},
    lang: localStorage.getItem("lang"),
    loan_purpose: "",
    write_loan_Purpose: false,
    other_purpose_msg: "",
  })

  const onChange = (e) => {
    const { name, value } = e.target
    const write_loan_Purpose = value === "other" || name === "other_purpose_msg"
    const otherPurposeValue = write_loan_Purpose ? state.other_purpose_msg : ""
    setState({
      ...state,
      write_loan_Purpose,
      other_purpose_msg: otherPurposeValue,
      [name]: value,
    })
  }

  const isValid = () => {
    let data = { ...state }
    if (!props.currentMember.loan_service_charge) {
      delete data.service_charge_amount
    }
    const { errors, isValid } = validateInput(data)

    if (!isValid) {
      setState({
        ...state,
        errors,
      })
    }

    return isValid
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isValid()) {
      createLoan(
        state.amount,
        state.asset,
        state.source,
        state.dest,
        state.service_charge_amount,
        state.loan_purpose,
        state.other_purpose_msg,
      )
      setState({
        ...state,
        amount: "",
        service_charge_amount: 0,
        other_purpose_msg: "",
        loan_purpose: "",
      })
    }
  }

  const hasLoan = () => {
    return props.memberDebt(props.currentMember.blockchain_wallet_id).balance > 0
  }

  const loanQualify = () => {
    let savings = 0

    if (!props.isloading) {
      savings = props.balance.savings.balance
    }
    return parseFloat(savings)
  }

  const createLoan = (
    amount,
    asset,
    source,
    dest,
    service_charge_amount,
    loan_purpose,
    other_purpose_msg,
  ) => {
    const lang_code = localStorage.getItem("lang")

    const lang = {
      FRANÇAIS: {
        message: `Confirmer le prêt de ${
          parseFloat(state.amount) + parseFloat(state.service_charge_amount)
        } pour le membre`,
      },
      ENGLISH: {
        message: `Confirm loan of ${
          parseFloat(state.amount) + parseFloat(state.service_charge_amount)
        } for member`,
      },
      SWAHILI: {
        message: `Thibitisha mkopo wa shilingi ${
          parseFloat(state.amount) + parseFloat(state.service_charge_amount)
        } kwa mwanachama`,
      },
      ZARMA: {
        message: `Zarma for Confirm loan of ${
          parseFloat(state.amount) + parseFloat(state.service_charge_amount)
        } for member`,
      },
      HAOUSSA: {
        message: `Haoussa for Confirm loan of ${
          parseFloat(state.amount) + parseFloat(state.service_charge_amount)
        } for member`,
      },
      SPANISH: {
        message: `Confirmar préstamo de ${
          parseFloat(state.amount) + parseFloat(state.service_charge_amount)
        } lempiras para miembro`,
      },
      LEBANESE: {
        message: `Confirm loan of ${parseFloat(state.amount)} for household`,
      },
      PORTUGUESE: {
        message: `Confirmar empréstimo de ${parseFloat(state.amount)} para o membro`,
      },
      ARABIC: {
        message: `أكد قرض بقيمة ${parseFloat(state.amount)} للأسرة`,
      },
    }

    if (hasLoan()) {
      notify(<WarningMessage msg={"notifications.loan_error"} />)
      setState({ ...state, loading: false })
      return
    }

    if (window.confirm(lang[lang_code].message)) {
      setState({ ...state, loading: true })

      const uuid = props.currentMember.pubkey

      if (amount !== "") {
        props.commitTransaction(
          {
            uid: uuid,
            serviceCharges: service_charge_amount,
            meetingId: props.ongoingMeeting.length
              ? props.ongoingMeeting[0].id
              : offlineMeeting.data.id,
            type: "CREATE_LOAN",
            groupId: props.group[0].blockchain_key,
            memberId: props.currentMember.blockchain_wallet_id,
            amount: parseFloat(amount),
            sharePrice: 1,
            fund: "welfare",
            metadata: {
              loanPurpose:
                loan_purpose === "other" ? other_purpose_msg : loan_purpose,
            },
          },
          offlineMeeting,
        )
        notify(<WarningMessage msg={"notifications.processing"} />)
        props.setPage("group-memberList")
      }
    } else {
      notify(<WarningMessage />)
      setState({ ...state, loading: false })
    }
  }

  counterpart.setLocale(state.lang)
  const loan_amount = counterpart.translate("placeholder_copy.loan_amount")
  const loan_submit = counterpart.translate("placeholder_copy.loan_submit")
  const loan_amount_error = counterpart.translate("notifications.loan_amount_error")
  const select = counterpart.translate("placeholder_copy.select")
  const business_development = counterpart.translate(
    "dropdowns.business_development",
  )
  const personal_emergency = counterpart.translate("dropdowns.personal_emergency")
  const other = counterpart.translate("dropdowns.other")
  const loanPurpose = counterpart.translate("span.loan_purpose")

  const [totalSHA, setSHA] = React.useState([0])

  React.useEffect(() => {
    let SHA = [0]
    props.fund_balance.forEach((f) => {
      if (f.asset === "SHAR") {
        SHA.push(parseFloat(f.balance))
      }
    })
    setSHA(SHA)
  }, [])

  React.useEffect(() => {
    let SHA = [0]
    props.fund_balance.forEach((f) => {
      if (f.asset === "SHAR") {
        SHA.push(parseFloat(f.balance))
      }
    })
    setSHA(SHA)
  }, [props.fund_balance])

  if (!state.loading) {
    return (
      <div>
        <div>
          <h4 style={headerStyle}>{props.currentMember.full_name}</h4>
        </div>
        <div>
          <Translate content="title_copy.loan" component="h4" style={headerStyle} />
        </div>
        <div style={infoStyle}>
          <Translate content="title_copy.current_savings" />:{" "}
          {loanQualify() === 0 ? "0.00" : loanQualify().toFixed(2)}{" "}
          {getCurrency(props.group[0].country).symbol}
        </div>
        {state.amount > loanQualify() || state.amount > totalSHA[1] * 3 ? (
          <span
            style={{
              color: "red",
              textAlign: "center",
              paddingLeft: "0.7em",
            }}
          >
            {loan_amount_error}
          </span>
        ) : (
          <span></span>
        )}

        <Row>
          <br />
          <Col>
            <Form onSubmit={onSubmit}>
              <Container>
                <FormGroup>
                  <span style={{ color: "red" }}>{state.errors.amount}</span>
                  <Input
                    type="number"
                    name="amount"
                    style={loadingStyle}
                    placeholder={loan_amount}
                    onChange={onChange}
                    value={state.amount}
                  />
                </FormGroup>
                {props.currentMember.loan_service_charge && (
                  <FormGroup>
                    <span style={{ color: "red" }}>
                      {state.errors.service_charge_amount}
                    </span>
                    <Label>
                      <Translate content="span.loanServiceCharge" component="span" />
                    </Label>
                    <Input
                      type="number"
                      name="service_charge_amount"
                      style={loadingStyle}
                      placeholder="0"
                      value={state.service_charge_amount}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}

                <FormGroup>
                  <span style={{ color: "red" }}>{state.errors.loan_purpose}</span>
                  <Label>
                    <Translate content="span.loan_purpose" component="span" />
                  </Label>
                  <Input
                    id="loan_purpose"
                    type="select"
                    name="loan_purpose"
                    style={loadingStyle}
                    onChange={onChange}
                    value={state.loan_purpose}
                  >
                    <option value="">{select}</option>
                    <option value="Business Development">
                      {business_development}
                    </option>
                    <option value="Personal Emergency">{personal_emergency}</option>
                    <option value="other">{other}</option>
                  </Input>
                </FormGroup>

                {state.write_loan_Purpose && (
                  <FormGroup>
                    <span style={{ color: "red" }}>
                      {state.errors.other_purpose_msg}
                    </span>
                    <Label>
                      <Translate
                        content="span.write_loan_purpose"
                        component="span"
                      />
                    </Label>
                    <Input
                      type="text"
                      name="other_purpose_msg"
                      style={loadingStyle}
                      placeholder={loanPurpose}
                      value={state.other_purpose_msg}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              </Container>
              <Container>
                <FormGroup>
                  <Input
                    type="submit"
                    value={loan_submit}
                    disabled={
                      state.amount > parseFloat(loanQualify()) ? true : false
                    }
                    name="submit"
                    className="btn btn-primary"
                  />
                </FormGroup>
              </Container>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
        <br />
      </center>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setCurrentPage(page)),
  commitTransaction: (data, meeting) => dispatch(initiateTransaction(data, meeting)),
})

const mapStateToProps = (state) => {
  return {
    user: state.user,
    fund_balance: state.memberBalanceLoaded,
    isloading: state.vslaBalanceLoading,
    balance: state.account.balances,
    ongoingMeeting: state.currentOngoingMeeting,
    memberDebt: (id) => state.account.members[id].loan,
    currentMember: state.loadedMember,
    group: state.loadedvslas,
    vslaSummary: selectCreatedVslaInfo(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoan)
