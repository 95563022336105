export function formatStr(str) {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export function formatCurrency(amt) {
  if (amt > 0) {
    return parseFloat(amt).toFixed(2)
  } else {
    return parseFloat(0).toFixed(2)
  }
}
