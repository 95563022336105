import React, { Component } from "react"
import { Form, Input, Label, FormGroup, Container } from "reactstrap"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import ZARMA from "../../view/shared/lang/ZARMA"
import SPANISH from "../../view/shared/lang/SPANISH"
import { toast } from "react-toastify"
import { v4 as uuid } from "uuid"
import { connect } from "react-redux"
import {
  postCreateVsla,
  postCreateGroup,
  createVslaError,
  checkGroupName,
} from "../../store/actions/createVsla"
import { setCurrentPage } from "../../store/reducers/navigation"
import validateInput from "../../view/shared/validations/addGroup"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import {
  defaultGroupSettings,
  getCountryName,
  getTranslation,
} from "../../view/shared/helpers"
import StellarLoader from "../layout/StellarLoader"
import ProjectSelector from "../layout/MultiSelector"
import LocationForm from "../layout/Location/LocationForm"

counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("SPANISH", SPANISH)

const ErrorMessage = (props) => {
  return (
    <div style={failStyle}>
      {props.msg || getTranslation("notifications", "group_create_error")}
    </div>
  )
}

export class AddGroup extends Component {
  notify = (msg) => toast(msg)

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      share_value: "",
      vsla_uuid: uuid(),
      sec_id: this.props.user.id,
      location: "",
      savings_cycle: "",
      country: getCountryName(localStorage.getItem("_country")),
      projects: [],
      currency: "",
      establishment_date: "",
      secretary_loan_service_charge: true,
      errors: {},
      showLocationError: false,
      selectedLocations: {},
    }
    this.props.updateError()
  }

  onChange = (e) => {
    if (e.target.name === "name") {
      this.props.updateError()
    }

    if (
      e.target.name === "country" &&
      ["لبنان", "Lebanon"].includes(e.target.value)
    ) {
      this.setState({ share_value: 1, savings_cycle: "12" })
    } else if (
      e.target.name === "country" &&
      ["لبنان", "Lebanon"].includes(this.state.country)
    ) {
      this.setState({ share_value: "", establishment_date: "" })
    }
    if (e.target.name === "secretary_loan_service_charge") {
      const isTrueSet = e.target.value === "true"
      this.setState({ [e.target.name]: isTrueSet })
      return
    }
    this.setState({ [e.target.name]: e.target.value })
  }
  isValid() {
    const { errors, isValid } = validateInput(this.state)

    if (!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  filterProjectsByCountry = () => {
    const { projects } = this.state
    // Filter project props to include only those where country matches state.country and id is in state.projects
    const filteredIds = this.props.projects
      .filter(
        (project) =>
          project.country === this.props.transportedLocationData.country.name &&
          projects.includes(project.id),
      )
      .map((project) => project.id)
    // Set a new state or just use the filteredIds for further processing
    return filteredIds
  }

  setStateVariables = (param) => {
    this.setState({ address: param })
  }

  onSubmit = async (e) => {
    e.preventDefault()

    try {
      if (this.isValid()) {
        if (!this.props.transportedLocationData.valid) {
          this.setState({ ...this.state, showLocationError: true })
          setTimeout(() => {
            this.setState({ ...this.state, showLocationError: false })
          }, 4000)
          return
        }
        const blockChain = {
          description: this.state.name,
          metadata: {
            status: "Active",
            share_value: parseFloat(this.state.share_value),
            sec_id: parseInt(this.state.sec_id),
            vsla_uuid: this.state.vsla_uuid,
            welfareCashOutType: "Share based",
            membershipCashOutType: "Refund",
            currency: this.state.currency,
            fineCashOutType: "Share based",
            loanInterestCashOutType: defaultGroupSettings["loanInterestCashOutType"],
            savingsCashOutType: defaultGroupSettings["savingsCashOutType"],
          },
        }
        // Retrieve a list of project IDs where the project's country matches the currently selected country in the state.
        const validProjectIds = this.filterProjectsByCountry()
        // Remove valid attribute from location data
        delete this.props.transportedLocationData.valid
        const group = {
          locality: this.props.transportedLocationData.locality
            ? this.props.transportedLocationData.locality.name
            : "",
          description: this.state.name,
          name: this.state.name,
          blockchain_key: null,
          blockchain_wallet_id: this.state.vsla_uuid,
          longi: "",
          lati: "",
          county: "",
          village: "",
          cycle_duration: this.state.savings_cycle,
          status: "Active",
          country: this.props.transportedLocationData.country
            ? this.props.transportedLocationData.country.name
            : "",
          members: [],
          establishment_date: this.state.establishment_date,
          project_ids: validProjectIds,
          address: this.props.transportedLocationData,
        }

        const secretary = {
          pubkey: uuid(),
          full_name: this.props.user.full_name,
          phone: this.props.user.phone_number.includes("+")
            ? this.props.user.phone_number
            : `+${this.props.user.phone_number}`,
          gender: this.props.user.gender,
          date_of_birth: this.props.user.date_of_birth.split("T")[0],
          member_role: counterpart.translate("dropdowns.secretary"),
          status: "Active",
          loan_service_charge: this.state.secretary_loan_service_charge,
        }
        const data = {
          blockChain,
          group,
          secretary,
        }
        const isExist = await this.props.checkName(group.description)
        if (isExist.data && isExist.data.status === "Active") {
          this.props.updateError("duplicateGroup")
          return
        }
        this.props.setPayloadData(data)
        this.setState({ errors: {} })
        this.props.setPage("group-addFunds")
      }
    } catch (error) {
      console.log("Error Occured: ", error)
    }
  }

  handleProjectChange = (selectedOptionId) => {
    this.onChange({
      target: {
        value: selectedOptionId || [],
        name: "projects",
      },
    })
  }

  render() {
    const { isCreating } = this.props

    counterpart.setLocale(localStorage.getItem("lang"))
    const create_group_name = counterpart.translate(
      "placeholder_copy.create_group_name",
    )
    const create_group_submit = counterpart.translate(
      "placeholder_copy.create_group_submit",
    )
    const create_group_share_value = counterpart.translate(
      "placeholder_copy.create_group_share_value",
    )

    const savings_cycle = counterpart.translate("placeholder_copy.savings_cycle")

    const year = counterpart.translate("dropdowns.year")
    const nineMonths = counterpart.translate("dropdowns.nine_months")
    const sixMonths = counterpart.translate("dropdowns.six_months")
    const establishmentDate = counterpart.translate("dropdowns.establishment_date")
    const currency = counterpart.translate("placeholder_copy.currency")
    const USD = counterpart.translate("dropdowns.USD")
    const LBP = counterpart.translate("dropdowns.LBP")

    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "1.8em",
      textAlign: "center",
      paddingBottom: "0.5em",
    }
    const radioTextStyle = {
      fontFamily: "Fira Sans",
      fontSize: "18px",
    }

    const formStyle = {
      flex: "10",
      padding: "5px",
      fontFamily: "Fira Sans",
      fontSize: "20px",
      height: "50px",
    }

    const { errors } = this.state

    if (isCreating) {
      return (
        <StellarLoader
          msg={getTranslation("placeholder_copy", "group_loader_msg")}
        />
      )
    }
    return (
      <div>
        <div
          style={{
            backgroundColor: "white",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <Translate
            content="title_copy.create_group"
            component="h4"
            className="textStyle"
            style={headerStyle}
          />
        </div>
        <Form onSubmit={this.onSubmit}>
          <Container>
            {!!this.props.createVslaError && (
              <ErrorMessage
                msg={
                  this.props.createVslaError === "duplicateGroup"
                    ? getTranslation("notifications", "group_exist_error")
                    : ""
                }
              />
            )}
            <FormGroup>
              <span name="name_required_field_validation" style={{ color: "red" }}>
                {errors.name}
              </span>
              <Input
                type="text"
                name="name"
                style={formStyle}
                placeholder={create_group_name}
                onChange={this.onChange}
                value={this.state.name}
              />
            </FormGroup>
            {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) &&
              !["لبنان", "Lebanon"].includes(this.state.country) && (
                <FormGroup>
                  <Label style={loadingStyle}>{establishmentDate}</Label>
                  <Input
                    type="date"
                    name="establishment_date"
                    selected={this.state.establishment_date}
                    style={formStyle}
                    onChange={this.onChange}
                    numberofmonths={1}
                    max={new Date().toISOString().slice(0, 10)}
                  />
                </FormGroup>
              )}
            <LocationForm
              preset={this.state.selectedLocations}
              defaultCountry={this.props.defaultCountry}
              validateData={this.state.showLocationError}
            />
            <FormGroup>
              <ProjectSelector
                onChange={this.handleProjectChange}
                selectorOptions={
                  this.props.transportedLocationData.country
                    ? this.props.projects.filter(
                        (project) =>
                          project.country ===
                          this.props.transportedLocationData.country.name,
                      )
                    : []
                }
                placeholderText={getTranslation("placeholder_copy", "project_name")}
              />
            </FormGroup>
            {["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
              <FormGroup
                style={{
                  marginTop:
                    this.state.projects.length === 2
                      ? "45px"
                      : this.state.projects.length > 2
                        ? "85px"
                        : "0px",
                }}
              >
                <span
                  name="currency_required_field_validation"
                  style={{ color: "red" }}
                >
                  {errors.currency}
                </span>
                <Input
                  id="currency"
                  type="select"
                  name="currency"
                  style={formStyle}
                  onChange={this.onChange}
                  value={this.state.currency}
                >
                  <option value="">{currency}</option>
                  <option value="USD">{USD}</option>
                  <option value="LBP">{LBP}</option>
                </Input>
              </FormGroup>
            )}

            {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
              <FormGroup
                style={{
                  marginTop:
                    this.state.projects.length === 2
                      ? "45px"
                      : this.state.projects.length > 2
                        ? "85px"
                        : "0px",
                }}
              >
                <span
                  name="savings_cycle_required_field_validation"
                  style={{ color: "red" }}
                >
                  {errors.savings_cycle}
                </span>
                <Input
                  id="savings_cycle"
                  type="select"
                  name="savings_cycle"
                  style={formStyle}
                  onChange={this.onChange}
                  value={this.state.savings_cycle}
                >
                  <option value="">{savings_cycle}</option>
                  <option value="6">{sixMonths}</option>
                  <option value="9">{nineMonths}</option>
                  <option value="12">{year}</option>
                </Input>
              </FormGroup>
            )}

            <FormGroup>
              <span
                name="share_value_required_field_validation"
                style={{ color: "red" }}
              >
                {errors.share_value}
              </span>
              <Input
                type="text"
                name="share_value"
                style={formStyle}
                placeholder={create_group_share_value}
                onChange={this.onChange}
                disabled={["لبنان", "Lebanon"].includes(this.state.country)}
                value={this.state.share_value}
              />
            </FormGroup>
          </Container>
          <Container>
            <FormGroup>
              <span style={loadingStyle}>
                {getTranslation("placeholder_copy", "secretary_pay_interest")}
              </span>
              <span style={{ color: "red" }}>{errors.paysInterest}</span>
              <Container style={{ display: "flex", justifyContent: "space-around" }}>
                <span>
                  <Input
                    id="payInterest"
                    type="radio"
                    name="secretary_loan_service_charge"
                    value={"true"}
                    /*
                      Anyway it gets converted to string.
                      but some browsers may not.
                     */
                    checked={this.state.secretary_loan_service_charge}
                    onChange={this.onChange}
                  />

                  <Translate
                    content="dropdowns.yes"
                    component="span"
                    style={radioTextStyle}
                  />
                </span>
                <span>
                  <Input
                    id="noInterestPaid"
                    type="radio"
                    name="secretary_loan_service_charge"
                    value={"false"}
                    checked={!this.state.secretary_loan_service_charge}
                    onChange={this.onChange}
                  />
                  <Translate
                    content="dropdowns.no"
                    component="span"
                    style={radioTextStyle}
                  />
                </span>
              </Container>
              <br />
            </FormGroup>
          </Container>
          <Container>
            <FormGroup>
              <Input
                type="submit"
                value={create_group_submit}
                name="submit"
                className="btn btn-primary"
                disabled={this.props.createVslaError}
              />
            </FormGroup>
          </Container>
        </Form>
      </div>
    )
  }
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

const failStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "red",
  fontWeight: "bold",
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isCreating: state.isCreatingVsla,
    createVslaError: state.createVslaError,
    projects: state.projects,
    defaultCountry: state.countries.defaultCountry,
    transportedLocationData: state.locationTransport,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    sendVslaData: (url, vsla, headers) =>
      dispatch(postCreateVsla(url, vsla, headers)),
    sendGroupData: (url, vsla, headers) =>
      dispatch(postCreateGroup(url, vsla, headers, ownProps)),
    updateError: (err = null) => dispatch(createVslaError(err)),
    checkName: (name) => dispatch(checkGroupName(name)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup)
