import { createStore, applyMiddleware, compose } from "redux"
import socketMiddleware from "./middlewares"
import thunk from "redux-thunk"
import logger from "redux-logger"
import combineReducers from "./reducers/index"

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch (e) {
    console.log(e)
  }
}

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    console.log(e)
    return undefined
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedState = loadFromLocalStorage()
const middleWares = ["production"].includes(window.REACT_APP_PWA_APP_ENV)
  ? applyMiddleware(socketMiddleware(), thunk)
  : applyMiddleware(socketMiddleware(), thunk, logger)

const store = createStore(
  combineReducers,
  persistedState,
  composeEnhancers(middleWares),
)

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store
