import isEmpty from "lodash/isEmpty"
import { countryAgeRequirements, isDateOfBirthValid } from "../utilities"

export default function validateInput(data) {
  const lang_code = localStorage.getItem("lang")

  const _country = localStorage.getItem("_country") || "GH"
  // Country-specific requirements for age
  const requiredAge = countryAgeRequirements[_country] || 18

  const lang_required = {
    FRANÇAIS: {
      message_required: `Ce champ est requis`,
    },
    ENGLISH: {
      message_required: `This field is required`,
    },
    SWAHILI: {
      message_required: `Ni lazima kujaza nafasi hii`,
    },
    ZARMA: {
      message_required: `Tilas batamo hima ga bara`,
    },
    HAOUSSA: {
      message_required: `Doole hilin ga shina nan`,
    },
    SPANISH: {
      message_required: `Este campo es requerido`,
    },
    LEBANESE: {
      message_required: `This field is required`,
    },
    ARABIC: {
      message_required: `هذا الحقل اجباري`,
    },
    PORTUGUESE: {
      message_required: `Este campo é obrigatório`,
    },
  }

  const lang_text_only = {
    FRANÇAIS: {
      message_required: `Entrer seulement du texte`,
    },
    ENGLISH: {
      message_required: `Enter text only`,
    },
    SWAHILI: {
      message_required: `Ingiza herufi pekee`,
    },
    ZARMA: {
      message_required: `Zarma for Enter text only`,
    },
    HAOUSSA: {
      message_required: `Rubuta matani kaɗay`,
    },
    SPANISH: {
      message_required: `Inserta solo texto`,
    },
    LEBANESE: {
      message_required: `Enter text only`,
    },
    ARABIC: {
      message_required: `ادخال احرف/ كلمات فقط`,
    },
    PORTUGUESE: {
      message_required: `Insira apenas texto`,
    },
  }

  const lang_phone_max_length = {
    FRANÇAIS: {
      message_required: `Le numéro de téléphone doit comporter 10 chiffres`,
    },
    ENGLISH: {
      message_required: `Phone number should be 10 digits`,
    },
    SWAHILI: {
      message_required: `Namba ya simu mwisho ni tarakimu 10`,
    },
    ZARMA: {
      message_required: `Zarma for Phone number should be 10 digits`,
    },
    HAOUSSA: {
      message_required: `ỳan ƙilgo 10 ke cikin lamba waya`,
    },
    SPANISH: {
      message_required: `El número de teléfono debe tener 10 dígitos`,
    },
    LEBANESE: {
      message_required: `Phone number should be 10 digits`,
    },
    PORTUGUESE: {
      message_required: `O número de telefone deve ter 10 dígitos`,
    },
    ARABIC: {
      message_required: `رقم الهاتف يجب ان يكون من ١٠ أرقام`,
    },
  }

  const min_date_msg = {
    FRANÇAIS: {
      message_required: `Format de date invalide ou ne répond pas aux exigences d'âge minimum de ${requiredAge} ans`,
    },
    ENGLISH: {
      message_required: `Invalid date format or does not meet minimum age requirements of ${requiredAge} years`,
    },
    SWAHILI: {
      message_required: `Muundo wa tarehe si sahihi au haukidhi mahitaji ya chini ya umri wa miaka ${requiredAge}`,
    },
    ZARMA: {
      message_required: `Invalid date format or does not meet minimum age requirements of ${requiredAge} years`,
    },
    HAOUSSA: {
      message_required: `Tsarin kwanan wata mara inganci ko bai cika mafi ƙarancin shekarun buƙatun shekaru ${requiredAge} ba`,
    },
    SPANISH: {
      message_required: `Formato de fecha no válido o no cumple con los requisitos de edad mínima de ${requiredAge} años.`,
    },
    LEBANESE: {
      message_required: `Invalid date format or does not meet minimum age requirements of ${requiredAge} years`,
    },
    PORTUGUESE: {
      message_required: `Formato de data inválido ou não atende aos requisitos de idade mínima de ${requiredAge} anos`,
    },
    ARABIC: {
      message_required: `تنسيق التاريخ غير صالح أو لا يستوفي متطلبات الحد الأدنى للعمر وهو ${requiredAge} عامًا`,
    },
  }

  let errors = {}
  const letters = /^[A-zÀ-ú\-\\' \u0600-\u06FF]+$/

  if (data.paysInterest === "") {
    errors.paysInterest = lang_required[lang_code].message_required
  }

  if (data.name === "") {
    errors.name = lang_required[lang_code].message_required
  }

  if (!data.name.match(letters)) {
    errors.name = lang_text_only[lang_code].message_required
  }

  if (data.phone_number !== "") {
    if (data.phone_number.length < 10) {
      errors.phone_number = lang_phone_max_length[lang_code].message_required
    }
  }

  if (data.role_in_group === "") {
    errors.role_in_group = lang_required[lang_code].message_required
  }

  if (data.gender === "") {
    errors.gender = lang_required[lang_code].message_required
  }

  if (!isDateOfBirthValid(data.date_of_birth, requiredAge)) {
    errors.date_of_birth = min_date_msg[lang_code].message_required
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}
