import {
  SET_LEARNING_CATEGORIES,
  SET_LEARNING_COURSES,
  SET_LEARNING_LESSONS,
  SET_LEARNING_TRANSLATIONS,
  SET_LEARNING_CONTENT,
  SET_CATEGORY_ITEMS,
} from "../constants"

export const learningData = (state = {}, action) => {
  const clone = { ...state }
  switch (action.type) {
    case SET_LEARNING_CATEGORIES:
      return { ...clone, categories: action.payload }
    case SET_LEARNING_COURSES:
      return { ...clone, courses: action.payload }
    case SET_LEARNING_LESSONS:
      return { ...clone, lessons: action.payload }
    case SET_LEARNING_TRANSLATIONS:
      return { ...clone, translations: action.payload }
    case SET_LEARNING_CONTENT:
      return { ...clone, contents: action.payload }
    default:
      return state
  }
}

export const selectedLearningContent = (
  state = { selectedLanguage: [localStorage.getItem("lang")] },
  action,
) => {
  const clone = { ...state }
  switch (action.type) {
    case SET_CATEGORY_ITEMS:
      return { ...clone, categoryItems: action.item }
    case "SET_LESSONS":
      return { ...clone, lessons: action.item }
    case "SET_LESSON_CONTENT":
      return { ...clone, lessonContent: action.item }
    default:
      return state
  }
}
