import React from "react"
import {
  Form,
  Col,
  Input,
  FormGroup,
  Container,
  Row,
  Alert,
  Label,
} from "reactstrap"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { toast } from "react-toastify"
import {
  SuccessMessage,
  ErrorMessage,
} from "../../view/shared/notifications/Notifications"
import { selectVlsaFundAccounts } from "../../store/reducers/getVslas"
import { connect } from "react-redux"
import { updateGroupSettings } from "../../store/actions/createVsla"
import { defaultGroupSettings, getTranslation } from "../../view/shared/helpers"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { fetchVslas } from "../../store/actions/getVslas"
import StellarLoader from "../layout/StellarLoader"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)
const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontSize: "1.8em",
  marginBottom: "0rem",
  fontWeight: "bolder",
  textAlign: "center",
}

const subheaderStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  textAlign: "center",
  lineHeight: "1.5em",
}

const formStyle = {
  flex: "10",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  height: "50px",
}

const spanStyle = {
  padding: ".3rem",
  textAlign: "center",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#696969",
}

const Settings = (props) => {
  const fetchData = async () => {
    const headers = {
      headers: {
        Authorization: "Bearer " + props.user.token || localStorage.getItem("token"),
      },
    }
    let id = props.user.id
    const url = `/api/v1/groups/${id}/groups`
    await props.fetchData(url, headers)
    setIsLoading(true)
  }

  const [isLoading, setIsLoading] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)

  React.useEffect(() => {
    fetchData()
    counterpart.setLocale(localStorage.getItem("lang"))
  }, [])
  const notify = (msg) => toast(msg)

  const [state, setState] = React.useState({
    share_value: "",
    savingsCashOutType:
      props.vsla.metadata.savingsCashOutType ||
      defaultGroupSettings["savingsCashOutType"],
    fineCashOutType:
      props.vsla.metadata.fineCashOutType || defaultGroupSettings["fineCashOutType"],
    welfareCashOutType:
      props.vsla.metadata.welfareCashOutType ||
      defaultGroupSettings["welfareCashOutType"],
    membershipCashOutType:
      props.vsla.metadata.membershipCashOutType ||
      defaultGroupSettings["membershipCashOutType"],
    loanInterestCashOutType:
      props.vsla.metadata.loanInterestCashOutType ||
      defaultGroupSettings["loanInterestCashOutType"],
  })

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()
    setIsUpdating(true)
    updateSettingsApi(
      state.share_value,
      state.welfareCashOutType,
      state.membershipCashOutType,
      state.fineCashOutType,
      state.loanInterestCashOutType,
      state.savingsCashOutType,
    )
    setState({
      ...state,
      share_value: "",
      welfareCashOutType: "",
      fineCashOutType: "",
    })
  }

  const disableSubmit = () => {
    return (
      props.ongoingMeeting.length > 0 &&
      props.transactions.filter(
        ({ meetingId }) => parseInt(meetingId) === props.ongoingMeeting[0].id,
      ).length > 0
    )
  }

  const updateSettingsApi = async (
    share_value,
    welfareCashOutType,
    membershipCashOutType,
    fineCashOutType,
    loanInterestCashOutType,
    savingsCashOutType,
  ) => {
    const vsla = props.vsla
    let newVsla = Object.assign({}, vsla)
    newVsla.metadata.share_value =
      share_value === "" ? vsla.metadata.share_value : share_value
    newVsla.metadata.fineCashOutType =
      fineCashOutType === "" ? vsla.metadata.fineCashOutType : fineCashOutType
    newVsla.metadata.welfareCashOutType =
      welfareCashOutType === ""
        ? vsla.metadata.welfareCashOutType
        : welfareCashOutType
    newVsla.metadata.membershipCashOutType =
      membershipCashOutType === ""
        ? vsla.metadata.membershipCashOutType
        : membershipCashOutType
    newVsla.metadata.loanInterestCashOutType =
      loanInterestCashOutType === ""
        ? vsla.metadata.loanInterestCashOutType
        : loanInterestCashOutType
    newVsla.metadata.savingsCashOutType =
      savingsCashOutType === ""
        ? vsla.metadata.savingsCashOutType
        : savingsCashOutType

    await props
      .updateSettings(newVsla)
      .then(async () => {
        setState({
          ...state,
          share_value: props.vsla.metadata.share_value,
          welfareCashOutType: props.vsla.metadata.welfareCashOutType,
          memebershipCashOutType: props.vsla.metadata.membershipCashOutType,
          fineCashOutType: props.vsla.metadata.fineCashOutType,
          loanInterestCashOutType: props.vsla.metadata.loanInterestCashOutType,
          savingsCashOutType: props.vsla.metadata.savingsCashOutType,
        })
        notify(<SuccessMessage />)
        setState({ ...state, disableSubmit: false })
        await fetchData()
        setIsUpdating(false)
      })
      .catch((error) => {
        notify(<ErrorMessage />)
        throw new Error("Updating shares", error)
      })
  }

  if (!isLoading) {
    return <StellarLoader />
  }

  return (
    <Container>
      <div>
        <Translate
          content="title_copy.settings"
          component="h4"
          style={headerStyle}
        />
      </div>
      <br />
      <div style={spanStyle}>
        <Translate content="title_copy.share_value" component="span" />
        {": "}
        {parseFloat(props.vsla.metadata.share_value).toFixed(2)}
      </div>
      {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
        <Row>
          <Col>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Input
                  type="text"
                  name="share_value"
                  style={formStyle}
                  placeholder={getTranslation(
                    "placeholder_copy",
                    "share_value_amount",
                  )}
                  onChange={onChange}
                  values={state.share_value}
                  disabled={!props.loadedvslas.can_change_share_value}
                />
              </FormGroup>
              {disableSubmit() && (
                <span style={{ fontWeight: 600 }}>
                  {getTranslation("settings", "disclaimer")}
                </span>
              )}
              <br />
              <h3 style={subheaderStyle}>
                {getTranslation("span", "selectCashOutType")}
              </h3>
              <Label for="savingsCashOutType">
                {getTranslation("span", "savingsFund")}
              </Label>
              <FormGroup>
                <Input
                  id="savings"
                  type="select"
                  name="savingsCashOutType"
                  style={formStyle}
                  onChange={onChange}
                  value={state.savingsCashOutType}
                  // disabled={true}
                >
                  <option value="Shared based">
                    {getTranslation("span", "sharedBased")}
                  </option>
                </Input>
              </FormGroup>
              {props.availableAccounts.flat().includes("membership") && (
                <FormGroup>
                  <Label for="membershipCashOutType">
                    {getTranslation("span", "membershipFund")}
                  </Label>
                  <Input
                    id="membership"
                    type="select"
                    name="membershipCashOutType"
                    style={formStyle}
                    onChange={onChange}
                    value={state.membershipCashOutType}
                    // disabled={true}
                  >
                    <option value="Refund">
                      {getTranslation("span", "refund")}
                    </option>
                  </Input>
                </FormGroup>
              )}
              {props.availableAccounts.flat().includes("welfare") && (
                <FormGroup>
                  <Label for="welfareCashOutType">
                    {getTranslation("span", "welfareFund")}
                  </Label>
                  <Input
                    id="welfare"
                    type="select"
                    name="welfareCashOutType"
                    style={formStyle}
                    onChange={onChange}
                    value={state.welfareCashOutType}
                  >
                    {/* <option value="">{welfareCashOutTypes}</option> */}
                    {/* <option value="Share based">{sharedBased}</option> */}
                    <option value="Equally">
                      {getTranslation("span", "equally")}
                    </option>
                    {/* <option value="Refund">{refund}</option> */}
                  </Input>
                </FormGroup>
              )}

              {props.availableAccounts.flat().includes("fine") && (
                <FormGroup>
                  <Label for="fineCashOutType">
                    {getTranslation("span", "fineFund")}
                  </Label>
                  <Input
                    id="fine"
                    type="select"
                    name="fineCashOutType"
                    style={formStyle}
                    onChange={onChange}
                    value={state.fineCashOutType}
                  >
                    {/* <option value="">{fineCashOutType}</option>*/}
                    <option value="Share based">
                      {getTranslation("span", "sharedBased")}
                    </option>
                    <option value="Equally">
                      {getTranslation("span", "equally")}
                    </option>
                    {/* <option value="Refund">{refund}</option> */}
                  </Input>
                </FormGroup>
              )}

              {props.availableAccounts.flat().includes("loan_interest") && (
                <FormGroup>
                  <Label for="loanInterestCashOutType">
                    {getTranslation("span", "loanInterestFund")}
                  </Label>
                  <Input
                    type="select"
                    id="loan_interest"
                    name="loanInterestCashOutType"
                    style={formStyle}
                    onChange={onChange}
                    value={state.loanInterestCashOutType}
                  >
                    {/* <option value="">{fineCashOutType}</option> */}
                    <option value="Share based">
                      {getTranslation("span", "sharedBased")}
                    </option>
                    {/* <option value="Equally">{equally}</option> */}
                    {/* <option value="Refund">{refund}</option> */}
                  </Input>
                </FormGroup>
              )}
              <FormGroup>
                <Input
                  type="submit"
                  value={getTranslation("placeholder_copy", "share_value_submit")}
                  name="submit"
                  className="btn btn-primary"
                  disabled={!isUpdating ? disableSubmit() : true}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      )}
      <div>
        {props.ongoingMeeting.length > 0 && (
          <Alert color="warning">
            <span>
              <Translate
                content="notifications.cant_update_shares"
                style={{
                  fontFamily: "Fira Sans",
                  fontSize: "16px",
                }}
              />
            </span>
          </Alert>
        )}
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    ongoingMeeting: state.currentOngoingMeeting,
    transactions: state.loadedMeetingTransaction,
    share_value: state.vsla.metadata.share_value,
    vsla: state.vsla,
    availableAccounts: selectVlsaFundAccounts(state),
    loadedvslas: state.loadedvslas[0],
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSettings: (data) => dispatch(updateGroupSettings(data)),
    fetchData: () => dispatch(fetchVslas()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings)
