import { memberLoadedHOL } from "./members/getMemberInfo"
import { backendAPI, blockchainAPI } from "../api"

export const isUpdatingMember = (bool) => ({
  type: "IS_UPDATING_MEMBER",
  isUpdating: bool,
})

export const updateMemberHOL = (memberHOL) => ({
  type: "UPDATE_MEMBER_HOL",
  memberHOL,
})

export const updateMemberBC = (memberBC) => ({
  type: "UPDATE_MEMBER_BC",
  memberBC,
})

export const updateMemberErrorHOL = (error) => ({
  type: "UPDATE_MEMBER_HOL_ERROR",
  error: error,
})

export const updateMemberErrorBC = (error) => ({
  type: "UPDATE_MEMBER_BC_ERROR",
  error: error,
})

export function patchMemberHOL(url, data, headers) {
  return async function (dispatch) {
    dispatch(isUpdatingMember(true))
    try {
      const res = await backendAPI.patch(url, data, headers)
      dispatch(updateMemberHOL(res.data))
      dispatch(memberLoadedHOL(res.data))
      dispatch(isUpdatingMember(false))
      return Promise.resolve(res.data)
    } catch (error) {
      return dispatch(updateMemberErrorHOL(error))
    }
  }
}

export function patchMemberBC(url, data, headers) {
  return async function (dispatch) {
    dispatch(isUpdatingMember(true))
    try {
      const res = await blockchainAPI.patch(url, data, headers)
      dispatch(updateMemberBC(res.data))
      dispatch(isUpdatingMember(false))
    } catch (error) {
      return dispatch(updateMemberErrorBC(error))
    }
  }
}
