import logTransaction from "../members/logTransactions"
import updateTransaction from "./updateTransaction"
import { blockchainAPI } from "../../store/api"

export default function memberToIssuer(
  id,
  txn,
  amount,
  vsla_id,
  member_id,
  meeting_id,
  pubkey,
  reversalTransactionType,
  dest_fund_type,
) {
  const url = `/vsla/${vsla_id}/member/${member_id}/transaction`
  const data = {
    amount: amount,
    asset: "SC",
    source: "member",
    dest: dest_fund_type,
  }
  const headersBC = {
    headers: { Authorization: "Bearer " + localStorage.getItem("tokenBC") },
  }

  blockchainAPI.post(url, data, headersBC).then((res) => {
    logTransaction(amount, pubkey, meeting_id, reversalTransactionType)
    updateTransaction(txn)
  })
}
