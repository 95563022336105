export const triggerAction = (type, payload) => ({ type, payload })

export const SET_LOCATIONS = "SET_LOCATIONS"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const SET_USER_SELECTED_LOCATION_DATA = "SET_USER_SELECTED_LOCATION_DATA"

//learning
export const SET_LEARNING_CATEGORIES = "SET_LEARNING_CATEGORIES"
export const SET_LEARNING_COURSES = "SET_LEARNING_COURSES"
export const SET_LEARNING_LESSONS = "SET_LEARNING_LESSONS"
export const SET_LEARNING_TRANSLATIONS = "SET_LEARNING_TRANSLATIONS"
export const SET_LEARNING_CONTENT = "SET_LEARNING_CONTENT"
export const SET_CATEGORY_ITEMS = "SET_CATEGORY_ITEMS"
