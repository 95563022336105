export const meetingTransactions = (state = [], action) => {
  switch (action.type) {
    case "SET_ACTIVE_MEETING_TRANSACTIONS":
      state = action.payload
      return state
    default:
      return state
  }
}

export const previousMeetingsTransactions = (
  state = { meetings: [], selectedMeeting: null },
  action,
) => {
  switch (action.type) {
    case "SET_PREVIOUS_MEETING_DATA":
      state.meetings = action.payload
      return state
    case "SET_SELECTED_PREVIOUS_MEETING_DATA":
      state.selectedMeeting = action.payload
      return state
    default:
      return state
  }
}

export const offlineMeetings = (state = {}, action) => {
  switch (action.type) {
    case "SET_OFFLINE_MEETING":
      const meeting = Object.values(state)[0]
      // prevent overriding meeting data, due to redux multiple action trigger
      if (action.payload.id && meeting && action.payload.id === meeting.id) {
        return state
      }
      // allow only one meeting for now.
      state = {}
      return {
        ...state,
        [action.payload.requestId]: {
          transactions: [],
          ...action.payload,
        },
      }
    case "UPDATE_OFFLINE_MEETING":
      return {
        ...state,
        [action.payload.requestId]: {
          ...state[action.payload.requestId],
          ...action.payload.data,
        },
      }
    case "END_OFFLINE_MEETING":
      state[action.payload].status = "completed"
      return {
        [action.payload]: {
          ...state[action.payload],
          status: "completed",
        },
      }
    case "REMOVE_OFFLINE_MEETING":
      delete state[action.payload]
      return state
    case "ADD_OFFLINE_MEETING_TRANSACTION":
      const transactions = state[action.payload.id].transactions || []
      if (
        transactions.filter(
          ({ requestId }) => requestId === action.payload.transaction.requestId,
        ).length
      ) {
        return state
      }
      transactions.push(action.payload.transaction)
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          transactions: transactions.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          ),
        },
      }
    case "REMOVE_FAILED_TRANSACTION":
      const meetingId = action.payload.meetingId
      const cloneTransactions = state[meetingId].transactions
      return {
        ...state,
        [meetingId]: {
          ...state[meetingId],
          transactions: cloneTransactions.filter(
            ({ requestId }) => requestId !== action.payload.transactionId,
          ),
        },
      }
    default:
      return state
  }
}
