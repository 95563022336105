import { blockchainAPI } from "../api"

export const loadBalancesStart = (bool) => ({
  type: "LOAD_BALANCES_START",
  isloading: bool,
})

export const loadBalanceSuccess = (balances) => ({
  type: "LOAD_BALANCES_SUCCESS",
  balances,
})

export const loadBalanceError = (error) => ({
  type: "LOAD_BALANCES_ERROR",
  error: error,
})

export function itemsFetchBalances(url, headers) {
  return (dispatch) => {
    dispatch(loadBalancesStart(true))
    blockchainAPI
      .get(url, headers)
      .then((res) => {
        dispatch(loadBalanceSuccess(res.data))
        dispatch(loadBalancesStart(false))
      })
      .catch((error) => {
        dispatch(loadBalanceError(error))
        dispatch(loadBalancesStart(false))
      })
  }
}
