import React, { useState } from "react"
import { connect } from "react-redux"
import {
  getCoursesById,
  getLessonsById,
  getTranslationsById,
  getLearningLanguage,
} from "./helpers"
import { Icon } from "@iconify/react"
import CardView from "./item-card"

import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap"

const Item = (props) => {
  const courses = React.useMemo(
    () =>
      getCoursesById(props.id, props.learningData.courses, [getLearningLanguage()]),
    [props.id, props.learningData.courses],
  )
  const lessons = React.useMemo(
    () =>
      getLessonsById(props.id, props.learningData.lessons, "lessons", ["ENGLISH"]),
    [props.id, props.learningData.lessons],
  )

  const coursesProp = React.useMemo(() => {
    if (!courses) return null
    return getTranslationsById(courses, props.learningData.translations, [
      getLearningLanguage(),
    ])
  }, [courses, props.learningData.translations])

  const getCourseLessons = (courseData) => {
    const course = courses.find((item) => item.course_id === courseData.course_id[0])
    return course ? course.lesson : []
  }
  return (
    <AccordionItem>
      <AccordionHeader targetId={props.id}>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Icon
              style={{
                fontSize: 40,
              }}
              icon={props.icon_name[0]}
            />
            <div>
              <h6>{props.value} </h6>
              <p>
                <strong style={{ marginRight: "2px" }}>{courses.length}</strong>{" "}
                courses,
                <strong style={{ marginLeft: "4px" }}>{lessons.length}</strong>{" "}
                lessons
              </p>
            </div>
          </div>
        </div>
      </AccordionHeader>
      <AccordionBody accordionId={props.id}>
        {coursesProp.map((course) => (
          <CardView
            key={`${new Date().toISOString()}-${course.course_id}`}
            contentName={`course (${getCourseLessons(course).length}) Lessons`}
            type="0"
            name={course.value}
            description={course.descriptions}
            item_id={course.course_id}
            course={course}
            lessons={lessons.filter(
              (lessonItem) => lessonItem.course_id[0] === course.course_id[0],
            )}
          />
        ))}
      </AccordionBody>
    </AccordionItem>
  )
}

const Courses = (props) => {
  const [open, setOpen] = useState("")
  const toggle = (id) => {
    if (open === id) {
      setOpen("")
    } else {
      setOpen(id)
    }
  }
  React.useEffect(() => {
    if (props.categories.length) {
      toggle(props.categories[0].category[0])
    }
  }, [])

  React.useEffect(() => {
    if (props.categories.length && !open) {
      toggle(props.categories[0].category[0])
    }
  }, [props.categories])

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        {props.categories.map((category, idx) => (
          <Item
            {...category}
            key={category.value}
            id={category.category[0]}
            courses={props.courses}
            lessons={props.lessons}
            learningData={props.learningData}
            translations={props.translations}
          />
        ))}
      </Accordion>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    learningData: state.learningData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
