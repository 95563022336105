import React from "react"
import { Container, Row, Col, Form, Input, FormGroup, Alert } from "reactstrap"
import stellarProcessing from "../../view/assets/stellar-animation.gif"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { toast } from "react-toastify"
import { WarningMessage } from "../../view/shared/notifications/Notifications"
import validateInput from "../../view/shared/validations/validateAmount"
import { connect } from "react-redux"
import LastLoanRepayment from "../reverseTransactions/LastLoanRepayment"
import { currentTransactionContext } from "../../store/actions/transactionContext"
import { selectMemberTotalLoanAggregate } from "../../store/reducers/getMemberAggregates"
import { selectMemberServiceChargeAggregate } from "../../store/reducers/getMemberAggregates"
import { getCurrency } from "../../view/shared/utilities/currency"
import { initiateTransaction } from "../../store/reducers/transactions/actions"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import { setCurrentPage } from "../../store/reducers/navigation"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../view/shared/utilities/cache"

counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)
const spinnerStyle = {
  height: "90%",
  width: "90%",
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}
const RepayLoan = (props) => {
  const notify = (msg) => toast(msg)
  const [state, setState] = React.useState({
    amount: 0,
    service_charge_amount: "",
    loading: false,
    errors: {},
    transactionTypeInProcessing: [],
  })

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  const [offlineMeeting, setOfflineMeeting] = React.useState(null)

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting(cachedMeeting[0])
  }, [])

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting])

  React.useEffect(() => {
    props.pushTransactionContext("Loan repaid")
  }, [])

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value })

  const isValid = () => {
    const { errors, isValid } = validateInput(state)

    if (!isValid) {
      setState({ ...state, errors })
    }

    return isValid
  }
  const redirectBack = () => {
    props.setPage("group-memberList")
  }

  const onSubmit = (e) => {
    if (
      state.transactionTypeInProcessing.includes("LOAN_INTEREST_REPAYMENT") ||
      state.transactionTypeInProcessing.includes("LOAN_REPAYMENT")
    ) {
      return
    }
    e.preventDefault()
    if (isValid()) {
      processTransaction(state.amount, state.service_charge_amount)
      setState({ ...state, amount: "" })
    }
  }

  const cachedTransactions = useLiveQuery(() => {
    try {
      return db.pendingTransactions
        .filter(
          ({ data, error, vslaId }) =>
            vslaId === props.vsla.id &&
            data.memberId === props.currentMember.blockchain_wallet_id &&
            !error &&
            ["LOAN_INTEREST_REPAYMENT", "LOAN_REPAYMENT"].includes(data.type),
        )
        .toArray()
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  React.useEffect(() => {
    if (!Array.isArray(cachedTransactions) || !cachedTransactions.length) {
      return
    }
    setState({ ...state, transactionTypeInProcessing: cachedTransactions })
  }, [cachedTransactions])

  const isPayable = () => {
    let outstandingLoan = 0

    if (props.memberDebt(props.currentMember.blockchain_wallet_id).balance > 0) {
      outstandingLoan = props.memberDebt(
        props.currentMember.blockchain_wallet_id,
      ).balance
      return outstandingLoan
    }

    return parseFloat(outstandingLoan)
  }

  const processTransaction = (amount, service_charge) => {
    const lang_code = localStorage.getItem("lang")
    const interest =
      props.loanInterest(props.currentMember.blockchain_wallet_id).balance > 0
    const amount_msg = parseFloat(interest ? service_charge : amount)

    const lang = {
      FRANÇAIS: {
        message: `Confirmer le remboursement du prêt de ${amount_msg} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm loan repayment of ${amount_msg} for member`,
      },
      SWAHILI: {
        message: `Thibitisha kwamba mwanachama amerejesha pesa wa ${amount_msg}`,
      },
      ZARMA: {
        message: `Zarma for Confirm loan repayment of ${amount_msg} for member`,
      },
      HAOUSSA: {
        message: `Tabbatar da ${amount_msg} dala dari  da ỳar rukuni ta ɗauka `,
      },
      SPANISH: {
        message: `Confirmar el pago del préstamo de ${amount_msg} para el miembro`,
      },
      LEBANESE: {
        message: `Confirm loan repayment of ${amount_msg} for household`,
      },
      PORTUGUESE: {
        message: `Confirme o pagamento do empréstimo de ${amount_msg} para o membro`,
      },
      ARABIC: {
        message: "أكد معاملة سداد القرض",
      },
    }

    if (
      window.confirm(lang[lang_code].message) &&
      parseFloat(amount) <= isPayable()
    ) {
      setState({ ...state, loading: true })
      const uuid = props.currentMember.pubkey

      if (interest) {
        props.commitTransaction(
          {
            uid: uuid,
            meetingId: props.ongoingMeeting.length
              ? props.ongoingMeeting[0].id
              : offlineMeeting.data.id,
            type: "LOAN_INTEREST_REPAYMENT",
            groupId: props.group[0].blockchain_key,
            memberId: props.currentMember.blockchain_wallet_id,
            amount: parseFloat(service_charge),
            paysInterest: true,
          },
          offlineMeeting,
        )
      } else {
        props.commitTransaction(
          {
            uid: uuid,
            meetingId: props.ongoingMeeting.length
              ? props.ongoingMeeting[0].id
              : offlineMeeting.data.id,
            type: "LOAN_REPAYMENT",
            groupId: props.group[0].blockchain_key,
            memberId: props.currentMember.blockchain_wallet_id,
            amount: parseFloat(amount),
          },
          offlineMeeting,
        )
      }
      notify(<WarningMessage msg={"notifications.processing"} />)
      props.setPage("group-memberList")
    } else {
      notify(<WarningMessage />)
    }
  }

  const debtAmount = () => {
    const debt =
      props.memberDebt(props.currentMember.blockchain_wallet_id).balance > 0
        ? props.memberDebt(props.currentMember.blockchain_wallet_id).balance
        : "0.00"
    return debt
  }

  const disableLoanInterestReversal = () => {
    const { transactions, currentMember, loanInterest } = props
    const hasLoanRepayments = transactions.some(
      ({ type }) => type === "LOAN_REPAYMENT",
    )
    const hasZeroLoanInterestBalance =
      loanInterest(currentMember.blockchain_wallet_id).balance === 0
    return hasZeroLoanInterestBalance && hasLoanRepayments
  }

  counterpart.setLocale(localStorage.getItem("lang"))
  const service_charge_amount = counterpart.translate(
    "placeholder_copy.service_charge_amount",
  )
  const payment_amount = counterpart.translate("placeholder_copy.payment_amount")
  const repay_submit = counterpart.translate("placeholder_copy.repay_submit")

  if (!state.loading) {
    return (
      <Container>
        <div>
          <h4
            style={{
              color: "#E36F1E",
              textAlign: "center",
              fontFamily: "quicksand",
              fontSize: "1.8em",
              marginBottom: "0rem",
              fontWeight: "bolder",
            }}
          >
            {" "}
            {props.currentMember.full_name}
          </h4>
        </div>
        <div style={{ padding: ".5rem", textAlign: "center" }}>
          <Translate
            content="title_copy.loan_repayment"
            component="h4"
            style={{
              color: "#E36F1E",
              fontFamily: "quicksand",
              fontSize: "1.8em",
              marginBottom: "0rem",
              fontWeight: "bolder",
            }}
          />
        </div>

        <div
          style={{
            padding: ".3rem",
            textAlign: "center",
            fontFamily: "Fira Sans",
            fontSize: "20px",
            color: "#696969",
          }}
        >
          <Translate content="title_copy.outstanding_loan" />{" "}
          {props.currentMember.loan_service_charge
            ? parseFloat(debtAmount()).toFixed(2)
            : parseFloat(isPayable()).toFixed(2)}{" "}
          {getCurrency(props.group[0].country).symbol}
        </div>
        {props.currentMember.loan_service_charge &&
          props.loanInterest(props.currentMember.blockchain_wallet_id).balance >
            0 && (
            <div
              style={{
                padding: ".3rem",
                textAlign: "center",
                fontFamily: "Fira Sans",
                fontSize: "20px",
                color: "#696969",
              }}
            >
              <Translate content="title_copy.outstanding_service_charge" />{" "}
              {Math.abs(
                parseFloat(
                  props.loanInterest(props.currentMember.blockchain_wallet_id)
                    .balance,
                ).toFixed(2),
              )}{" "}
              {getCurrency(props.group[0].country).symbol}
            </div>
          )}
        {props.loanInterest(props.currentMember.blockchain_wallet_id).balance >
          0 && (
          <Alert color="warning">
            <span>
              <Translate
                content="notifications.pay_out_standing_charge_first"
                style={{
                  fontFamily: "Fira Sans",
                  fontSize: "16px",
                }}
              />
            </span>
          </Alert>
        )}
        <Row>
          <br />
          <Col>
            {props.memberDebt(props.currentMember.blockchain_wallet_id).balance >
              0 && (
              <Form onSubmit={onSubmit} noValidate>
                <Container>
                  {!props.loanInterest(props.currentMember.blockchain_wallet_id)
                    .balance && (
                    <FormGroup>
                      <span style={{ color: "red" }}>{state.errors.amount}</span>
                      <Input
                        type="number"
                        name="amount"
                        style={{
                          flex: "10",
                          padding: "5px",
                          fontFamily: "Fira Sans",
                          fontSize: "20px",
                        }}
                        placeholder={payment_amount}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )}
                  {props.currentMember.loan_service_charge &&
                    props.loanInterest(props.currentMember.blockchain_wallet_id)
                      .balance > 0 && (
                      <FormGroup>
                        <span style={{ color: "red" }}>
                          {state.errors.service_charge_amount}
                        </span>
                        <Input
                          type="number"
                          name="service_charge_amount"
                          style={loadingStyle}
                          placeholder={service_charge_amount}
                          onChange={onChange}
                        />
                      </FormGroup>
                    )}
                </Container>

                <Container>
                  <FormGroup>
                    <Input
                      type="submit"
                      value={repay_submit}
                      name="submit"
                      className="btn btn-primary"
                      disabled={
                        state.transactionTypeInProcessing.includes(
                          "LOAN_INTEREST_REPAYMENT",
                        ) ||
                        state.transactionTypeInProcessing.includes(
                          "LOAN_REPAYMENT",
                        ) ||
                        parseFloat(state.service_charge_amount) >
                          Math.abs(
                            parseFloat(
                              props.loanInterest(
                                props.currentMember.blockchain_wallet_id,
                              ).balance,
                            ),
                          )
                          ? true
                          : false
                      }
                    />
                  </FormGroup>
                </Container>
              </Form>
            )}
          </Col>
        </Row>
        <LastLoanRepayment
          redirectBack={redirectBack}
          reversal_details={{
            destFundType: "issuer",
            reversalTransactionType: "Loan repayment reversed",
          }}
          transactions={props.transactions.filter(
            ({ memberId, type }) =>
              memberId === props.currentMember.blockchain_wallet_id,
          )}
          disableLoanInterestReversal={disableLoanInterestReversal()}
        />
      </Container>
    )
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
        <br />
      </center>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    vsla: state.vsla,
    pendingTransactionsKeys: Object.keys(state.pendingTransactions).filter((key) => {
      const fractions = key.split("_")
      return fractions.length >= 3 && fractions.includes(state.vsla.id)
    }),
    ongoingMeeting: state.currentOngoingMeeting,
    memberDebt: (id) => state.account.members[id].loan,
    memberDebtAndInterest: selectMemberTotalLoanAggregate(state),
    membersInterest: selectMemberServiceChargeAggregate(state),
    currentMember: state.loadedMember,
    transactions: state.loadedMeetingTransaction.filter(({ type }) =>
      ["LOAN_REPAYMENT", "LOAN_INTEREST_REPAYMENT"].includes(type),
    ),
    group: state.loadedvslas,
    loanInterest: (id) => state.account.members[id].loan_interest,
    vslaSummary: selectCreatedVslaInfo(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) =>
      dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepayLoan)
