import { createSelector } from "reselect"

export function vslaBalanceLoading(state = false, action) {
  switch (action.type) {
    case "LOAD_BALANCES_START":
      return action.isloading
    default:
      return state
  }
}

export function balance(state = [], action) {
  switch (action.type) {
    case "LOAD_BALANCES_SUCCESS":
      return action.balances
    default:
      return state
  }
}

export function balanceError(state = false, action) {
  switch (action.type) {
    case "LOAD_BALANCE_ERROR":
      return action.error
    default:
      return state
  }
}

export const selectVslaBalance = (state) => Object.values(state.account.balances)
export const selectLoadedMemberAddress = (state) => state.loadedMember.address
export const selectloadedMemberDetails = (state) => state.loadedMembers
const selectWelfareRequests = (state) =>
  Object.values(state.account.members).filter(
    ({ welfare_requests }) => welfare_requests,
  )
export const selectMemberDebt = createSelector(
  selectVslaBalance,
  selectLoadedMemberAddress,
  (vslaBalance, memberAddress) => {
    return vslaBalance.filter((bal) => bal.issuer === memberAddress)
  },
)

export const selectBalance = createSelector(
  selectVslaBalance,
  selectWelfareRequests,
  (balance, welfareReqs) => {
    const sumWelfareRequests = (welfareReqs || []).reduce((acc, item) => {
      acc += item.balance
      return acc
    }, 0)
    const item = {
      ...welfareReqs[0],
      balance: sumWelfareRequests,
    }
    return [...balance, item]
      .filter(
        (balance) =>
          balance.balance > 0 &&
          [
            "savings",
            "Fine paid",
            "loan",
            "shares",
            "loan_interest",
            "membership",
            "welfare",
          ].includes(balance.accountType),
      )
      .sort((a, b) => (a.balance > b.balance ? 0 : b.balance > a.balance ? 1 : -1))
      .map((balance) => {
        return [
          {
            issuer: balance.issuer,
            asset: balance.assetType,
            type: balance.accountType,
            amount: balance.balance,
          },
        ]
      })
  },
)

export const selectGroupSavingsFund = createSelector(
  selectVslaBalance,
  (balance) => {
    return balance
      .filter((balance) => balance.assetType === "SC")
      .filter((balance) => balance.accountType === "fund")
      .map((balance) => {
        return [
          {
            type: balance.accountType,
            amount: balance.balance,
          },
        ]
      })
  },
)

export const selectVslaDebtBalance = createSelector(
  selectVslaBalance,
  selectloadedMemberDetails,
  (balance) => {
    let totalDebt = balance
      .filter((balance) => balance.accountType === "loan")
      .map((balance) => balance.balance)
      .flat(1)
    totalDebt.push(0)
    return { type: "Debt", amount: sum(totalDebt) }
  },
)

export const selectGroupWelfareBalance = createSelector(
  selectVslaBalance,
  (balance) => {
    return balance
      .filter(
        (balance) => balance.assetType === "SC" && balance.accountType === "welfare",
      )
      .map((balance) => {
        return [
          {
            amount: balance.balance,
          },
        ]
      })
  },
)

export const selectGroupFineFundBalance = createSelector(
  selectVslaBalance,
  (balance) => {
    return balance
      .filter(
        (balance) => balance.assetType === "SC" && balance.accountType === "fine",
      )
      .map((balance) => {
        return [
          {
            type: balance.accountType,
            amount: balance.balance,
          },
        ]
      })
  },
)

const sum = (a) => a.reduce((x, y) => parseFloat(x) + parseFloat(y))
