import React from "react"
import counterpart from "counterpart"
import validateInput from "../../view/shared/validations/validateAmount"
import { WarningMessage } from "../../view/shared/notifications/Notifications"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { FormInput } from "./formComponents/FormInput"
import { FormTitle } from "./formComponents/FormTitle"
import { FormButton } from "./formComponents/FormButton"
import { Form, Container } from "reactstrap"
import { connect } from "react-redux"
import stellarProcessing from "../../view/assets/stellar-animation.gif"
import { toast } from "react-toastify"
import LastTransaction from "../reverseTransactions/LastTrasaction"
import { currentTransactionContext } from "../../store/actions/transactionContext"
import { initiateTransaction } from "../../store/reducers/transactions/actions"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import ARABIC from "../../view/shared/lang/ARABIC"
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas"
import { setCurrentPage } from "../../store/reducers/navigation"
import { getTranslation } from "../../view/shared/helpers"
import { db } from "../../view/shared/utilities/cache"
import { useLiveQuery } from "dexie-react-hooks"

counterpart.registerTranslations("ARABIC", ARABIC)
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

const notify = (msg) => toast(msg)
const spinnerStyle = {
  height: "90%",
  width: "90%",
}

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
}
const MembershipFee = (props) => {
  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])
  const [offlineMeeting, setOfflineMeeting] = React.useState(null)

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting(cachedMeeting[0])
  }, [])
  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null)
      return
    }
    setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting])

  const [state, setState] = React.useState({
    amount: "",
    errors: {},
    loading: false,
  })

  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"))
    props.pushTransactionContext("Membership paid")
  }, [])

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value })

  const isValid = () => {
    const { errors, isValid } = validateInput(state)

    if (!isValid) {
      setState({ ...state, errors })
    }
    return isValid
  }

  const redirectBack = () => {
    props.setPage("group-memberList")
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isValid()) {
      processTransaction(state.amount)
      setState({ amount: "", loading: true })
    }
  }

  const processTransaction = (amount) => {
    const lang_code = localStorage.getItem("lang")

    const lang = {
      FRANÇAIS: {
        message: `Confirmer la cotisation de ${state.amount} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm contribution of ${state.amount} for member`,
      },
      SWAHILI: {
        message: `Thibitisha mwanachama ametoa mchango wa shilingi ${state.amount}`,
      },
      ZARMA: {
        message: `Zarma for Confirm contribution of ${state.amount} for member`,
      },
      HAOUSSA: {
        message: `Tabbatar da taimakon dala ${state.amount} daga ỳar rukuni`,
      },
      SPANISH: {
        message: `Confirmar contribución de ${state.amount} lempiras para miembro`,
      },
      LEBANESE: {
        message: `Confirm contribution of ${state.amount} for household`,
      },
      PORTUGUESE: {
        message: `Confirme a contribuição de ${state.amount} para o membro`,
      },
      ARABIC: {
        message: `للأسرة ${state.amount} أكد مساهمة`,
      },
    }

    if (window.confirm(lang[lang_code].message)) {
      const uuid = props.currentMember.pubkey
      props.commitTransaction(
        {
          uid: uuid,
          meetingId: props.ongoingMeeting.length
            ? props.ongoingMeeting[0].id
            : offlineMeeting.data.id,
          type: "MEMBERSHIP_FEE",
          groupId: props.group[0].blockchain_key,
          memberId: props.currentMember.blockchain_wallet_id,
          amount: parseFloat(amount),
        },
        offlineMeeting,
      )
      notify(<WarningMessage msg={"notifications.processing"} />)
      redirectBack()
    } else {
      notify(<WarningMessage />)
      setState({ loading: false })
    }
  }

  if (!state.loading) {
    return (
      <div>
        <div>
          <h4 style={headerStyle}>{props.currentMember.full_name}</h4>
        </div>
        <FormTitle style={headerStyle} title="title_copy.membership_fee" />
        <Form onSubmit={onSubmit}>
          <Container>
            <span style={{ color: "red" }}>{state.errors.amount}</span>
            <FormInput
              type="number"
              name="amount"
              placeholder={getTranslation("placeholder_copy", "membership_amount")}
              value={state.amount}
              onChange={onChange}
              className="form-control"
            />

            <FormButton
              type={"submit"}
              value="placeholder_copy.membership_submit"
              name="submit"
              className="btn btn-lg form-control"
            />
          </Container>
        </Form>
        <LastTransaction
          offlineMeeting={props.offlineMeeting}
          redirectBack={redirectBack}
          reversal_details={{
            sourceFundType: "membership",
            destFundType: "issuer",
            reversalTransactionType: "Membership reversed",
          }}
          transactions={props.transactions}
        />
      </div>
    )
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
      </center>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    vslaSummary: selectCreatedVslaInfo(state),
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    transactions: state.loadedMeetingTransaction.filter(
      ({ type, memberId }) =>
        memberId === state.loadedMember.blockchain_wallet_id &&
        type === "MEMBERSHIP_FEE",
    ),
    vsla: state.vsla,
    group: state.loadedvslas,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) =>
      dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipFee)
