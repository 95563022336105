import React from "react"
import { getTranslation } from "../../../view/shared/helpers"

const commonStyle = {
  borderRadius: "3px",
  textTransform: "capitalize",
  padding: "0 4px",
  fontWeight: "500",
  fontSize: "12px",
}
const successfulStyle = {
  border: "1px solid green",
  color: "green",
}
const failedStyle = {
  border: "1px solid red",
  background: "red",
  color: "#fff",
}
const pendingStyle = {
  border: "1px solid #ccc",
  color: "#ccc",
}
const processingStyle = {
  border: "1px solid",
  //   color: "yellow",
  color: "rgb(227, 111, 30)",
  borderColor: "rgb(227, 111, 30)",
}
const styles = {
  processingStyle,
  pendingStyle,
  failedStyle,
  successfulStyle,
}

const View = (props) => {
  return (
    <div
      style={{
        ...commonStyle,
        ...styles[`${props.status.toLocaleLowerCase()}Style`],
      }}
    >
      {getTranslation("status", props.status.toLocaleUpperCase())}
    </div>
  )
}

export default View
