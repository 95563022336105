import { createSelector } from "reselect"

export function loadedvslaMembers(state = [], action) {
  switch (action.type) {
    case "LOADED_VSLA_MEMBERS":
      return action.members
    default:
      return state
  }
}

export function vlsaMembersLoadingStarted(state = false, action) {
  switch (action.type) {
    case "VSLA_MEM_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function vlsaMembersLoadingError(state = false, action) {
  switch (action.type) {
    case "VSLA_MEM_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}

export const loadVslaMembers = (state) => state.loadedvslaMembers

export const SelectVslaMembers = createSelector(loadVslaMembers, (members) => {
  return members.filter(
    (member) => member.status === "Active" && member.blockchain_wallet_id !== null,
  )
})
