import React from "react"
import { Badge } from "reactstrap"
import counterpart from "counterpart"
import Translate from "react-translate-component"
import ZARMA from "../../view/shared/lang/ZARMA"
import ENGLISH from "../../view/shared/lang/ENGLISH"
import SWAHILI from "../../view/shared/lang/SWAHILI"
import HAOUSSA from "../../view/shared/lang/HAOUSSA"
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS"
import SPANISH from "../../view/shared/lang/SPANISH"
import { toast } from "react-toastify"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { fetchMeeting, meetingLoaded } from "../../store/actions/meetings/getMeeting"
import { patchMeeting } from "../../store/actions/meetings/createMeeting"
import LEBANESE from "../../view/shared/lang/LEBANESE"
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE"
import { getTranslation } from "../../view/shared/helpers"
import ARABIC from "../../view/shared/lang/ARABIC"
import { useLiveQuery } from "dexie-react-hooks"
import { db, updateCacheItem } from "../../view/shared/utilities/cache"
counterpart.registerTranslations("ARABIC", ARABIC)

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE)
counterpart.registerTranslations("LEBANESE", LEBANESE)
counterpart.registerTranslations("ZARMA", ZARMA)
counterpart.registerTranslations("SWAHILI", SWAHILI)
counterpart.registerTranslations("ENGLISH", ENGLISH)
counterpart.registerTranslations("HAOUSSA", HAOUSSA)
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS)
counterpart.registerTranslations("SPANISH", SPANISH)

export const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.meeting_end_success"
      component="div"
      style={loadingStyle}
    />
  )
}

export const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.meeting_end_error"
      component="div"
      style={loadingStyle}
    />
  )
}

const MeetingBadge = (props) => {
  const notify = (msg) => toast.info(msg)

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray()
      return items.filter(({ vslaId }) => vslaId === props.vsla.id)
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  const cachedTransactions = useLiveQuery(() => {
    try {
      return db.pendingTransactions.toArray()
    } catch (error) {
      console.log(`Error: ${error}`)
      return []
    }
  }, [])

  const [offlineMeeting, setOfflineMeeting] = React.useState(null)
  const [transactions, setTransactions] = React.useState([])

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    })
  }, [cachedMeeting])

  React.useEffect(() => {
    if (!Array.isArray(cachedTransactions) || !cachedTransactions.length) {
      return
    }
    setTransactions(
      cachedTransactions
        .filter(({ vslaId }) => vslaId === props.vsla.id)
        .map(({ data }) => data),
    )
  }, [cachedMeeting])

  React.useEffect(() => {
    const ts = props.meetingTransactions.filter(({ status }) =>
      ["pending"].includes((status || "").toLocaleLowerCase()),
    )
    if (!ts.length) {
      return
    }
    setTransactions(ts)
  }, [props.meetingTransactions])

  const [state, setState] = React.useState({
    redirect: false,
    showEndMeetingBtn: [
      (props.ongoingMeeting || []).length &&
        props.ongoingMeeting[0].status === "ongoing" &&
        !(cachedMeeting || []).length,
      offlineMeeting && offlineMeeting.status === "ongoing",
    ].some((value) => value),
  })

  React.useEffect(() => {
    if (!props.loadedVsla.length) {
      return
    }
    counterpart.setLocale(localStorage.getItem("lang"))
    setState({
      ...state,
      showEndMeetingBtn: [
        (props.ongoingMeeting || []).length &&
          props.ongoingMeeting[0].status === "ongoing" &&
          !(cachedMeeting || []).length,
        offlineMeeting && offlineMeeting.status === "ongoing",
      ].some((value) => value),
    })
  }, [])

  React.useEffect(() => {
    setState({
      ...state,
      showEndMeetingBtn: [
        (props.ongoingMeeting || []).length &&
          props.ongoingMeeting[0].status === "ongoing" &&
          !(cachedMeeting || []).length,
        offlineMeeting && offlineMeeting.status === "ongoing",
      ].some((value) => value),
    })
  }, [props.isConnected, props.ongoingMeeting, offlineMeeting])

  const endMeeting = () => {
    const lang_code = localStorage.getItem("lang")
    const lang = {
      FRANÇAIS: {
        message: `Voulez-vous vraiment terminer la réunion?`,
      },
      ENGLISH: {
        message: `Are you sure you want to end the meeting?`,
      },
      SWAHILI: {
        message: `Una uhakika unataka kufunga mkutano?`,
      },
      HAOUSSA: {
        message: `Are you sure you want to end the meeting?`,
      },
      ZARMA: {
        message: `Akiika kaŋ ni ga ba ma marga ban ne?`,
      },
      SPANISH: {
        message: `¿Está seguro de que desea finalizar la reunión?`,
      },
      LEBANESE: {
        message: `Are you sure you want to end the session?`,
      },
      PORTUGUESE: {
        message: `Tem certeza de que deseja encerrar a reunião?`,
      },
      ARABIC: {
        message: "هل انت متاكد انك تريد انهاء الجلسة",
      },
    }

    if (transactions.length) {
      alert(getTranslation("alert", "cannot_end_meeting"))
      return
    }

    if (window.confirm(lang[lang_code].message)) {
      const currentMeeting = offlineMeeting || props.ongoingMeeting[0]
      const temp = currentMeeting
      temp.status = "completed"
      const headers = {
        headers: {
          Authorization:
            "Bearer " + props.user.token || localStorage.getItem("token"),
        },
      }

      const group_name = props.loadedVsla[0].name

      const notif = {
        ENGLISH: {
          message: `The secretary has ended a meeting for ${group_name} group on VSLA Online`,
        },
        FRANÇAIS: {
          message: `FRA - The secretary has ended a meeting for ${group_name} group on VSLA Online`,
        },
        SWAHILI: {
          message: `SWA - The secretary has ended a meeting for ${group_name} group on VSLA Online`,
        },
        SPANISH: {
          message: `La secretaria ha terminado una reunión para el grupo ${group_name} en VSLA Online`,
        },
        LEBANESE: {
          message: `The officer has ended a session for ${group_name} community on VSLA.online`,
        },
        PORTUGUESE: {
          message: `O/A secretário/a encerrou uma reunião para o grupo ${group_name} no VSLA Online`,
        },
        ARABIC: {
          message: group_name,
        },
      }
      temp.msg = Object.values(notif[lang_code])[0]
      const url = `/api/v1/meetings/${temp.id}`
      if (offlineMeeting) {
        updateCacheItem(
          "offlineMeeting",
          {
            data: {
              ...offlineMeeting,
              status: "completed",
            },
          },
          offlineMeeting.cacheId,
        )
        props.clearLoadedMeeting()
        notify(<SuccessMessage />)
        return
      }
      const data = {
        date: temp.date,
        status: "completed",
        request_id: temp.requestId,
        group_id: temp.group_id,
        msg: temp.msg,
      }
      props
        .patchOngoingMeeting(url, data, headers)
        .then((res) => {
          setState({ ...state, redirect: true })
          const group_id = props.loadedVsla[0].id
          const url = `/api/v1/groups/${group_id}/meetings`
          props.fetchOngoingMeeting(url, headers)
          notify(<SuccessMessage />)
          setState({ ...state, showEndMeetingBtn: false })
        })
        .catch((error) => {
          console.log(error)
          notify(<ErrorMessage />)
        })
    }
  }
  if (state.redirect) {
    return <Redirect to="/Groups" />
  }

  return (
    <div>
      <Badge
        key={props.ongoingMeeting.length}
        style={{
          display: [
            props.ongoingMeeting !== undefined && props.ongoingMeeting.length,
            offlineMeeting && offlineMeeting.status === "ongoing",
          ].some((value) => value)
            ? "initial"
            : "none",
        }}
        color="danger"
        onClick={endMeeting}
      >
        <Translate
          content="badge.meeting_on"
          style={{ fontFamily: "Fira Sans", fontSize: "15px" }}
        />
      </Badge>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    ongoingMeeting: state.currentOngoingMeeting,
    loadedVsla: state.loadedvslas,
    vsla: state.vsla,
    meetingTransactions: state.meetingTransactions,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoadedMeeting: () => dispatch(meetingLoaded([])),
    patchOngoingMeeting: (url, data, headers) =>
      dispatch(patchMeeting(url, data, headers)),
    fetchOngoingMeeting: (url, headers) => dispatch(fetchMeeting(url, headers)),
  }
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingBadge)
