import React from "react"
import { useSelector } from "react-redux"
import { Container, Alert } from "reactstrap"
import { getTranslation } from "../../view/shared/helpers"

const StatusBanner = (props) => {
  const { uploadStatus, isConnected } = useSelector(
    ({ isConnected, uploadStatus }) => ({ uploadStatus, isConnected }),
  )
  return (
    <Container
      style={{
        textAlign: "center",
        margin: 0,
        padding: 0,
        ...(props.extraStyles ? props.extraStyles : {}),
        // components styles here
      }}
    >
      {!isConnected && (
        <Alert
          style={{ width: "100%", padding: 0, marginBottom: 0, zIndex: 2 }}
          color="danger"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
            <path
              fill="#721c24"
              d="m40.9 45.2-5.75-5.75H12.4Q8 39.45 5 36.5t-3-7.35q0-4 2.525-6.7t6.325-3.3q.1-.7.325-1.575T11.8 16L3.5 7.7l2.1-2.1 37.45 37.45Zm-28.5-8.75h19.85l-18-18q-.55.75-.725 1.7-.175.95-.175 1.85h-.95q-3.1 0-5.25 1.975T5 29q0 3.1 2.15 5.275Q9.3 36.45 12.4 36.45Zm10.8-9.05Zm19.5 10.5-2.35-2.35q1.25-.85 1.95-1.9.7-1.05.7-2.5 0-2.15-1.55-3.675t-3.7-1.525H34.4V21.9q0-4.4-3.05-7.375-3.05-2.975-7.45-2.975-1.4 0-3.025.45T17.9 13.45l-2.1-2.1q1.8-1.45 3.875-2.125T23.9 8.55q5.55 0 9.525 3.95Q37.4 16.45 37.4 22v1.05Q41 23 43.5 25.3q2.5 2.3 2.5 5.85 0 1.75-.825 3.675Q44.35 36.75 42.7 37.9ZM29.15 24.5Z"
            />
          </svg>
          {getTranslation("alert", "no_internet")}
        </Alert>
      )}
      {isConnected && uploadStatus.isUpLoading && (
        <Alert style={{ padding: 0, marginBottom: 0, zIndex: 2 }} color="info">
          <img
            src="/images/icons/cloud_upload.svg"
            style={{
              width: 22,
              padding: "0 0px",
              margin: "0 8px",
            }}
            alt="-_-"
          />
          {getTranslation("alert", "uploading_transactions")}
        </Alert>
      )}
    </Container>
  )
}

export default StatusBanner
