import React from "react"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import CardView from "./item-card"
import { getTranslationsById, getLearningLanguage } from "./helpers"
import { getTranslation } from "../../view/shared/helpers"

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.4em",
  textAlign: "center",
  paddingBottom: "0.5em",
  margin: "34px 0 0 0",
}
const LessonsView = (props) => {
  const courseName = React.useMemo(() => {
    if (!props.selectedLearningContent) {
      return
    }
    return props.selectedLearningContent.categoryItems.courses[0].value
  }, [props.selectedLearningContent.categoryItems.courses])

  const getLessons = () => {
    return props.selectedLearningContent.categoryItems.lessons || []
  }

  const lessonsProp = React.useMemo(() => {
    const lessons = getLessons()
    if (!lessons.length || !props.learningData) return []
    return getTranslationsById(
      lessons,
      props.learningData.translations,
      [getLearningLanguage()],
      "lessons",
    )
  }, [
    props.selectedLearningContent.categoryItems.lessons,
    props.learningData.translations,
  ])

  return (
    <Container>
      <div style={headerStyle}>
        <h6>
          <b style={{ padding: "0 2px" }}>Course:</b>
          {courseName}
        </h6>
        <strong style={{ marginLeft: "4px" }}>{getLessons().length}</strong>{" "}
        {getTranslation("learningContent", "lessonView")}
      </div>
      {lessonsProp.map((lesson) => (
        <CardView
          key={`${new Date().toISOString()}-${lesson.lesson_id}`}
          contentName="lesson"
          type="1"
          isLesson={true}
          description={lesson.descriptions}
          item_id={lesson.lesson_id}
          name={lesson.value}
          lesson_id={lesson.lesson_id}
          lesson_fk_id={lesson.lesson}
          num_of_views={lesson.num_of_views}
        />
      ))}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    learningData: state.learningData,
    selectedLearningContent: state.selectedLearningContent,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonsView)
