export function loadedMember(state = [], action) {
  switch (action.type) {
    case "LOADED_MEMBER":
      return action.member
    default:
      return state
  }
}

export function memberLoadingStarted(state = false, action) {
  switch (action.type) {
    case "MEM_LOAD_START":
      return action.isloading
    default:
      return state
  }
}

export function memberLoadingError(state = [], action) {
  switch (action.type) {
    case "MEM_LOAD_ERROR":
      return action.error
    default:
      return state
  }
}
